export const GET_DRO_PRODUCTS_REQUESTED = 'GET_DRO_PRODUCTS_REQUESTED';
export const GET_DRO_PRODUCTS_STARTED = 'GET_DRO_PRODUCTS_STARTED';
export const GET_DRO_PRODUCTS_SUCCEED = 'GET_DRO_PRODUCTS_SUCCEED';
export const GET_DRO_PRODUCTS_FAILED = 'GET_DRO_PRODUCTS_FAILED';

export const IMPORT_DRO_PRODUCTS_REQUESTED = 'IMPORT_DRO_PRODUCTS_REQUESTED';
export const IMPORT_DRO_PRODUCTS_STARTED = 'IMPORT_DRO_PRODUCTS_STARTED';
export const IMPORT_DRO_PRODUCTS_SUCCEED = 'IMPORT_DRO_PRODUCTS_SUCCEED';
export const IMPORT_DRO_PRODUCTS_FAILED = 'IMPORT_DRO_PRODUCTS_FAILED';

export const GET_DRO_CATEGORIES_REQUESTED = 'GET_DRO_CATEGORIES_REQUESTED';
export const GET_DRO_CATEGORIES_STARTED = 'GET_DRO_CATEGORIES_STARTED';
export const GET_DRO_CATEGORIES_SUCCEED = 'GET_DRO_CATEGORIES_SUCCEED';
export const GET_DRO_CATEGORIES_FAILED = 'GET_DRO_CATEGORIES_FAILED';

export const UPDATE_DRO_CATEGORY_REQUESTED = 'UPDATE_DRO_CATEGORY_REQUESTED';
export const UPDATE_DRO_CATEGORY_STARTED = 'UPDATE_DRO_CATEGORY_STARTED';
export const UPDATE_DRO_CATEGORY_SUCCEED = 'UPDATE_DRO_CATEGORY_SUCCEED';
export const UPDATE_DRO_CATEGORY_FAILED = 'UPDATE_DRO_CATEGORY_FAILED';
