export const GET_REPORTS_REQUESTED = 'GET_REPORTS_REQUESTED';
export const GET_REPORTS_STARTED = 'GET_REPORTS_STARTED';
export const GET_REPORTS_SECCESS = 'GET_REPORTS_SECCESS';
export const GET_REPORTS_FAILED = 'GET_REPORTS_FAILED';

export const MAKE_REPORT_REQUESTED = 'MAKE_REPORT_REQUESTED';
export const MAKE_REPORT_STARTED = 'MAKE_REPORT_STARTED';
export const MAKE_REPORT_SECCESS = 'MAKE_REPORT_SECCESS';
export const MAKE_REPORT_FAILED = 'MAKE_REPORT_FAILED';

export const GET_SINGLE_REPORT_REQUESTED = 'GET_SINGLE_REPORT_REQUESTED';
export const GET_SINGLE_REPORT_STARTED = 'GET_SINGLE_REPORT_STARTED';
export const GET_SINGLE_REPORT_SECCESS = 'GET_SINGLE_REPORT_SECCESS';
export const GET_SINGLE_REPORT_FAILED = 'GET_SINGLE_REPORT_FAILED';