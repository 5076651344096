export const GET_PRODUCTS_TO_IMPROVE_REQUESTED = 'GET_PRODUCTS_TO_IMPROVE_REQUESTED';
export const GET_PRODUCTS_TO_IMPROVE_STARTED = 'GET_PRODUCTS_TO_IMPROVE_STARTED';
export const GET_PRODUCTS_TO_IMPROVE_SUCCEED = 'GET_PRODUCTS_TO_IMPROVE_SUCCEED';
export const GET_PRODUCTS_TO_IMPROVE_FAILED = 'GET_PRODUCTS_TO_IMPROVE_FAILED';

export const PUT_NEW_PRODUCTS_REQUESTED = 'PUT_NEW_PRODUCTS_REQUESTED';
export const PUT_NEW_PRODUCTS_STARTED = 'PUT_NEW_PRODUCTS_STARTED';
export const PUT_NEW_PRODUCTS_SUCCEED = 'PUT_NEW_PRODUCTS_SUCCEED';
export const PUT_NEW_PRODUCTS_FAILED = 'PUT_NEW_PRODUCTS_FAILED';

export const ADD_PRODUCTS_FROM_FILE_REQUESTED = 'ADD_PRODUCTS_FROM_FILE_REQUESTED';
export const ADD_PRODUCTS_FROM_FILE_STARTED = 'ADD_PRODUCTS_FROM_FILE_STARTED';
export const ADD_PRODUCTS_FROM_FILE_SUCCEED = 'ADD_PRODUCTS_FROM_FILE_SUCCEED';
export const ADD_PRODUCTS_FROM_FILE_FAILED = 'ADD_PRODUCTS_FROM_FILE_FAILED';

export const EDIT_PRODUCT_REQUESTED = 'EDIT_PRODUCT_REQUESTED';
export const EDIT_PRODUCT_STARTED = 'EDIT_PRODUCT_STARTED';
export const EDIT_PRODUCT_SUCCEED = 'EDIT_PRODUCT_SUCCEED';
export const EDIT_PRODUCT_FAILED = 'EDIT_PRODUCT_FAILED';

export const DELETE_PRODUCT_REQUESTED = 'DELETE_PRODUCT_REQUESTED';
export const DELETE_PRODUCT_STARTED = 'DELETE_PRODUCT_STARTED';
export const DELETE_PRODUCT_SUCCEED = 'DELETE_PRODUCT_SUCCEED';
export const DELETE_PRODUCT_FAILED = 'DELETE_PRODUCT_FAILED';

export const SAVE_G_PRODUCT_REQUESTED = 'SAVE_G_PRODUCT_REQUESTED';
export const SAVE_G_PRODUCT_STARTED = 'SAVE_G_PRODUCT_STARTED';
export const SAVE_G_PRODUCT_SUCCEED = 'SAVE_G_PRODUCT_SUCCEED';
export const SAVE_G_PRODUCT_FAILED = 'SAVE_G_PRODUCT_FAILED';

export const GET_MAG_SUPP_RULES_REQUESTED = 'GET_MAG_SUPP_RULES_REQUESTED';
export const GET_MAG_SUPP_RULES_STARTED = 'GET_MAG_SUPP_RULES_STARTED';
export const GET_MAG_SUPP_RULES_SUCCEED = 'GET_MAG_SUPP_RULES_SUCCEED';
export const GET_MAG_SUPP_RULES_FAILED = 'GET_MAG_SUPP_RULES_FAILED';

export const POST_CHECKLIST_REQUESTED = 'POST_CHECKLIST_REQUESTED';
export const POST_CHECKLIST_STARTED = 'POST_CHECKLIST_STARTED';
export const POST_CHECKLIST_SUCCEED = 'POST_CHECKLIST_SUCCEED';
export const POST_CHECKLIST_FAILED = 'POST_CHECKLIST_FAILED';

export const POST_TAKE_ACTION_REQUESTED = 'POST_TAKE_ACTION_REQUESTED';
export const POST_TAKE_ACTION_STARTED = 'POST_TAKE_ACTION_STARTED';
export const POST_TAKE_ACTION_SUCCEED = 'POST_TAKE_ACTION_SUCCEED';
export const POST_TAKE_ACTION_FAILED = 'POST_TAKE_ACTION_FAILED';

export const POST_REINDEX_REQUESTED = 'POST_REINDEX_REQUESTED';
export const POST_REINDEX_STARTED = 'POST_REINDEX_STARTED';
export const POST_REINDEX_SUCCEED = 'POST_REINDEX_SUCCEED';
export const POST_REINDEX_FAILED = 'POST_REINDEX_FAILED';