import { Cookies } from 'react-cookie';

/**
 * Fetch data from given url
 * @param {*} url
 * @param {*} options
 */
const fetchJSON = (url, options = {}, auth = true, stringifyBody = true) => {
    if(options.body && stringifyBody) {
        options.body = JSON.stringify(options.body)
    }
    if(!options.headers) {
        options.headers = { 'Content-Type': 'application/json' }
    }
    if(auth) {
        let cookies = new Cookies();
        let user = cookies.get('user');
        let auth = user.token;
        options.headers = {
            ...options.headers,
            'Authorization': "Bearer " + auth,
        }
    }
    return fetch(url, options)
        .then(response => {
            if (response.status !== 200) {
                throw response;
            }
            return response.json();
        })
        .then(json => {
            return json;
        })
        .catch(error => {
            throw error;
        });
};

export { fetchJSON };
