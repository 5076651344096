export const GET_PRODUCTS_REQUESTED = 'GET_PRODUCTS_REQUESTED';
export const GET_PRODUCTS_STARTED = 'GET_PRODUCTS_STARTED';
export const GET_PRODUCTS_SUCCEED = 'GET_PRODUCTS_SUCCEED';
export const GET_PRODUCTS_FAILED = 'GET_PRODUCTS_FAILED';

export const GET_TAXES_SHIPPING_REQUESTED = 'GET_TAXES_SHIPPING_REQUESTED';
export const GET_TAXES_SHIPPING_STARTED = 'GET_TAXES_SHIPPING_STARTED';
export const GET_TAXES_SHIPPING_SUCCEED = 'GET_TAXES_SHIPPING_SUCCEED';
export const GET_TAXES_SHIPPING_FAILED = 'GET_TAXES_SHIPPING_FAILED';

export const POST_BLOCK_REQUESTED = 'POST_BLOCK_REQUESTED';
export const POST_BLOCK_STARTED = 'POST_BLOCK_STARTED';
export const POST_BLOCK_SUCCEED = 'POST_BLOCK_SUCCEED';
export const POST_BLOCK_FAILED = 'POST_BLOCK_FAILED';

export const POST_CHANGE_PRICE_REQUESTED = 'POST_CHANGE_PRICE_REQUESTED';
export const POST_CHANGE_PRICE_STARTED = 'POST_CHANGE_PRICE_STARTED';
export const POST_CHANGE_PRICE_SUCCEED = 'POST_CHANGE_PRICE_SUCCEED';
export const POST_CHANGE_PRICE_FAILED = 'POST_CHANGE_PRICE_FAILED';

export const POST_CHANGE_PRICE_CENEO_REQUESTED = 'POST_CHANGE_PRICE_CENEO_REQUESTED';
export const POST_CHANGE_PRICE_CENEO_STARTED = 'POST_CHANGE_PRICE_CENEO_STARTED';
export const POST_CHANGE_PRICE_CENEO_SUCCEED = 'POST_CHANGE_PRICE_CENEO_SUCCEED';
export const POST_CHANGE_PRICE_CENEO_FAILED = 'POST_CHANGE_PRICE_CENEO_FAILED';

export const POST_CHANGE_TAXES_SHIPPING_PRICE_REQUESTED = 'POST_CHANGE_TAXES_SHIPPING_PRICE_REQUESTED';
export const POST_CHANGE_TAXES_SHIPPING_PRICE_STARTED = 'POST_CHANGE_TAXES_SHIPPING_PRICE_STARTED';
export const POST_CHANGE_TAXES_SHIPPING_PRICE_SUCCEED = 'POST_CHANGE_TAXES_SHIPPING_PRICE_SUCCEED';
export const POST_CHANGE_TAXES_SHIPPING_PRICE_FAILED = 'POST_CHANGE_TAXES_SHIPPING_PRICE_FAILED';