export const GET_ALLEGRO_PRODUCTS_REQUESTED = 'GET_ALLEGRO_PRODUCTS_REQUESTED';
export const GET_ALLEGRO_PRODUCTS_STARTED = 'GET_ALLEGRO_PRODUCTS_STARTED';
export const GET_ALLEGRO_PRODUCTS_SUCCEED = 'GET_ALLEGRO_PRODUCTS_SUCCEED';
export const GET_ALLEGRO_PRODUCTS_FAILED = 'GET_ALLEGRO_PRODUCTS_FAILED';

export const GET_ALLEGRO_MESSAGES_REQUESTED = 'GET_ALLEGRO_MESSAGES_REQUESTED';
export const GET_ALLEGRO_MESSAGES_STARTED = 'GET_ALLEGRO_MESSAGES_STARTED';
export const GET_ALLEGRO_MESSAGES_SUCCEED = 'GET_ALLEGRO_MESSAGES_SUCCEED';
export const GET_ALLEGRO_MESSAGES_FAILED = 'GET_ALLEGRO_MESSAGES_FAILED';

export const GET_ALLEGRO_BILLING_REQUESTED = 'GET_ALLEGRO_BILLING_REQUESTED';
export const GET_ALLEGRO_BILLING_STARTED = 'GET_ALLEGRO_BILLING_STARTED';
export const GET_ALLEGRO_BILLING_SUCCEED = 'GET_ALLEGRO_BILLING_SUCCEED';
export const GET_ALLEGRO_BILLING_FAILED = 'GET_ALLEGRO_BILLING_FAILED';

export const POST_ALLEGRO_READ_MESSAGES_REQUESTED = 'POST_ALLEGRO_READ_MESSAGES_REQUESTED';
export const POST_ALLEGRO_READ_MESSAGES_STARTED = 'POST_ALLEGRO_READ_MESSAGES_STARTED';
export const POST_ALLEGRO_READ_MESSAGES_SUCCEED = 'POST_ALLEGRO_READ_MESSAGES_SUCCEED';
export const POST_ALLEGRO_READ_MESSAGES_FAILED = 'POST_ALLEGRO_READ_MESSAGES_FAILED';

export const GET_ALLEGRO_MESSAGES_CENTER_REQUESTED = 'GET_ALLEGRO_MESSAGES_CENTER_REQUESTED';
export const GET_ALLEGRO_MESSAGES_CENTER_STARTED = 'GET_ALLEGRO_MESSAGES_CENTER_STARTED';
export const GET_ALLEGRO_MESSAGES_CENTER_SUCCEED = 'GET_ALLEGRO_MESSAGES_CENTER_SUCCEED';
export const GET_ALLEGRO_MESSAGES_CENTER_FAILED = 'GET_ALLEGRO_MESSAGES_CENTER_FAILED';

export const POST_ALLEGRO_READ_MESSAGES_CENTER_REQUESTED = 'POST_ALLEGRO_READ_MESSAGES_CENTER_REQUESTED';
export const POST_ALLEGRO_READ_MESSAGES_CENTER_STARTED = 'POST_ALLEGRO_READ_MESSAGES_CENTER_STARTED';
export const POST_ALLEGRO_READ_MESSAGES_CENTER_SUCCEED = 'POST_ALLEGRO_READ_MESSAGES_CENTER_SUCCEED';
export const POST_ALLEGRO_READ_MESSAGES_CENTER_FAILED = 'POST_ALLEGRO_READ_MESSAGES_CENTER_FAILED';

export const POST_ALLEGRO_PRODUCTS_REQUESTED = 'POST_ALLEGRO_PRODUCTS_REQUESTED';
export const POST_ALLEGRO_PRODUCTS_STARTED = 'POST_ALLEGRO_PRODUCTS_STARTED';
export const POST_ALLEGRO_PRODUCTS_SUCCEED = 'POST_ALLEGRO_PRODUCTS_SUCCEED';
export const POST_ALLEGRO_PRODUCTS_FAILED = 'POST_ALLEGRO_PRODUCTS_FAILED';

export const MODIFY_ALLEGRO_PRODUCTS_REQUESTED = 'MODIFY_ALLEGRO_PRODUCTS_REQUESTED';
export const MODIFY_ALLEGRO_PRODUCTS_STARTED = 'MODIFY_ALLEGRO_PRODUCTS_STARTED';
export const MODIFY_ALLEGRO_PRODUCTS_SUCCEED = 'MODIFY_ALLEGRO_PRODUCTS_SUCCEED';
export const MODIFY_ALLEGRO_PRODUCTS_FAILED = 'MODIFY_ALLEGRO_PRODUCTS_FAILED';

export const POST_CALC_COST_REQUESTED = 'POST_CALC_COST_REQUESTED';
export const POST_CALC_COST_STARTED = 'POST_CALC_COST_STARTED';
export const POST_CALC_COST_SUCCEED = 'POST_CALC_COST_SUCCEED';
export const POST_CALC_COST_FAILED = 'POST_CALC_COST_FAILED';

export const POST_CHECKBOX_UPDATE_REQUESTED = 'POST_CHECKBOX_UPDATE_REQUESTED';
export const POST_CHECKBOX_UPDATE_STARTED = 'POST_CHECKBOX_UPDATE_STARTED';
export const POST_CHECKBOX_UPDATE_SUCCEED = 'POST_CHECKBOX_UPDATE_SUCCEED';
export const POST_CHECKBOX_UPDATE_FAILED = 'POST_CHECKBOX_UPDATE_FAILED';

export const POST_EDIT_PRODUCT_REQUESTED = 'POST_EDIT_PRODUCT_REQUESTED';
export const POST_EDIT_PRODUCT_STARTED = 'POST_EDIT_PRODUCT_STARTED';
export const POST_EDIT_PRODUCT_SUCCEED = 'POST_EDIT_PRODUCT_SUCCEED';
export const POST_EDIT_PRODUCT_FAILED = 'POST_EDIT_PRODUCT_FAILED';

export const POST_REPORT_BADGE_REQUESTED = 'POST_REPORT_BADGE_REQUESTED';
export const POST_REPORT_BADGE_STARTED = 'POST_REPORT_BADGE_STARTED';
export const POST_REPORT_BADGE_SUCCEED = 'POST_REPORT_BADGE_SUCCEED';
export const POST_REPORT_BADGE_FAILED = 'POST_REPORT_BADGE_FAILED';

export const GET_SEARCH_ITEM_REQUESTED = 'GET_SEARCH_ITEM_REQUESTED';
export const GET_SEARCH_ITEM_FAILED = 'GET_SEARCH_ITEM_FAILED';
export const GET_SEARCH_ITEM_STARTED = 'GET_SEARCH_ITEM_STARTED';
export const GET_SEARCH_ITEM_SUCCEED = 'GET_SEARCH_ITEM_SUCCEED';

export const GET_SEARCH_AUCTION_REQUESTED = 'GET_SEARCH_AUCTION_REQUESTED';
export const GET_SEARCH_AUCTION_FAILED = 'GET_SEARCH_AUCTION_FAILED';
export const GET_SEARCH_AUCTION_STARTED = 'GET_SEARCH_AUCTION_STARTED';
export const GET_SEARCH_AUCTION_SUCCEED = 'GET_SEARCH_AUCTION_SUCCEED';

export const SAVE_PRODUCT_REQUESTED = 'SAVE_PRODUCT_REQUESTED';
export const SAVE_PRODUCT_FAILED = 'SAVE_PRODUCT_FAILED';
export const SAVE_PRODUCT_STARTED = 'SAVE_PRODUCT_STARTED';
export const SAVE_PRODUCT_SUCCEED = 'SAVE_PRODUCT_SUCCEED';

export const FILTER_PRODUCT_REQUESTED = 'FILTER_PRODUCT_REQUESTED'
export const FILTER_PRODUCT_FAILED = 'FILTER_PRODUCT_FAILER'
export const FILTER_PRODUCT_STARTED = 'FILTER_PRODUCT_STARTED'
export const FILTER_PRODUCT_SUCCEED = 'FILTER_PRODUCT_SUCCEED'

export const CLEAR_PRODUCT_REQUESTED = 'CLEAR_PRODUCT_REQUESTED'
export const CLEAR_PRODUCT_FAILED = 'CLEAR_PRODUCT_FAILER'
export const CLEAR_PRODUCT_STARTED = 'CLEAR_PRODUCT_STARTED'
export const CLEAR_PRODUCT_SUCCEED = 'CLEAR_PRODUCT_SUCCEED'

export const GET_CATEGORY_PRODUCT_REQUESTED = 'GET_CATEGORY_PRODUCT_REQUESTED'
export const GET_CATEGORY_PRODUCT_FAILED = 'GET_CATEGORY_PRODUCT_FAILED'
export const GET_CATEGORY_PRODUCT_STARTED = 'GET_CATEGORY_PRODUCT_STARTED'
export const GET_CATEGORY_PRODUCT_SUCCEED = 'GET_CATEGORY_PRODUCT_SUCCEED'

export const GET_ALL_CATEGORY_REQUESTED = 'GET_ALL_CATEGORY_REQUESTED'
export const GET_ALL_CATEGORY_FAILED = 'GET_ALL_CATEGORY_FAILED'
export const GET_ALL_CATEGORY_STARTED = 'GET_ALL_CATEGORY_STARTED'
export const GET_ALL_CATEGORY_SUCCEED = 'GET_ALL_CATEGORY_SUCCEED'

export const GET_CATEGORY_FIELD_REQUESTED = 'GET_CATEGORY_FIELD_REQUESTED'
export const GET_CATEGORY_FIELD_FAILED = 'GET_CATEGORY_FIELD_FAILED'
export const GET_CATEGORY_FIELD_STARTED = 'GET_CATEGORY_FIELD_STARTED'
export const GET_CATEGORY_FIELD_SUCCEED = 'GET_CATEGORY_FIELD_SUCCEED'
