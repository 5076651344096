import { all, takeLatest, put, call } from "redux-saga/effects";
import { fetchJSON } from '../../helpers/api';
import { Cookies } from 'react-cookie';
import { post } from 'axios';

import {
  GET_ALLEGRO_PRODUCTS_REQUESTED,
  GET_ALLEGRO_PRODUCTS_STARTED,
  GET_ALLEGRO_PRODUCTS_SUCCEED,
  GET_ALLEGRO_PRODUCTS_FAILED,
  GET_ALLEGRO_MESSAGES_REQUESTED,
  GET_ALLEGRO_MESSAGES_STARTED,
  GET_ALLEGRO_MESSAGES_SUCCEED,
  GET_ALLEGRO_MESSAGES_FAILED,
  GET_ALLEGRO_BILLING_REQUESTED,
  GET_ALLEGRO_BILLING_STARTED,
  GET_ALLEGRO_BILLING_SUCCEED,
  GET_ALLEGRO_BILLING_FAILED,
  POST_ALLEGRO_READ_MESSAGES_REQUESTED,
  POST_ALLEGRO_READ_MESSAGES_STARTED,
  POST_ALLEGRO_READ_MESSAGES_SUCCEED,
  POST_ALLEGRO_READ_MESSAGES_FAILED,
  GET_ALLEGRO_MESSAGES_CENTER_REQUESTED,
  GET_ALLEGRO_MESSAGES_CENTER_STARTED,
  GET_ALLEGRO_MESSAGES_CENTER_SUCCEED,
  GET_ALLEGRO_MESSAGES_CENTER_FAILED,
  POST_ALLEGRO_READ_MESSAGES_CENTER_REQUESTED,
  POST_ALLEGRO_READ_MESSAGES_CENTER_STARTED,
  POST_ALLEGRO_READ_MESSAGES_CENTER_SUCCEED,
  POST_ALLEGRO_READ_MESSAGES_CENTER_FAILED,
  POST_ALLEGRO_PRODUCTS_REQUESTED,
  POST_ALLEGRO_PRODUCTS_STARTED,
  POST_ALLEGRO_PRODUCTS_SUCCEED,
  POST_ALLEGRO_PRODUCTS_FAILED,
  MODIFY_ALLEGRO_PRODUCTS_REQUESTED,
  MODIFY_ALLEGRO_PRODUCTS_STARTED,
  MODIFY_ALLEGRO_PRODUCTS_SUCCEED,
  MODIFY_ALLEGRO_PRODUCTS_FAILED,
  POST_CALC_COST_REQUESTED,
  POST_CALC_COST_FAILED,
  POST_CALC_COST_STARTED,
  POST_CALC_COST_SUCCEED,
  POST_CHECKBOX_UPDATE_REQUESTED,
  POST_CHECKBOX_UPDATE_STARTED,
  POST_CHECKBOX_UPDATE_SUCCEED,
  POST_CHECKBOX_UPDATE_FAILED,
  POST_EDIT_PRODUCT_REQUESTED,
  POST_EDIT_PRODUCT_STARTED,
  POST_EDIT_PRODUCT_SUCCEED,
  POST_EDIT_PRODUCT_FAILED,
  POST_REPORT_BADGE_REQUESTED,
  POST_REPORT_BADGE_STARTED,
  POST_REPORT_BADGE_SUCCEED,
  POST_REPORT_BADGE_FAILED,
  GET_SEARCH_ITEM_REQUESTED,
  GET_SEARCH_ITEM_STARTED,
  GET_SEARCH_ITEM_SUCCEED,
  GET_SEARCH_ITEM_FAILED,
  GET_SEARCH_AUCTION_REQUESTED,
  GET_SEARCH_AUCTION_STARTED,
  GET_SEARCH_AUCTION_SUCCEED,
  GET_SEARCH_AUCTION_FAILED,
  SAVE_PRODUCT_REQUESTED,
  SAVE_PRODUCT_STARTED,
  SAVE_PRODUCT_SUCCEED,
  SAVE_PRODUCT_FAILED,
  FILTER_PRODUCT_REQUESTED,
  FILTER_PRODUCT_STARTED,
  FILTER_PRODUCT_SUCCEED,
  FILTER_PRODUCT_FAILED,
  CLEAR_PRODUCT_REQUESTED,
  CLEAR_PRODUCT_STARTED,
  CLEAR_PRODUCT_SUCCEED,
  CLEAR_PRODUCT_FAILED,
  GET_CATEGORY_PRODUCT_REQUESTED,
  GET_CATEGORY_PRODUCT_FAILED,
  GET_CATEGORY_PRODUCT_STARTED,
  GET_CATEGORY_PRODUCT_SUCCEED,
  GET_ALL_CATEGORY_REQUESTED,
  GET_ALL_CATEGORY_FAILED,
  GET_ALL_CATEGORY_STARTED,
  GET_ALL_CATEGORY_SUCCEED,
  GET_CATEGORY_FIELD_REQUESTED,
  GET_CATEGORY_FIELD_FAILED,
  GET_CATEGORY_FIELD_STARTED,
  GET_CATEGORY_FIELD_SUCCEED,
} from "./actionTypes";

import { SUCCESS, ERROR } from "../../constants/toastTypes";
import { GET_PRODUCTS_ALLEGRO_SUCCESS, GET_PRODUCTS_ALLEGRO_ERROR,POST_ALLEGRO_PRODUCT_SUCCEED, POST_EDIT_PRODUCT_SUCCESS, POST_EDIT_PRODUCT_ERROR } from "../../constants/notifications";
import { addToast } from "../toast/actions";

function* getAllegroProducts() {
  try {
    yield put({ type: GET_ALLEGRO_PRODUCTS_STARTED });
    const payload = yield call(fetchJSON, process.env.REACT_APP_API_URL+'/api/allegro/products');
    let products = payload.products.map( (item,index) => {
      return {...item, id: index, id_table: item.id}
    });
    const data = {
      products: products,
      options: payload.options
    }
     //console.log(data);
    yield put({ type: GET_ALLEGRO_PRODUCTS_SUCCEED, payload: { data } });

  } catch (error) {
    yield put(addToast({
      type: ERROR,
      message: GET_PRODUCTS_ALLEGRO_ERROR,
    }));
    yield put({ type: GET_ALLEGRO_PRODUCTS_FAILED, payload: { error } });
  }
}

function* getMessages() {
  try {
    yield put({ type: GET_ALLEGRO_MESSAGES_STARTED });
    const data = yield call(fetchJSON, process.env.REACT_APP_API_URL+'/api/allegro/get-messages');
     //console.log(data);
    yield put({ type: GET_ALLEGRO_MESSAGES_SUCCEED, payload: { data } });

  } catch (error) {
    yield put({ type: GET_ALLEGRO_MESSAGES_FAILED, payload: { error } });
  }
}

function* getBilling() {
  try {
    yield put({ type: GET_ALLEGRO_BILLING_STARTED });
    const data = yield call(fetchJSON, process.env.REACT_APP_API_URL+'/api/allegro/get-billing');
     //console.log(data);
    yield put({ type: GET_ALLEGRO_BILLING_SUCCEED, payload: { data } });

  } catch (error) {
    yield put({ type: GET_ALLEGRO_BILLING_FAILED, payload: { error } });
  }
}

function* changeReadMessage({payload}) {
  const options = {
    body: payload,
    method: 'POST'
  };
  try {
    yield put({ type: POST_ALLEGRO_READ_MESSAGES_STARTED });
    const data = yield call(fetchJSON, process.env.REACT_APP_API_URL+'/api/allegro/messages-read-update', options);
     //console.log(data);
    yield put({ type: POST_ALLEGRO_READ_MESSAGES_SUCCEED, payload: data });

  } catch (error) {
    yield put({ type: POST_ALLEGRO_READ_MESSAGES_FAILED, payload: { error } });
  }
}

function* getMessagesCenter() {
  try {
    yield put({ type: GET_ALLEGRO_MESSAGES_CENTER_STARTED });
    const data = yield call(fetchJSON, process.env.REACT_APP_API_URL+'/api/allegro/get-messages-center');
     //console.log(data);
    yield put({ type: GET_ALLEGRO_MESSAGES_CENTER_SUCCEED, payload: { data } });

  } catch (error) {
    yield put({ type: GET_ALLEGRO_MESSAGES_CENTER_FAILED, payload: { error } });
  }
}

function* changeReadMessageCenter({payload}) {
  const options = {
    body: payload,
    method: 'POST'
  };
  try {
    yield put({ type: POST_ALLEGRO_READ_MESSAGES_CENTER_STARTED });
    const data = yield call(fetchJSON, process.env.REACT_APP_API_URL+'/api/allegro/messages-read-update-center', options);
     //console.log(data);
    yield put({ type: POST_ALLEGRO_READ_MESSAGES_CENTER_SUCCEED, payload: data });

  } catch (error) {
    yield put({ type: POST_ALLEGRO_READ_MESSAGES_CENTER_FAILED, payload: { error } });
  }
}

// aktualizacja checkboxów
function* checkboxUpdate({payload}) {
  const options = {
    body: payload,
    method: 'POST'
  };
  try {
    yield put({ type: POST_CHECKBOX_UPDATE_STARTED, payload: payload });
    const data = yield call(fetchJSON, process.env.REACT_APP_API_URL+'/api/allegro/checkbox-update', options);
    yield put({ type: POST_CHECKBOX_UPDATE_SUCCEED, payload: payload });
    
  } catch (error) {
    yield put({ type: POST_CHECKBOX_UPDATE_FAILED, payload: { error } });
  }
}

// kalkulacja kosztu wystawienia oferty
function* calcOffertCost({payload}) {
  const options = {
    body: payload,
    method: 'POST'
  };
  try {
    yield put({ type: POST_CALC_COST_STARTED });
    const data = yield call(fetchJSON, process.env.REACT_APP_API_URL+'/api/allegro/calc-offert-cost', options);
    yield put({ type: POST_CALC_COST_SUCCEED, payload: data });
    
  } catch (error) {
    yield put({ type: POST_CALC_COST_FAILED });
  }
}

// Wystawienie produktu allegro
function* postProductAllegro({payload}) {
  // console.log(payload.offert.description.sections);
  document.getElementById('js-addProductAllegro').style.display = 'block';
  let formData = new FormData();
  let offert = JSON.stringify(payload.offert);
  let multishop_options = JSON.stringify(payload.multishop_options);
  formData.append('offert', offert);
  formData.append('multishop_options', multishop_options);
  if(payload.shop_ids) {
    for (var i = 0; i < payload.shop_ids.length; i++) {
      formData.append('shop_ids[]', payload.shop_ids[i]);
    }
  }
  if(payload.images) {
    for (var i = 0; i < payload.images.length; i++) {
      formData.append('images[]', payload.images[i]);
    }
  }
  if(payload.attachments) {
    for (var i = 0; i < payload.attachments.length; i++) {
      formData.append('attachments[]', payload.attachments[i].file);
      formData.append('attachments_types[]', payload.attachments[i].type);
    }
  }
  if(payload.images_desc) {
    for (var i = 0; i < payload.images_desc.length; i++) {
      formData.append('images_desc[]', payload.images_desc[i]);
    }
  }

  let cookies = new Cookies();
  let user = cookies.get('user');
  let auth = user.token;

  const options = {
    body: payload,
    method: 'POST',
    headers: {
      'Content-type': 'multipart/form-data',
      'Authorization': "Bearer " + auth
    }
  };
  // console.log(options);
  try {
    yield put({ type: POST_ALLEGRO_PRODUCTS_STARTED });
    const data = yield call(post(process.env.REACT_APP_API_URL+'/api/allegro/add-draft', formData, options)
    .then(function (response) {
      if (response.data.status) {
        document.getElementById('powiadomienie').style.display = 'block';
        document.getElementById('js-addProductAllegro').style.display = 'none';
        setTimeout(function(){
          window.location.href = '/integracje/allegro';
        }, 2000)
      } else {
        document.getElementById('powiadomienie-error').style.display = 'block';
        document.getElementById('js-addProductAllegro').style.display = 'none';
      }
      
    })
    .catch(function (error) {
      // console.log(error);
      document.getElementById('powiadomienie-error').style.display = 'block';
      document.getElementById('js-addProductAllegro').style.display = 'none';
    }));

    yield put({ type: POST_ALLEGRO_PRODUCTS_SUCCEED, payload: payload });
  } catch (error) {
    yield put({ type: POST_ALLEGRO_PRODUCTS_FAILED, payload: { error } });
  }
}

// Wystawienie produktu allegro
function* modifyProductAllegro({payload}) {
  // console.log(payload.offert.description.sections);
  document.getElementById('js-addProductAllegro').style.display = 'block';
  let formData = new FormData();
  let product = JSON.stringify(payload.data);
  let parameters = JSON.stringify(payload.parameters);
  let multishop_options = JSON.stringify(payload.multishop_options);
  formData.append('product', product);
  formData.append('parameters', parameters);
  formData.append('multishop_options', multishop_options);
  if(payload.shop_ids) {
    for (var i = 0; i < payload.shop_ids.length; i++) {
      formData.append('shop_ids[]', payload.shop_ids[i]);
    }
  }
  if(payload.images) {
    for (var i = 0; i < payload.images.length; i++) {
      formData.append('images[]', payload.images[i]);
    }
  }
  if(payload.attachments) {
    for (var i = 0; i < payload.attachments.length; i++) {
      formData.append('attachments[]', payload.attachments[i].file);
      formData.append('attachments_types[]', payload.attachments[i].type);
    }
  }
  if(payload.images_desc) {
    for (var i = 0; i < payload.images_desc.length; i++) {
      formData.append('images_desc[]', payload.images_desc[i]);
    }
  }

  let cookies = new Cookies();
  let user = cookies.get('user');
  let auth = user.token;

  const options = {
    body: payload,
    method: 'POST',
    headers: {
      'Content-type': 'multipart/form-data',
      'Authorization': "Bearer " + auth
    }
  };
  // console.log(options);
  try {
    yield put({ type: MODIFY_ALLEGRO_PRODUCTS_STARTED });
    const data = yield call(post(process.env.REACT_APP_API_URL+'/api/allegro/modify-offer', formData, options)
    .then(function (response) {
      if (response.data.status) {
        document.getElementById('powiadomienie-edit').style.display = 'block';
        document.getElementById('js-addProductAllegro').style.display = 'none';
        setTimeout(function(){
          window.location.href = '/integracje/allegro';
        }, 2000)
      } else {
        document.getElementById('powiadomienie-edit-error').style.display = 'block';
        document.getElementById('js-addProductAllegro').style.display = 'none';
      }
      
    })
    .catch(function (error) {
      // console.log(error);
      document.getElementById('powiadomienie-edit-error').style.display = 'block';
      document.getElementById('js-addProductAllegro').style.display = 'none';
    }));

    yield put({ type: MODIFY_ALLEGRO_PRODUCTS_SUCCEED, payload: payload });
  } catch (error) {
    yield put({ type: MODIFY_ALLEGRO_PRODUCTS_FAILED, payload: { error } });
  }
}

// zmiana ceny produktu Allegro
function* editProduct({payload}) {
  const options = {
    body: payload,
    method: 'PUT'
  };
  try {
    yield put({ type: POST_EDIT_PRODUCT_STARTED });
    const data = yield call(fetchJSON, process.env.REACT_APP_API_URL+'/api/allegro/edit', options);
    if(!data.status) throw data
    yield put(addToast({
      type: SUCCESS,
      message: POST_EDIT_PRODUCT_SUCCESS,
    }));
    yield put({ type: POST_EDIT_PRODUCT_SUCCEED, payload: payload });
    
  } catch (error) {
    // console.log(error);
    yield put(addToast({
      type: ERROR,
      message: POST_EDIT_PRODUCT_ERROR,
    }));
    yield put({ type: POST_EDIT_PRODUCT_FAILED, payload: { error } });
  }
}

// zgloszenie produktu do kampanii
function* reportBadge({payload}) {
  console.log(payload);
  const options = {
    body: payload,
    method: 'POST'
  };
  try {
    yield put({ type: POST_REPORT_BADGE_STARTED });
    const data = yield call(fetchJSON, process.env.REACT_APP_API_URL+'/api/allegro/report-badge', options);
    if(!data.status) throw data
    yield put({ type: POST_REPORT_BADGE_SUCCEED, payload: payload });
  } catch (error) {
    // console.log(error);
    yield put({ type: POST_REPORT_BADGE_FAILED, payload: { error } });
  }
}

// Wyszukiwarka produktu
function* searchItem({payload}) {
  // console.log(payload)
  try {
    yield put({ type: GET_SEARCH_ITEM_STARTED });
    const data = yield call(fetchJSON, process.env.REACT_APP_API_URL+'/api/allegro/search?s='+payload.s+'&shop_id='+payload.shop_ids[0]);
    const response = data.products;
    yield put({ type: GET_SEARCH_ITEM_SUCCEED, payload: response });
    
  } catch (error) {
    yield put({ type: GET_SEARCH_ITEM_FAILED, payload: { error } });
  }
}

// Wyszukiwarka konkretnej aukcji
function* searchAuction({payload}) {
  // console.log(payload)
  try {
    yield put({ type: GET_SEARCH_AUCTION_STARTED });
    const data = yield call(fetchJSON, process.env.REACT_APP_API_URL+'/api/allegro/search-auction?id='+payload.auction_number+'&shop_id='+payload.shop_ids[0]);
    yield put({ type: GET_SEARCH_AUCTION_SUCCEED, payload: data });
    
  } catch (error) {
    yield put({ type: GET_SEARCH_AUCTION_FAILED, payload: { error } });
  }
}

// Zapisywanie wybranego produktu
function* selectProduct({payload}) {
  try {
    yield put({ type: SAVE_PRODUCT_STARTED });
    yield put({ type: SAVE_PRODUCT_SUCCEED, payload: payload });
    
  } catch (error) {
    yield put({ type: SAVE_PRODUCT_FAILED, payload: { error } });
  }
}

// pobranie wszystkich pól tu skonczylem
function* getCategoryField({payload}) {
  // console.log(payload);
  try {
    yield put({ type: GET_CATEGORY_FIELD_STARTED });
    const data = yield call(fetchJSON, process.env.REACT_APP_API_URL+'/api/allegro/options?shop_id='+payload.shop_ids[0]+'&cat_id='+payload.cat_id);
    yield put({ type: GET_CATEGORY_FIELD_SUCCEED, payload: data });
    
  } catch (error) {
    yield put({ type: GET_CATEGORY_FIELD_FAILED, payload: { error } });
  }
}

// pobranie kategorii produktu
function* getProductCategory({payload}) {
  try {
    yield put({ type: GET_CATEGORY_PRODUCT_STARTED });
    const data = yield call(fetchJSON, process.env.REACT_APP_API_URL+'/api/allegro/category-suggestion?s='+payload.s+'&shop_id='+payload.shop_ids[0]);
    yield put({ type: GET_CATEGORY_PRODUCT_SUCCEED, payload: data.matchingCategories });
    
  } catch (error) {
    yield put({ type: GET_CATEGORY_PRODUCT_FAILED, payload: { error } });
  }
}

// pobranie wszystkich kategorii
function* getAllCategory({payload}) {
  try {
    yield put({ type: GET_ALL_CATEGORY_STARTED });
    const data = yield call(fetchJSON, process.env.REACT_APP_API_URL+'/api/allegro/category-all?id='+payload.id+'&shop_id='+payload.shop_ids[0]);
    yield put({ type: GET_ALL_CATEGORY_SUCCEED, payload: data.categories });
    
  } catch (error) {
    yield put({ type: GET_ALL_CATEGORY_FAILED, payload: { error } });
  }
}


// Zapisywanie wybranego produktu
function* filteredProduct() {
  try {
    yield put({ type: FILTER_PRODUCT_STARTED });
    yield put({ type: FILTER_PRODUCT_SUCCEED });
  } catch (error) {
    yield put({ type: FILTER_PRODUCT_FAILED, payload: { error } });
  }
}

// Zmiana wyboru
function* clearProducts() {
  try {
    yield put({ type: CLEAR_PRODUCT_STARTED });
    yield put({ type: CLEAR_PRODUCT_SUCCEED });
  } catch (error) {
    yield put({ type: CLEAR_PRODUCT_FAILED, payload: { error } });
  }
}

export default function* usersSaga() {
  yield all([
    takeLatest(GET_ALLEGRO_PRODUCTS_REQUESTED, getAllegroProducts),
    takeLatest(GET_ALLEGRO_MESSAGES_REQUESTED, getMessages),
    takeLatest(GET_ALLEGRO_BILLING_REQUESTED, getBilling),
    takeLatest(POST_ALLEGRO_READ_MESSAGES_REQUESTED, changeReadMessage),
    takeLatest(GET_ALLEGRO_MESSAGES_CENTER_REQUESTED, getMessagesCenter),
    takeLatest(POST_ALLEGRO_READ_MESSAGES_CENTER_REQUESTED, changeReadMessageCenter),
    takeLatest(POST_EDIT_PRODUCT_REQUESTED, editProduct),
    takeLatest(POST_REPORT_BADGE_REQUESTED, reportBadge),
    takeLatest(POST_CHECKBOX_UPDATE_REQUESTED, checkboxUpdate),
    takeLatest(GET_SEARCH_ITEM_REQUESTED, searchItem),
    takeLatest(GET_SEARCH_AUCTION_REQUESTED, searchAuction),
    takeLatest(SAVE_PRODUCT_REQUESTED, selectProduct),
    takeLatest(FILTER_PRODUCT_REQUESTED, filteredProduct),
    takeLatest(CLEAR_PRODUCT_REQUESTED, clearProducts),
    takeLatest(GET_CATEGORY_PRODUCT_REQUESTED, getProductCategory),
    takeLatest(GET_ALL_CATEGORY_REQUESTED, getAllCategory),
    takeLatest(POST_ALLEGRO_PRODUCTS_REQUESTED, postProductAllegro),
    takeLatest(MODIFY_ALLEGRO_PRODUCTS_REQUESTED, modifyProductAllegro),
    takeLatest(POST_CALC_COST_REQUESTED, calcOffertCost),
    takeLatest(GET_CATEGORY_FIELD_REQUESTED, getCategoryField),
  ]);
}
