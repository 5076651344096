import { findIndex } from 'lodash';
import {
  INITIAL,
  STARTED,
  SUCCEED,
  FAILED,
} from '../../constants/statusTypes';
import { GET_INNPRO_CATEGORIES_FAILED, GET_INNPRO_CATEGORIES_STARTED, GET_INNPRO_CATEGORIES_SUCCEED, GET_INNPRO_PRODUCTS_FAILED, GET_INNPRO_PRODUCTS_STARTED, GET_INNPRO_PRODUCTS_SUCCEED, IMPORT_INNPRO_PRODUCTS_FAILED, IMPORT_INNPRO_PRODUCTS_STARTED, IMPORT_INNPRO_PRODUCTS_SUCCEED, UPDATE_INNPRO_CATEGORY_FAILED, UPDATE_INNPRO_CATEGORY_STARTED, UPDATE_INNPRO_CATEGORY_SUCCEED } from './actionTypes';

const INIT_STATE = {
  products: [],
  categories: [],
  status: {
    isLoading: false,
    name: INITIAL,
  }
};

const InnproProducts = (state = INIT_STATE, action) => {
  switch (action.type) {
    case IMPORT_INNPRO_PRODUCTS_STARTED:
    case UPDATE_INNPRO_CATEGORY_STARTED:
    case GET_INNPRO_CATEGORIES_STARTED:
    case GET_INNPRO_PRODUCTS_STARTED: {
      const newStatus = {
        name: STARTED,
        isLoading: true,
      }

      return {
        ...state,
        status: newStatus
      };
    }

    // pobranie produktów Innpro
    case GET_INNPRO_PRODUCTS_SUCCEED: {
      const newStatus = {
        name: SUCCEED,
        isLoading: false,
      };
      return {
        ...state,
        status: newStatus,
        products: action.data
      };
    }

    // zaimportowano produkty Innpro
    case IMPORT_INNPRO_PRODUCTS_SUCCEED: {
      const newStatus = {
        name: SUCCEED,
        isLoading: false,
      };

      const newProducts = [...state.products];
      if(Array.isArray(action.data)) {
        action.data.map(innproProduct => {
          const index = findIndex(newProducts, ["id", innproProduct.id]);
          if(index !== -1) newProducts[index] = innproProduct;
        });
      }

      return {
        ...state,
        status: newStatus,
        products: newProducts
      };
    }

    // pobranie kategorii Innpro
    case GET_INNPRO_CATEGORIES_SUCCEED: {
      const newStatus = {
        name: SUCCEED,
        isLoading: false,
      };
      return {
        ...state,
        status: newStatus,
        categories: action.data
      };
    }

    // zaktualizacja kategorii Innpro
    case UPDATE_INNPRO_CATEGORY_SUCCEED: {
      const newStatus = {
        name: SUCCEED,
        isLoading: false,
      };
      if(action.data.id != undefined) {
        const index = findIndex(state.categories.innpro_categories, ["id", action.data.id]);
        if(index !== -1) {
          state.categories.innpro_categories[index] = action.data;
        }
      }
      return {
        ...state,
        status: newStatus
      };
    }

    case UPDATE_INNPRO_CATEGORY_FAILED: 
    case GET_INNPRO_CATEGORIES_FAILED: 
    case IMPORT_INNPRO_PRODUCTS_FAILED: 
    case GET_INNPRO_PRODUCTS_FAILED: {
      const newStatus = {
        name: FAILED,
        isLoading: false,
      };

      return {
        ...state,
        status: newStatus
      };
    }

    default:
      return state;
  }
};

export default InnproProducts;
