export const MAKE_TEST_REQUESTED = 'MAKE_TEST_REQUESTED';
export const MAKE_TEST_STARTED = 'MAKE_TEST_STARTED';
export const MAKE_TEST_SECCESS = 'MAKE_TEST_SECCESS';
export const MAKE_TEST_FAILED = 'MAKE_TEST_FAILED';

export const GET_LOGS_REQUESTED = 'GET_LOGS_REQUESTED';
export const GET_LOGS_STARTED = 'GET_LOGS_STARTED';
export const GET_LOGS_SECCESS = 'GET_LOGS_SECCESS';
export const GET_LOGS_FAILED = 'GET_LOGS_FAILED';

export const CONFIRM_LOG_REQUESTED = 'CONFIRM_LOG_REQUESTED';
export const CONFIRM_LOG_STARTED = 'CONFIRM_LOG_STARTED';
export const CONFIRM_LOG_SECCESS = 'CONFIRM_LOG_SECCESS';
export const CONFIRM_LOG_FAILED = 'CONFIRM_LOG_FAILED';