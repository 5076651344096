import {
  CONFIRM_LOG_REQUESTED,
  MAKE_TEST_REQUESTED,
  GET_LOGS_REQUESTED
} from './actionTypes';

export const makeTest = (params) => ({
  type: MAKE_TEST_REQUESTED,
  payload: params,
});

export const confirmLog = (params) => ({
  type: CONFIRM_LOG_REQUESTED,
  payload: params,
});

export const getLogs = (params) => ({
  type: GET_LOGS_REQUESTED,
  payload: params,
});