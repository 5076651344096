import { all, takeLatest, put, call } from "redux-saga/effects";
import { fetchJSON } from '../../helpers/api';

import {
  GET_PRODUCTS_REQUESTED,
  GET_PRODUCTS_STARTED,
  GET_PRODUCTS_SUCCEED,
  GET_PRODUCTS_FAILED,
  GET_TAXES_SHIPPING_REQUESTED,
  GET_TAXES_SHIPPING_STARTED,
  GET_TAXES_SHIPPING_SUCCEED,
  GET_TAXES_SHIPPING_FAILED,
  POST_BLOCK_REQUESTED,
  POST_BLOCK_STARTED,
  POST_BLOCK_SUCCEED,
  POST_BLOCK_FAILED,
  POST_CHANGE_PRICE_REQUESTED,
  POST_CHANGE_PRICE_STARTED,
  POST_CHANGE_PRICE_SUCCEED,
  POST_CHANGE_PRICE_FAILED,
  POST_CHANGE_PRICE_CENEO_REQUESTED,
  POST_CHANGE_PRICE_CENEO_STARTED,
  POST_CHANGE_PRICE_CENEO_SUCCEED,
  POST_CHANGE_PRICE_CENEO_FAILED,
  POST_CHANGE_TAXES_SHIPPING_PRICE_REQUESTED,
  POST_CHANGE_TAXES_SHIPPING_PRICE_STARTED,
  POST_CHANGE_TAXES_SHIPPING_PRICE_SUCCEED,
  POST_CHANGE_TAXES_SHIPPING_PRICE_FAILED,
} from "./actionTypes";

import { SUCCESS, ERROR } from "../../constants/toastTypes";
import { GET_PRODUCTS_CENEO_SUCCESS, GET_PRODUCTS_CENEO_ERROR,  GET_CENEO_TAXES_SHIPPING_SUCCESS, GET_CENEO_TAXES_SHIPPING_ERROR} from "../../constants/notifications";
import { addToast } from "../toast/actions";

function* getProducts() {
  try {
    yield put({ type: GET_PRODUCTS_STARTED });

    const { products } = yield call(fetchJSON, process.env.REACT_APP_API_URL+'/api/ceneo/products');
    const data = products.map( (item,index) => {
      return {...item, id: index}
    });

    yield put(addToast({
      type: SUCCESS,
      message: GET_PRODUCTS_CENEO_SUCCESS,
    }));

    yield put({ type: GET_PRODUCTS_SUCCEED, payload: { data } });

  } catch (error) {
    yield put(addToast({
      type: ERROR,
      message: GET_PRODUCTS_CENEO_ERROR,
    }));
    yield put({ type: GET_PRODUCTS_FAILED, payload: { error } });
  }
}
function* getTaxesShipping() {
  try {
    yield put({ type: GET_TAXES_SHIPPING_STARTED });

    const { products } = yield call(fetchJSON, process.env.REACT_APP_API_URL+'/api/ceneo/taxesShipping');
    // const data = products.map( (item,index) => {
    //   return {...item, id: index}
    // });

    yield put(addToast({
      type: SUCCESS,
      message: GET_CENEO_TAXES_SHIPPING_SUCCESS,
    }));

    yield put({ type: GET_TAXES_SHIPPING_SUCCEED, payload: { products } });

  } catch (error) {
    yield put(addToast({
      type: ERROR,
      message: GET_CENEO_TAXES_SHIPPING_ERROR,
    }));
    yield put({ type: GET_TAXES_SHIPPING_FAILED, payload: { error } });
  }
}
// aktualizacja checkboxów bloku
function* blockUpdate({payload}) {
  const options = {
    body: payload,
    method: 'POST'
  };
  try {
    yield put({ type: POST_BLOCK_STARTED, payload: payload });
    const data = yield call(fetchJSON, process.env.REACT_APP_API_URL+'/api/ceneo/block', options);
    const send = [payload, data];
    yield put({ type: POST_BLOCK_SUCCEED, payload: send });
    
  } catch (error) {
    yield put({ type: POST_BLOCK_FAILED, payload: { error } });
  }
}
// zmiana ceny produktu
function* changePrice({payload}) {
  const options = {
    body: payload,
    method: 'POST'
  };
  try {
    yield put({ type: POST_CHANGE_PRICE_STARTED });

    const data = yield call(fetchJSON, process.env.REACT_APP_API_URL+'/api/ceneo/product/price', options);

    yield put({ type: POST_CHANGE_PRICE_SUCCEED, payload: { payload, data } });
    
  } catch (error) {
    yield put({ type: POST_CHANGE_PRICE_FAILED, payload: { error } });
  }
}
// zmiana ceny produktów do ceneo
function* changePriceToCeneo() {
  const options = {
    method: 'POST'
  };
  try {
    yield put({ type: POST_CHANGE_PRICE_CENEO_STARTED });

    const data = yield call(fetchJSON, process.env.REACT_APP_API_URL+'/api/ceneo/prices', options);

    alert(data.message);

    yield put({ type: POST_CHANGE_PRICE_CENEO_SUCCEED, payload: data });
    
  } catch (error) {
    yield put({ type: POST_CHANGE_PRICE_CENEO_FAILED, payload: { error } });
  }
}

// zmiana ceny dostaw
function* changeTaxesShippingPrice({payload}) {
  const options = {
    body: payload,
    method: 'POST'
  };
  try {
    yield put({ type: POST_CHANGE_TAXES_SHIPPING_PRICE_STARTED });

    const data = yield call(fetchJSON, process.env.REACT_APP_API_URL+'/api/ceneo/taxesShipping/price', options);

    yield put({ type: POST_CHANGE_TAXES_SHIPPING_PRICE_SUCCEED, payload: payload });
    
  } catch (error) {
    yield put({ type: POST_CHANGE_TAXES_SHIPPING_PRICE_FAILED, payload: { error } });
  }
}

export default function* usersSaga() {
  yield all([
    takeLatest(GET_PRODUCTS_REQUESTED, getProducts),
    takeLatest(GET_TAXES_SHIPPING_REQUESTED, getTaxesShipping),
    takeLatest(POST_BLOCK_REQUESTED, blockUpdate),
    takeLatest(POST_CHANGE_PRICE_REQUESTED, changePrice),
    takeLatest(POST_CHANGE_PRICE_CENEO_REQUESTED, changePriceToCeneo),
    takeLatest(POST_CHANGE_TAXES_SHIPPING_PRICE_REQUESTED, changeTaxesShippingPrice),
  ]);
}
