import { all, takeLatest, put, call } from 'redux-saga/effects';
import { fetchJSON } from '../../helpers/api';
import { SUCCESS, ERROR } from '../../constants/toastTypes';
import { addToast } from '../toast/actions';

import {
  GET_COMPLAINT_COLLECTIONS_REQUESTED,
  GET_COMPLAINT_COLLECTIONS_STARTED,
  GET_COMPLAINT_COLLECTIONS_SECCESS,
  GET_COMPLAINT_COLLECTIONS_FAILED,
  GET_COMPLAINTS_REQUESTED,
  GET_COMPLAINTS_STARTED,
  GET_COMPLAINTS_SECCESS,
  GET_COMPLAINTS_FAILED,
  GET_COMPLAINT_DETAILS_REQUESTED,
  GET_COMPLAINT_DETAILS_STARTED,
  GET_COMPLAINT_DETAILS_SECCESS,
  GET_COMPLAINT_DETAILS_FAILED,
  GET_COMPLAINT_FORM_DATA_REQUESTED,
  GET_COMPLAINT_FORM_DATA_STARTED,
  GET_COMPLAINT_FORM_DATA_SECCESS,
  GET_COMPLAINT_FORM_DATA_FAILED,
  UPDATE_COMPLAINT_INFO_REQUESTED,
  UPDATE_COMPLAINT_INFO_STARTED,
  UPDATE_COMPLAINT_INFO_SECCESS,
  UPDATE_COMPLAINT_INFO_FAILED,
  MAKE_COMPLAINT_ACTION_REQUESTED,
  MAKE_COMPLAINT_ACTION_STARTED,
  MAKE_COMPLAINT_ACTION_SECCESS,
  MAKE_COMPLAINT_ACTION_FAILED,
  SEARCH_COMPLAINT_CLIENTS_REQUESTED,
  SEARCH_COMPLAINT_CLIENTS_STARTED,
  SEARCH_COMPLAINT_CLIENTS_SECCESS,
  SEARCH_COMPLAINT_CLIENTS_FAILED,
  CREATE_NEW_COMPLAINT_REQUESTED,
  CREATE_NEW_COMPLAINT_STARTED,
  CREATE_NEW_COMPLAINT_SECCESS,
  CREATE_NEW_COMPLAINT_FAILED,
  GET_BASELINKER_ORDERS_BY_EMAIL_REQUESTED,
  GET_BASELINKER_ORDERS_BY_EMAIL_STARTED,
  GET_BASELINKER_ORDERS_BY_EMAIL_SECCESS,
  GET_BASELINKER_ORDERS_BY_EMAIL_FAILED,
  GET_COMPLAINT_DHL_ORDERS_STARTED,
  GET_COMPLAINT_DHL_ORDERS_SECCESS,
  GET_COMPLAINT_DHL_ORDERS_FAILED,
  GET_COMPLAINT_DHL_ORDERS_REQUESTED,
} from './actionTypes';

function* getCollections() {
  try {
    yield put({ type: GET_COMPLAINT_COLLECTIONS_STARTED });
    const response = yield callApi('/collections');

    yield serveReponse(
      response,
      'Pomyślnie pobrano listę statusów.',
      GET_COMPLAINT_COLLECTIONS_SECCESS,
      GET_COMPLAINT_COLLECTIONS_FAILED
    );
  } catch (error) {
    console.warn(error);
    yield serveError('Niepoprawna odpowiedź serwera.', GET_COMPLAINT_COLLECTIONS_FAILED);
  }
}

function* getComplaints({ collectionHandle }) {
  try {
    yield put({ type: GET_COMPLAINTS_STARTED });
    const response = yield callApi('/by-collection/' + collectionHandle);

    yield serveReponse(
      response,
      'Pomyślnie pobrano listę zgłoszeń.',
      GET_COMPLAINTS_SECCESS,
      GET_COMPLAINTS_FAILED,
      {'collectionHandle': collectionHandle}
    );
  } catch (error) {
    console.warn(error);
    yield serveError('Niepoprawna odpowiedź serwera.', GET_COMPLAINTS_FAILED);
  }
}

function* getComplaintDhlOrders() {
  try {
    yield put({ type: GET_COMPLAINT_DHL_ORDERS_STARTED });
    const response = yield callApi('/dhl-orders');

    yield serveReponse(
      response,
      'Pomyślnie pobrano listę przesyłek.',
      GET_COMPLAINT_DHL_ORDERS_SECCESS,
      GET_COMPLAINT_DHL_ORDERS_FAILED
    );
  } catch (error) {
    console.warn(error);
    yield serveError('Niepoprawna odpowiedź serwera.', GET_COMPLAINT_DHL_ORDERS_FAILED);
  }
}

function* getComplaintDetails({ complaintId }) {
  try {
    yield put({ type: GET_COMPLAINT_DETAILS_STARTED });
    const response = yield callApi('/complaint/' + complaintId);

    yield serveReponse(
      response,
      'Pomyślnie pobrano szczegóły zgłoszenia.',
      GET_COMPLAINT_DETAILS_SECCESS,
      GET_COMPLAINT_DETAILS_FAILED
    );
  } catch (error) {
    console.warn(error);
    yield serveError('Niepoprawna odpowiedź serwera.', GET_COMPLAINT_DETAILS_FAILED);
  }
}

function* createNewComplaint({ params }) {
  try {
    yield put({ type: CREATE_NEW_COMPLAINT_STARTED });
    const response = yield callApi('/complaint/new/create', 'POST', params);

    yield serveReponse(
      response,
      'Pomyślnie utworzno nowe zgłoszenie.',
      CREATE_NEW_COMPLAINT_SECCESS,
      CREATE_NEW_COMPLAINT_FAILED
    );
  } catch (error) {
    console.warn(error);
    yield serveError('Niepoprawna odpowiedź serwera.', CREATE_NEW_COMPLAINT_FAILED);
  }
}

function* getBaselinkerOrderByEmail({ params }) {
  try {
    yield put({ type: GET_BASELINKER_ORDERS_BY_EMAIL_STARTED });
    const response = yield callApi('/baselinker/orders', 'POST', params);

    yield serveReponse(
      response,
      'Pomyślnie pobrano listę zamówień z BaseLinkera.',
      GET_BASELINKER_ORDERS_BY_EMAIL_SECCESS,
      GET_BASELINKER_ORDERS_BY_EMAIL_FAILED
    );
  } catch (error) {
    console.warn(error);
    yield serveError('Niepoprawna odpowiedź serwera.', GET_BASELINKER_ORDERS_BY_EMAIL_FAILED);
  }
}

function* getFormData() {
  try {
    yield put({ type: GET_COMPLAINT_FORM_DATA_STARTED });
    const response = yield callApi('/complaint/new/form-data');

    yield serveReponse(
      response,
      'Pomyślnie pobrano listę statusów oraz pól dodatkowych.',
      GET_COMPLAINT_FORM_DATA_SECCESS,
      GET_COMPLAINT_FORM_DATA_FAILED
    );
  } catch (error) {
    console.warn(error);
    yield serveError('Niepoprawna odpowiedź serwera.', GET_COMPLAINT_FORM_DATA_FAILED);
  }
}

function* updateComplaintInfo({ complaintId, params }) {
  try {
    yield put({ type: UPDATE_COMPLAINT_INFO_STARTED });
    const response = yield callApi(
      '/complaint/' + complaintId + '/collected-info', 
      'PUT', 
      params
    );

    yield serveReponse(
      response,
      'Pomyślnie zaktualizowano zgłoszenie.',
      UPDATE_COMPLAINT_INFO_SECCESS,
      UPDATE_COMPLAINT_INFO_FAILED
    );
  } catch (error) {
    console.warn(error);
    yield serveError('Niepoprawna odpowiedź serwera.', UPDATE_COMPLAINT_INFO_FAILED);
  }
}

function* makeComplaintAction({ complaintId, actionType, params }) {
  try {
    yield put({ type: MAKE_COMPLAINT_ACTION_STARTED });
    const response = yield callApi(
      '/complaint/' + complaintId + '/action/' + actionType, 
      'POST', 
      params
    );

    yield serveReponse(
      response,
      'Akcja wykonana pomyślnie.',
      MAKE_COMPLAINT_ACTION_SECCESS,
      MAKE_COMPLAINT_ACTION_FAILED
    );
  } catch (error) {
    console.warn(error);
    yield serveError('Niepoprawna odpowiedź serwera.', MAKE_COMPLAINT_ACTION_FAILED);
  }
}

function* searchComplaintClients({ fieldName, fieldValue }) {
  try {
    yield put({ type: SEARCH_COMPLAINT_CLIENTS_STARTED });
    const response = yield callApi('/clients/search-by-field/' + fieldName + '/' + fieldValue);

    yield serveReponse(
      response,
      'Wyszukiwanie zakończone.',
      SEARCH_COMPLAINT_CLIENTS_SECCESS,
      SEARCH_COMPLAINT_CLIENTS_FAILED
    );
  } catch (error) {
    console.warn(error);
    yield serveError('Niepoprawna odpowiedź serwera.', SEARCH_COMPLAINT_CLIENTS_FAILED);
  }
}

export default function* complaintsSaga() {
  yield all([
    takeLatest(GET_COMPLAINT_COLLECTIONS_REQUESTED, getCollections),
    takeLatest(GET_COMPLAINTS_REQUESTED, getComplaints),
    takeLatest(GET_COMPLAINT_DHL_ORDERS_REQUESTED, getComplaintDhlOrders),
    takeLatest(GET_COMPLAINT_DETAILS_REQUESTED, getComplaintDetails),
    takeLatest(GET_BASELINKER_ORDERS_BY_EMAIL_REQUESTED, getBaselinkerOrderByEmail),
    takeLatest(CREATE_NEW_COMPLAINT_REQUESTED, createNewComplaint),
    takeLatest(GET_COMPLAINT_FORM_DATA_REQUESTED, getFormData),
    takeLatest(UPDATE_COMPLAINT_INFO_REQUESTED, updateComplaintInfo),
    takeLatest(MAKE_COMPLAINT_ACTION_REQUESTED, makeComplaintAction),
    takeLatest(SEARCH_COMPLAINT_CLIENTS_REQUESTED, searchComplaintClients),
  ]);
}

//=============================================================================================
// Fetch API and serve response/error methods

const callApi = (endpoint, method = 'GET', body = []) => {
  const formData = new FormData();
  const useFormData = Object.keys(body).includes('files');

  if(useFormData) {
    // Append body data to form data
    appendDataToForm(formData, body);
  }

  return call(fetchJSON, process.env.REACT_APP_API_URL + '/api/complaints' + endpoint, {
    method: method,
    headers: useFormData ? {} : { 'Content-Type': 'application/json' },
    body: method != 'GET' ? (useFormData ? formData : body) : null
  }, true, !useFormData);
}

const appendDataToForm = (formData, appendData, namePrefix = null) => {
  if(appendData instanceof File) {
    const finalName = namePrefix ? namePrefix : 'file';
    formData.append(finalName, appendData);
    return;
  }

  if(namePrefix != null && (appendData == null || typeof appendData === 'string' || typeof appendData === 'number')) {
    formData.append(namePrefix, appendData);
    return;
  }

  Object.keys(appendData).forEach(paramName => {
    if(Array.isArray(appendData[paramName]) && appendData[paramName].length) {
      appendData[paramName].forEach(arrayElement => {
        appendDataToForm(formData, arrayElement, paramName + "[]");
      });
      return;
    }

    if(typeof appendData[paramName] === 'object' && 
      !Array.isArray(appendData[paramName]) && 
      appendData[paramName] !== null
    ) {
      appendDataToForm(formData, appendData[paramName], paramName);
      return;
    }

    const finalName = namePrefix ? (namePrefix + "[" + paramName + "]") : paramName;
    formData.append(finalName, appendData[paramName]);
  });
}

function* serveReponse(response, successMessage, seccessType, failedType, customData = null) {
  if (response.success) {
    yield put(
      addToast({
        type: SUCCESS,
        message: successMessage,
      })
    );
    yield put({ type: seccessType, data: response.data, customData: customData });
  } else {
    yield serveError(response.error, failedType);
  }
}

function* serveError(errorMessages, failedType) {
  yield put(
    addToast({
      type: ERROR,
      message: errorMessages,
    })
  );
  yield put({ type: failedType, data: null });
}
