export const GET_INNPRO_PRODUCTS_REQUESTED = 'GET_INNPRO_PRODUCTS_REQUESTED';
export const GET_INNPRO_PRODUCTS_STARTED = 'GET_INNPRO_PRODUCTS_STARTED';
export const GET_INNPRO_PRODUCTS_SUCCEED = 'GET_INNPRO_PRODUCTS_SUCCEED';
export const GET_INNPRO_PRODUCTS_FAILED = 'GET_INNPRO_PRODUCTS_FAILED';

export const IMPORT_INNPRO_PRODUCTS_REQUESTED = 'IMPORT_INNPRO_PRODUCTS_REQUESTED';
export const IMPORT_INNPRO_PRODUCTS_STARTED = 'IMPORT_INNPRO_PRODUCTS_STARTED';
export const IMPORT_INNPRO_PRODUCTS_SUCCEED = 'IMPORT_INNPRO_PRODUCTS_SUCCEED';
export const IMPORT_INNPRO_PRODUCTS_FAILED = 'IMPORT_INNPRO_PRODUCTS_FAILED';

export const GET_INNPRO_CATEGORIES_REQUESTED = 'GET_INNPRO_CATEGORIES_REQUESTED';
export const GET_INNPRO_CATEGORIES_STARTED = 'GET_INNPRO_CATEGORIES_STARTED';
export const GET_INNPRO_CATEGORIES_SUCCEED = 'GET_INNPRO_CATEGORIES_SUCCEED';
export const GET_INNPRO_CATEGORIES_FAILED = 'GET_INNPRO_CATEGORIES_FAILED';

export const UPDATE_INNPRO_CATEGORY_REQUESTED = 'UPDATE_INNPRO_CATEGORY_REQUESTED';
export const UPDATE_INNPRO_CATEGORY_STARTED = 'UPDATE_INNPRO_CATEGORY_STARTED';
export const UPDATE_INNPRO_CATEGORY_SUCCEED = 'UPDATE_INNPRO_CATEGORY_SUCCEED';
export const UPDATE_INNPRO_CATEGORY_FAILED = 'UPDATE_INNPRO_CATEGORY_FAILED';