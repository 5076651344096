import {
  INITIAL,
  STARTED,
  SUCCEED,
  FAILED,
} from '../../constants/statusTypes';

import {
  GET_ALLEGRO_PRODUCTS_STARTED,
  GET_ALLEGRO_PRODUCTS_SUCCEED,
  GET_ALLEGRO_PRODUCTS_FAILED,
  GET_ALLEGRO_MESSAGES_STARTED,
  GET_ALLEGRO_MESSAGES_SUCCEED,
  GET_ALLEGRO_MESSAGES_FAILED,
  GET_ALLEGRO_BILLING_STARTED,
  GET_ALLEGRO_BILLING_SUCCEED,
  GET_ALLEGRO_BILLING_FAILED,
  POST_ALLEGRO_READ_MESSAGES_STARTED,
  POST_ALLEGRO_READ_MESSAGES_SUCCEED,
  POST_ALLEGRO_READ_MESSAGES_FAILED,
  GET_ALLEGRO_MESSAGES_CENTER_STARTED,
  GET_ALLEGRO_MESSAGES_CENTER_SUCCEED,
  GET_ALLEGRO_MESSAGES_CENTER_FAILED,
  POST_ALLEGRO_READ_MESSAGES_CENTER_STARTED,
  POST_ALLEGRO_READ_MESSAGES_CENTER_SUCCEED,
  POST_ALLEGRO_READ_MESSAGES_CENTER_FAILED,
  POST_ALLEGRO_PRODUCTS_STARTED,
  POST_ALLEGRO_PRODUCTS_FAILED,
  MODIFY_ALLEGRO_PRODUCTS_STARTED,
  MODIFY_ALLEGRO_PRODUCTS_FAILED,
  POST_CALC_COST_FAILED,
  POST_CALC_COST_STARTED,
  POST_CALC_COST_SUCCEED,
  POST_CHECKBOX_UPDATE_STARTED,
  POST_CHECKBOX_UPDATE_SUCCEED,
  POST_CHECKBOX_UPDATE_FAILED,
  POST_EDIT_PRODUCT_STARTED,
  POST_EDIT_PRODUCT_SUCCEED,
  POST_EDIT_PRODUCT_FAILED,
  POST_REPORT_BADGE_STARTED,
  POST_REPORT_BADGE_SUCCEED,
  POST_REPORT_BADGE_FAILED,
  GET_SEARCH_ITEM_STARTED,
  GET_SEARCH_ITEM_SUCCEED,
  GET_SEARCH_ITEM_FAILED,
  GET_SEARCH_AUCTION_STARTED,
  GET_SEARCH_AUCTION_SUCCEED,
  GET_SEARCH_AUCTION_FAILED,
  SAVE_PRODUCT_STARTED,
  SAVE_PRODUCT_SUCCEED,
  SAVE_PRODUCT_FAILED,
  FILTER_PRODUCT_REQUESTED,
  CLEAR_PRODUCT_REQUESTED,
  GET_CATEGORY_PRODUCT_FAILED,
  GET_CATEGORY_PRODUCT_STARTED,
  GET_CATEGORY_PRODUCT_SUCCEED,
  GET_ALL_CATEGORY_FAILED,
  GET_ALL_CATEGORY_STARTED,
  GET_ALL_CATEGORY_SUCCEED,
  GET_CATEGORY_FIELD_FAILED,
  GET_CATEGORY_FIELD_STARTED,
  GET_CATEGORY_FIELD_SUCCEED,
} from './actionTypes';

import { findIndex } from 'lodash';

const INIT_STATE = {
  data: {
    index: [],
    indexSearch: [],
    options: null
  },
  messages: [],
  billings: [],
  messagesCenter: [],
  newDataProduct: {
    id: null,
    chooseCategory: null,
    productCategories: [],
    fields: [],
    otherData: {
      name: ''
    },

  },
  allCat: {
    updated: []
  },
  calcOffertCost: {},
  error: '',
  status: {
    isLoading: false,
    name: INITIAL,
  },
  showRightSidebar: false,
};

const allegroProducts = (state = INIT_STATE, action) => {

  switch (action.type) {
    case GET_ALLEGRO_PRODUCTS_STARTED:
    case POST_ALLEGRO_READ_MESSAGES_STARTED:
    case GET_ALLEGRO_MESSAGES_STARTED:
    case GET_ALLEGRO_BILLING_STARTED:
    case POST_ALLEGRO_READ_MESSAGES_CENTER_STARTED:
    case GET_ALLEGRO_MESSAGES_CENTER_STARTED:
    case GET_SEARCH_ITEM_STARTED:
    case SAVE_PRODUCT_STARTED:
    case MODIFY_ALLEGRO_PRODUCTS_STARTED:
    case GET_CATEGORY_PRODUCT_STARTED:
    case GET_SEARCH_AUCTION_STARTED:
    case GET_ALL_CATEGORY_STARTED:
    case GET_CATEGORY_FIELD_STARTED:
    case POST_ALLEGRO_PRODUCTS_STARTED:
    case POST_CALC_COST_STARTED:
    case POST_REPORT_BADGE_STARTED:
    case POST_EDIT_PRODUCT_STARTED: {
      const newStatus = {
        name: STARTED,
        isLoading: true,
      }

      return {
        ...state,
        status: newStatus
      };
    }

    case POST_CHECKBOX_UPDATE_STARTED: {
      const newStatus = {
        name: STARTED,
        isLoading: true,
      }
      const index = findIndex(state.data.index, ["auction_number", action.payload.product_id]);
      state.data.index[index].checkBoxRefresh = false;

      return {
        ...state,
        status: newStatus
      };
    }

    case POST_CALC_COST_SUCCEED: {
      const newStatus = {
        name: SUCCEED,
        isLoading: false,
      };

      return {
        ...state,
        status: newStatus,
        calcOffertCost: action.payload
      };
    }

    // pobranie produktów allegro
    case GET_ALLEGRO_PRODUCTS_SUCCEED: {
      const newStatus = {
        name: SUCCEED,
        isLoading: false,
      };
      return {
        ...state,
        status: newStatus,
        data: {
          ...state.data,
          index: action.payload.data.products,
          options: action.payload.data.options
        }
      };
    }
    // pobranie komunikatów allegro
    case GET_ALLEGRO_MESSAGES_SUCCEED: {
      const newStatus = {
        name: SUCCEED,
        isLoading: false,
      };
      return {
        ...state,
        status: newStatus,
        messages: action.payload.data
      };
    }
    // pobranie rozliczeń z allegro
    case GET_ALLEGRO_BILLING_SUCCEED: {
      const newStatus = {
        name: SUCCEED,
        isLoading: false,
      };
      return {
        ...state,
        status: newStatus,
        billings: action.payload.data
      };
    }
    // pobranie centrum wiadomości allegro
    case GET_ALLEGRO_MESSAGES_CENTER_SUCCEED: {
      const newStatus = {
        name: SUCCEED,
        isLoading: false,
      };
      return {
        ...state,
        status: newStatus,
        messagesCenter: action.payload.data
      };
    }
    // odznacz nieprzeczytane komunikatów allegro
    case POST_ALLEGRO_READ_MESSAGES_SUCCEED: {
      const newStatus = {
        name: SUCCEED,
        isLoading: false,
      };
      if(action.payload.id != null) {
        const index = findIndex(state.messages, ["id", action.payload.id]);
        if(action.payload.status) {
          state.messages[index].read = 1;
        }
      } else {
        window.location.reload();
      }
      return {
        ...state,
        status: newStatus,
      };
    }
    // odznacz nieprzeczytane centrum wiadomości allegro
    case POST_ALLEGRO_READ_MESSAGES_CENTER_SUCCEED: {
      const newStatus = {
        name: SUCCEED,
        isLoading: false,
      };
      console.log(action.payload);
      if(action.payload.id != null) {
        const index = findIndex(state.messagesCenter, ["id", action.payload.id]);
        console.log(index);
        if(action.payload.status) {
          state.messagesCenter[index].read = 1;
        }
      } else {
        window.location.reload();
      }
      return {
        ...state,
        status: newStatus,
      };
    }
    // Wyszukiwanie produktu
    case GET_SEARCH_ITEM_SUCCEED: {
      const newStatus = {
        name: SUCCEED,
        isLoading: false,
      };
      return {
        ...state,
        status: newStatus,
        data: {
          ...state.data,
          indexSearch: action.payload
        }
      };
    }
    
    // Wyszukiwanie konrektnego produktu
    case GET_SEARCH_AUCTION_SUCCEED: {
      const newStatus = {
        name: SUCCEED,
        isLoading: false,
      };
      return {
        ...state,
        status: newStatus,
        data: {
          ...state.data,
          indexSearch: action.payload
        }
      };
    }
    // pobranie kategorii produktu
    case GET_CATEGORY_PRODUCT_SUCCEED: {
      const newStatus = {
        name: SUCCEED,
        isLoading: false,
      };
      return {
        ...state,
        status: newStatus,
        newDataProduct: {
          ...state.newDataProduct,
          productCategories: action.payload
        },
      };
    }

    // pobranie wszystkich kategorii
    case GET_ALL_CATEGORY_SUCCEED: {
      const newStatus = {
        name: SUCCEED,
        isLoading: false,
      };
      return {
        ...state,
        status: newStatus,
        allCat: {
          ...state.allCat,
          updated: action.payload
        },
      };
    }
    // pobranie pól po kategorii
    case GET_CATEGORY_FIELD_SUCCEED: {
      const newStatus = {
        name: SUCCEED,
        isLoading: false,
      };
      return {
        ...state,
        status: newStatus,
        newDataProduct: {
          ...state.newDataProduct,
          fields: action.payload
        },
      };
    }
    // filtrowanie produktu
    case FILTER_PRODUCT_REQUESTED: {
      const newStatus = {
        name: SUCCEED,
        isLoading: false,
      };
      return {
        ...state,
        status: newStatus,
        data: {
          ...state.data,
          indexSearch: state.data.indexSearch.filter(index => index.id === state.newDataProduct.id)
        }
      };
    }
    // ZMIANA WYBORU
    case CLEAR_PRODUCT_REQUESTED: {
      const newStatus = {
        name: SUCCEED,
        isLoading: false,
      };
      return {
        ...state,
        status: newStatus,
        data: {
          index: [],
          indexSearch: [],
          options: null
        },
        calcOffertCost: {},
        newDataProduct: {
          id: null,
          chooseCategory: null,
          productCategories: [],
          fields: [],
          otherData: {
            name: ''
          },
        }
      };
    }
    // Zapisanie wybranego produktu
    case SAVE_PRODUCT_SUCCEED: {
      const newStatus = {
        name: SUCCEED,
        isLoading: false,
      };
      return {
        ...state,
        status: newStatus,
        newDataProduct: {
          ...state.newDataProduct,
          otherData: action.payload.data,
          id: action.payload.id,
          chooseCategory: action.payload.mainCategory
        }
      };
    }

    // ZMIANA CENY ALLEGRO
    case POST_EDIT_PRODUCT_SUCCEED: {
      const newStatus = {
        name: SUCCEED,
        isLoading: false,
      };
      const index = findIndex(state.data.index, ["auction_number", action.payload.auction_id]);
      var params = [];
      if(action.payload.stock) state.data.index[index].stock = action.payload.stock;
      if(action.payload.price) state.data.index[index].price = action.payload.price;
      if(action.payload.status) state.data.index[index].status = action.payload.status;
      if(action.payload.promotion) state.data.index[index].promotion = action.payload.promotion;
      if(action.payload.id_magento) state.data.index[index].id_magento = action.payload.id_magento;
      return {
        ...state,
        status: newStatus,
      };
    }

    // ZMIANA CENY ALLEGRO
    case POST_REPORT_BADGE_SUCCEED: {
      const newStatus = {
        name: SUCCEED,
        isLoading: false,
      };
      return {
        ...state,
        status: newStatus,
      };
    }

    case POST_CHECKBOX_UPDATE_SUCCEED: {
      const newStatus = {
        name: SUCCEED,
        isLoading: false,
      };
      const index = findIndex(state.data.index, ["auction_number", action.payload.product_id]);
      if(action.payload.content === 'state') {
        state.data.index[index].sync_state = action.payload.change;
      } else if (action.payload.content === 'state_change_price') {
        state.data.index[index].sync_state_change_price = action.payload.change;
      } else if (action.payload.content === 'status') {
        state.data.index[index].sync_status = action.payload.change;
      } else if (action.payload.content === 'block_up') {
        state.data.index[index].block_sync_price_up = action.payload.change;
      } else if (action.payload.content === 'block_down') {
        state.data.index[index].block_sync_price_down = action.payload.change;
      } 
      state.data.index[index].checkBoxRefresh = true;
      return {
        ...state,
        status: newStatus
      }
    }

    case POST_EDIT_PRODUCT_FAILED:
    case POST_ALLEGRO_READ_MESSAGES_FAILED:
    case GET_ALLEGRO_MESSAGES_FAILED:
    case GET_ALLEGRO_BILLING_FAILED:
    case POST_ALLEGRO_READ_MESSAGES_CENTER_FAILED:
    case GET_ALLEGRO_MESSAGES_CENTER_FAILED:
    case POST_ALLEGRO_PRODUCTS_FAILED:
    case POST_CALC_COST_FAILED:
    case MODIFY_ALLEGRO_PRODUCTS_FAILED:
    case GET_SEARCH_AUCTION_FAILED:
    case GET_CATEGORY_FIELD_FAILED: 
    case POST_CHECKBOX_UPDATE_FAILED: 
    case POST_REPORT_BADGE_FAILED: 
    case GET_CATEGORY_PRODUCT_FAILED: {
      const newStatus = {
        name: FAILED,
        isLoading: false,
      };

      return {
        ...state,
        status: newStatus,
      };
    }

    case GET_ALLEGRO_PRODUCTS_FAILED:  {
      const newStatus = {
        name: FAILED,
        isLoading: false,
      };

      return {
        ...state,
        status: newStatus,
        error: action.error,
        data: {
          ...state.data,
          index: []
        }
      };
    }
    // wyszukiwanie produktu
    case GET_SEARCH_ITEM_FAILED: {
      const newStatus = {
        name: FAILED,
        isLoading: false,
      };

      return {
        ...state,
        status: newStatus,
        error: action.error,
        data: {
          ...state.data,
          indexSearch: []
        }
      };
    }

    // pobieranie wszystkich kategorii
    case GET_ALL_CATEGORY_FAILED: {
      const newStatus = {
        name: FAILED,
        isLoading: false,
      };

      return {
        ...state,
        status: newStatus,
        allCat: {
          ...state.allCat,
          updated: []
        }
      };
    }
    // wyszukiwanie produktu
    case SAVE_PRODUCT_FAILED: {
      const newStatus = {
        name: FAILED,
        isLoading: false,
      };

      return {
        ...state,
        status: newStatus,
        error: action.error,
        newDataProduct: {
          ...state.data,
          id: null,
          chooseCategory: null
        }
      };
    }
    default:
      return state;
  }
};

export default allegroProducts;
