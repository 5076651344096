import { all, takeLatest, put, call } from "redux-saga/effects";
import { fetchJSON } from '../../helpers/api';

import {
  GET_AB_PRODUCTS_REQUESTED,
  GET_AB_PRODUCTS_STARTED,
  GET_AB_PRODUCTS_SUCCEED,
  GET_AB_PRODUCTS_FAILED,
  POST_IMPORT_AB_REQUESTED,
  POST_IMPORT_AB_STARTED,
  POST_IMPORT_AB_SUCCEED,
  POST_IMPORT_AB_FAILED
} from "./actionTypes";

import { SUCCESS, ERROR } from "../../constants/toastTypes";
import { GET_PRODUCTS_AB_SUCCESS, GET_IMPORT_AB_SUCCESS, GET_IMPORT_AB_ERROR, GET_PRODUCTS_AB_ERROR } from "../../constants/notifications";
import { addToast } from "../toast/actions";

function* getABProducts() {
  try {
    yield put({ type: GET_AB_PRODUCTS_STARTED });

    const products = yield call(fetchJSON, process.env.REACT_APP_API_URL+'/api/AB/getProducts');

    yield put(addToast({
      type: SUCCESS,
      message: GET_PRODUCTS_AB_SUCCESS,
    }));

    yield put({ type: GET_AB_PRODUCTS_SUCCEED, payload: { products } });
 
  } catch (error) {
    yield put(addToast({
      type: ERROR,
      message: GET_PRODUCTS_AB_ERROR,
    }));
    yield put({ type: GET_AB_PRODUCTS_FAILED, payload: { error } });
  }
}

// import
function* importProductAB({payload}) {
  const options = {
    body: payload,
    method: 'POST'
  };
  try {
    yield put({ type: POST_IMPORT_AB_STARTED, payload: payload  });
    const data = yield call(fetchJSON, process.env.REACT_APP_API_URL+'/api/AB/changeSettings', options);
    yield put({ type: POST_IMPORT_AB_SUCCEED, payload: payload });
  } catch (error) {
    yield put(addToast({
      type: ERROR,
      message: GET_IMPORT_AB_ERROR,
    }));
    yield put({ type: POST_IMPORT_AB_FAILED, payload: { error } });
  }
}



export default function* usersSaga() {
  yield all([
    takeLatest(GET_AB_PRODUCTS_REQUESTED, getABProducts),
    takeLatest(POST_IMPORT_AB_REQUESTED, importProductAB),
  ]);
}
