import { all, takeLatest, put, call } from "redux-saga/effects";
import { fetchJSON } from '../../helpers/api';

import {
  GET_INNPRO_PRODUCTS_STARTED,
  GET_INNPRO_PRODUCTS_SUCCEED,
  GET_INNPRO_PRODUCTS_FAILED,
  GET_INNPRO_PRODUCTS_REQUESTED,
  IMPORT_INNPRO_PRODUCTS_STARTED,
  IMPORT_INNPRO_PRODUCTS_SUCCEED,
  IMPORT_INNPRO_PRODUCTS_FAILED,
  IMPORT_INNPRO_PRODUCTS_REQUESTED,
  GET_INNPRO_CATEGORIES_STARTED,
  GET_INNPRO_CATEGORIES_SUCCEED,
  GET_INNPRO_CATEGORIES_FAILED,
  GET_INNPRO_CATEGORIES_REQUESTED,
  UPDATE_INNPRO_CATEGORY_STARTED,
  UPDATE_INNPRO_CATEGORY_SUCCEED,
  UPDATE_INNPRO_CATEGORY_FAILED,
  UPDATE_INNPRO_CATEGORY_REQUESTED
} from "./actionTypes";
import { addToast } from "../toast/actions";
import { ERROR, SUCCESS } from "../../constants/toastTypes";

function* getInnproProducts() {
  try {
    yield put({ type: GET_INNPRO_PRODUCTS_STARTED });
    const response = yield callApi('/products');

    yield serveReponse(
      response,
      'Pomyślnie pobrano listę produktów.',
      GET_INNPRO_PRODUCTS_SUCCEED,
      GET_INNPRO_PRODUCTS_FAILED
    );
  } catch (error) {
    console.warn(error);
    yield serveError('Niepoprawna odpowiedź serwera.', GET_INNPRO_PRODUCTS_FAILED);
  }
}

function* importInnproProducts({ ids }) {
  try {
    yield put({ type: IMPORT_INNPRO_PRODUCTS_STARTED });
    const response = yield callApi('/products-import', 'POST', { products_ids: ids });

    yield serveReponse(
      response,
      'Pomyślnie dodano produkty do generatora.',
      IMPORT_INNPRO_PRODUCTS_SUCCEED,
      IMPORT_INNPRO_PRODUCTS_FAILED
    );
  } catch (error) {
    console.warn(error);
    yield serveError('Niepoprawna odpowiedź serwera.', IMPORT_INNPRO_PRODUCTS_FAILED);
  }
}

function* getInnproCategories() {
  try {
    yield put({ type: GET_INNPRO_CATEGORIES_STARTED });
    const response = yield callApi('/categories');

    yield serveReponse(
      response,
      'Pomyślnie pobrano listę kategorii.',
      GET_INNPRO_CATEGORIES_SUCCEED,
      GET_INNPRO_CATEGORIES_FAILED
    );
  } catch (error) {
    console.warn(error);
    yield serveError('Niepoprawna odpowiedź serwera.', GET_INNPRO_CATEGORIES_FAILED);
  }
}

function* updateInnproCategory({ params }) {
  try {
    yield put({ type: UPDATE_INNPRO_CATEGORY_STARTED });
    const response = yield callApi('/update-category-map', 'POST', params);

    yield serveReponse(
      response,
      'Pomyślnie zaktualizowano kategorie.',
      UPDATE_INNPRO_CATEGORY_SUCCEED,
      UPDATE_INNPRO_CATEGORY_FAILED
    );
  } catch (error) {
    console.warn(error);
    yield serveError('Niepoprawna odpowiedź serwera.', UPDATE_INNPRO_CATEGORY_FAILED);
  }
}

export default function* innproSaga() {
  yield all([
    takeLatest(GET_INNPRO_PRODUCTS_REQUESTED, getInnproProducts),
    takeLatest(IMPORT_INNPRO_PRODUCTS_REQUESTED, importInnproProducts),
    takeLatest(GET_INNPRO_CATEGORIES_REQUESTED, getInnproCategories),
    takeLatest(UPDATE_INNPRO_CATEGORY_REQUESTED, updateInnproCategory),
  ]);
}

const callApi = (endpoint, method = 'GET', body = []) => {
  return call(fetchJSON, process.env.REACT_APP_API_URL + '/api/innpro' + endpoint, {
    method: method,
    headers: { 'Content-Type': 'application/json' },
    body: method != 'GET' ? body : null
  }, true, true);
}

function* serveReponse(response, successMessage, seccessType, failedType, customData = null) {
  if (response.success) {
    yield put(
      addToast({
        type: SUCCESS,
        message: successMessage,
      })
    );
    yield put({ type: seccessType, data: response.data, customData: customData });
  } else {
    yield serveError(response.error, failedType);
  }
}

function* serveError(errorMessages, failedType) {
  yield put(
    addToast({
      type: ERROR,
      message: errorMessages,
    })
  );
  yield put({ type: failedType, data: null });
}
