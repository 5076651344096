// Complaint - listing
export const GET_COMPLAINT_COLLECTIONS_REQUESTED = 'GET_COMPLAINT_COLLECTIONS_REQUESTED';
export const GET_COMPLAINT_COLLECTIONS_STARTED = 'GET_COMPLAINT_COLLECTIONS_STARTED';
export const GET_COMPLAINT_COLLECTIONS_SECCESS = 'GET_COMPLAINT_COLLECTIONS_SECCESS';
export const GET_COMPLAINT_COLLECTIONS_FAILED = 'GET_COMPLAINT_COLLECTIONS_FAILED';

export const GET_COMPLAINTS_REQUESTED = 'GET_COMPLAINTS_REQUESTED';
export const GET_COMPLAINTS_STARTED = 'GET_COMPLAINTS_STARTED';
export const GET_COMPLAINTS_SECCESS = 'GET_COMPLAINTS_SECCESS';
export const GET_COMPLAINTS_FAILED = 'GET_COMPLAINTS_FAILED';

// Complaint - single
export const GET_COMPLAINT_DETAILS_REQUESTED = 'GET_COMPLAINT_DETAILS_REQUESTED';
export const GET_COMPLAINT_DETAILS_STARTED = 'GET_COMPLAINT_DETAILS_STARTED';
export const GET_COMPLAINT_DETAILS_SECCESS = 'GET_COMPLAINT_DETAILS_SECCESS';
export const GET_COMPLAINT_DETAILS_FAILED = 'GET_COMPLAINT_DETAILS_FAILED';

export const GET_BASELINKER_ORDERS_BY_EMAIL_REQUESTED = 'GET_BASELINKER_ORDERS_BY_EMAIL_REQUESTED';
export const GET_BASELINKER_ORDERS_BY_EMAIL_STARTED = 'GET_BASELINKER_ORDERS_BY_EMAIL_STARTED';
export const GET_BASELINKER_ORDERS_BY_EMAIL_SECCESS = 'GET_BASELINKER_ORDERS_BY_EMAIL_SECCESS';
export const GET_BASELINKER_ORDERS_BY_EMAIL_FAILED = 'GET_BASELINKER_ORDERS_BY_EMAIL_FAILED';

export const CREATE_NEW_COMPLAINT_REQUESTED = 'CREATE_NEW_COMPLAINT_REQUESTED';
export const CREATE_NEW_COMPLAINT_STARTED = 'CREATE_NEW_COMPLAINT_STARTED';
export const CREATE_NEW_COMPLAINT_SECCESS = 'CREATE_NEW_COMPLAINT_SECCESS';
export const CREATE_NEW_COMPLAINT_FAILED = 'CREATE_NEW_COMPLAINT_FAILED';

// Complaint - collected info
export const GET_COMPLAINT_FORM_DATA_REQUESTED = 'GET_COMPLAINT_FORM_DATA_REQUESTED';
export const GET_COMPLAINT_FORM_DATA_STARTED = 'GET_COMPLAINT_FORM_DATA_STARTED';
export const GET_COMPLAINT_FORM_DATA_SECCESS = 'GET_COMPLAINT_FORM_DATA_SECCESS';
export const GET_COMPLAINT_FORM_DATA_FAILED = 'GET_COMPLAINT_FORM_DATA_FAILED';

export const UPDATE_COMPLAINT_INFO_REQUESTED = 'UPDATE_COMPLAINT_INFO_REQUESTED';
export const UPDATE_COMPLAINT_INFO_STARTED = 'UPDATE_COMPLAINT_INFO_STARTED';
export const UPDATE_COMPLAINT_INFO_SECCESS = 'UPDATE_COMPLAINT_INFO_SECCESS';
export const UPDATE_COMPLAINT_INFO_FAILED = 'UPDATE_COMPLAINT_INFO_FAILED';

// Complaint - actions
export const MAKE_COMPLAINT_ACTION_REQUESTED = 'MAKE_COMPLAINT_ACTION_REQUESTED';
export const MAKE_COMPLAINT_ACTION_STARTED = 'MAKE_COMPLAINT_ACTION_STARTED';
export const MAKE_COMPLAINT_ACTION_SECCESS = 'MAKE_COMPLAINT_ACTION_SECCESS';
export const MAKE_COMPLAINT_ACTION_FAILED = 'MAKE_COMPLAINT_ACTION_FAILED';

// Complaint - clients
export const SEARCH_COMPLAINT_CLIENTS_REQUESTED = 'SEARCH_COMPLAINT_CLIENTS_REQUESTED';
export const SEARCH_COMPLAINT_CLIENTS_STARTED = 'SEARCH_COMPLAINT_CLIENTS_STARTED';
export const SEARCH_COMPLAINT_CLIENTS_SECCESS = 'SEARCH_COMPLAINT_CLIENTS_SECCESS';
export const SEARCH_COMPLAINT_CLIENTS_FAILED = 'SEARCH_COMPLAINT_CLIENTS_FAILED';

// Complaint - dhl orders
export const GET_COMPLAINT_DHL_ORDERS_REQUESTED = 'GET_COMPLAINT_DHL_ORDERS_REQUESTED';
export const GET_COMPLAINT_DHL_ORDERS_STARTED = 'GET_COMPLAINT_DHL_ORDERS_STARTED';
export const GET_COMPLAINT_DHL_ORDERS_SECCESS = 'GET_COMPLAINT_DHL_ORDERS_SECCESS';
export const GET_COMPLAINT_DHL_ORDERS_FAILED = 'GET_COMPLAINT_DHL_ORDERS_FAILED';
