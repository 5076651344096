import {
  INITIAL,
  STARTED,
  SUCCEED,
  FAILED,
} from '../../constants/statusTypes';

import {
  GET_PRODUCTS_TO_IMPROVE_STARTED,
  GET_PRODUCTS_TO_IMPROVE_SUCCEED,
  GET_PRODUCTS_TO_IMPROVE_FAILED,
  GET_MAG_SUPP_RULES_STARTED,
  GET_MAG_SUPP_RULES_SUCCEED,
  GET_MAG_SUPP_RULES_FAILED,
  POST_CHECKLIST_STARTED,
  POST_CHECKLIST_SUCCEED,
  POST_CHECKLIST_FAILED,
  PUT_NEW_PRODUCTS_STARTED,
  PUT_NEW_PRODUCTS_SUCCEED,
  PUT_NEW_PRODUCTS_FAILED,
  DELETE_PRODUCT_STARTED,
  DELETE_PRODUCT_SUCCEED,
  DELETE_PRODUCT_FAILED,
  POST_REINDEX_STARTED,
  POST_REINDEX_SUCCEED,
  POST_REINDEX_FAILED,
  EDIT_PRODUCT_STARTED,
  EDIT_PRODUCT_SUCCEED,
  EDIT_PRODUCT_FAILED,
  SAVE_G_PRODUCT_STARTED,
  SAVE_G_PRODUCT_SUCCEED,
  SAVE_G_PRODUCT_FAILED,
  POST_TAKE_ACTION_STARTED,
  POST_TAKE_ACTION_FAILED,
  POST_TAKE_ACTION_SUCCEED,
  ADD_PRODUCTS_FROM_FILE_STARTED,
  ADD_PRODUCTS_FROM_FILE_FAILED,
  ADD_PRODUCTS_FROM_FILE_SUCCEED,
} from './actionTypes';

import { findIndex } from 'lodash';

const INIT_STATE = {
  data: {
    index: [],
    magSuppliers: [],
    magRules: [],
    magManufacturers: [],
    magCats: [],
    editProduct: null
  },
  error: '',
  status: {
    isLoading: false,
    name: INITIAL,
  }
};

const ProductGenerator = (state = INIT_STATE, action) => {

  switch (action.type) {
    case ADD_PRODUCTS_FROM_FILE_STARTED:
    case SAVE_G_PRODUCT_STARTED:
    case EDIT_PRODUCT_STARTED:
    case GET_PRODUCTS_TO_IMPROVE_STARTED:
    case POST_CHECKLIST_STARTED:
    case PUT_NEW_PRODUCTS_STARTED:
    case POST_TAKE_ACTION_STARTED:
    case DELETE_PRODUCT_STARTED:
    case POST_REINDEX_STARTED:
    case GET_MAG_SUPP_RULES_STARTED: {
      const newStatus = {
        name: STARTED,
        isLoading: true,
      }
      return {
        ...state,
        status: newStatus
      };
    }

    // pobranie listy produktów generatora
    case GET_PRODUCTS_TO_IMPROVE_SUCCEED: {
      const newStatus = {
        name: SUCCEED,
        isLoading: false,
      };
      return {
        ...state,
        status: newStatus,
        data: {
          ...state.data,
          index: action.payload.data
        }
      };
    }

    // pobrano dane produktu w celu edycji
    case EDIT_PRODUCT_SUCCEED: {
      const newStatus = {
        name: SUCCEED,
        isLoading: false,
      };
      return {
        ...state,
        status: newStatus,
        data: {
          ...state.data,
          editProduct: action.data
        }
      };
    }

    // zapisano nowe dane produktu
    case SAVE_G_PRODUCT_SUCCEED: {
      const newStatus = {
        name: SUCCEED,
        isLoading: true,
      };
      setTimeout(function(){
        window.location.href = window.location.href;
      }, 500);
      return {
        ...state,
        status: newStatus
      };
    }

    // pobranie listy reguł, dostawców oraz kategorii magento
    case GET_MAG_SUPP_RULES_SUCCEED: {
      const newStatus = {
        name: SUCCEED,
        isLoading: false,
      };
      return {
        ...state,
        status: newStatus,
        data: {
          ...state.data,
          magSuppliers: action.payload.data.mag_suppliers,
          magRules: action.payload.data.mag_rules,
          magManufacturers: action.payload.data.mag_manufacturers,
          magCats: action.payload.data.mag_cat
        }
      };
    }

    // aktualizacja checklisty oraz statusu
    case POST_CHECKLIST_SUCCEED: {
      const newStatus = {
        name: SUCCEED,
        isLoading: false,
      };
      const index = findIndex(state.data.index, ["id", action.payload.id]);
      if(index!=-1) {
        if(action.payload.mode=='imported') {
          state.data.index[index].gallery_valid = action.payload.gallery;
          state.data.index[index].description_valid = action.payload.description;
          state.data.index[index].technical_data_valid = action.payload.technical_data;
          state.data.index[index].other_valid = action.payload.other;
          state.data.index[index].status = 'for_repair';
        } else if(action.payload.mode=='for_repair') {
          state.data.index[index].status = 'imported';
        }
      }
      return {
        ...state,
        status: newStatus
      };
    }

    // dodawanie nowych produktów do bazy
    case PUT_NEW_PRODUCTS_SUCCEED: {
      const newStatus = {
        name: SUCCEED,
        isLoading: true,
      };
      setTimeout(function(){
        window.location.href = '/narzedzia/generator-produktow';
      }, 500);
      return {
        ...state,
        status: newStatus
      };
    }

    // dodawanie nowych produktów do bazy
    case ADD_PRODUCTS_FROM_FILE_SUCCEED: {
      const newStatus = {
        name: SUCCEED,
        isLoading: true,
      };
      setTimeout(function(){
        window.location.href = '/narzedzia/generator-produktow';
      }, 500);
      return {
        ...state,
        status: newStatus
      };
    }
    
    // dodano akcję do kolejki
    case POST_TAKE_ACTION_SUCCEED: {
      const newStatus = {
        name: SUCCEED,
        isLoading: true,
      };
      setTimeout(function(){
        window.location.href = window.location.href;
      }, 500);
      return {
        ...state,
        status: newStatus
      };
    }
    
    // usuń dane oraz pliki danego produktu
    case DELETE_PRODUCT_SUCCEED: {
      const newStatus = {
        name: SUCCEED,
        isLoading: true,
      };
      setTimeout(function(){
        window.location.href = '/narzedzia/generator-produktow';
      }, 500);
      return {
        ...state,
        status: newStatus
      };
    }
    
    // przeindeksuj dane aby pojawiły się świeżo dodane produkty
    case POST_REINDEX_SUCCEED: {
      const newStatus = {
        name: SUCCEED,
        isLoading: false,
      };
      return {
        ...state,
        status: newStatus
      };
    }

    case ADD_PRODUCTS_FROM_FILE_FAILED:
    case SAVE_G_PRODUCT_FAILED:
    case EDIT_PRODUCT_FAILED:
    case GET_PRODUCTS_TO_IMPROVE_FAILED:
    case POST_CHECKLIST_FAILED:
    case PUT_NEW_PRODUCTS_FAILED:
    case POST_TAKE_ACTION_FAILED:
    case DELETE_PRODUCT_FAILED:
    case POST_REINDEX_FAILED:
    case GET_MAG_SUPP_RULES_FAILED: {
      const newStatus = {
        name: FAILED,
        isLoading: false,
      };
      return {
        ...state,
        status: newStatus,
        error: action.error,
      };
    }


    default:
      return state;
  }
};

export default ProductGenerator;
