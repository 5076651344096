export const GET_RULE_REQUESTED = 'GET_RULE_REQUESTED';
export const GET_RULE_STARTED = 'GET_RULE_STARTED';
export const GET_RULE_SUCCEED = 'GET_RULE_SUCCEED';
export const GET_RULE_FAILED = 'GET_RULE_FAILED';

export const POST_MODIFY_RULE_REQUESTED = 'POST_MODIFY_RULE_REQUESTED';
export const POST_MODIFY_RULE_STARTED = 'POST_MODIFY_RULE_STARTED';
export const POST_MODIFY_RULE_SUCCEED = 'POST_MODIFY_RULE_SUCCEED';
export const POST_MODIFY_RULE_FAILED = 'POST_MODIFY_RULE_FAILED';

export const POST_UPDATE_RULE_ALLEGRO_REQUESTED = 'POST_UPDATE_RULE_ALLEGRO_REQUESTED';
export const POST_UPDATE_RULE_ALLEGRO_STARTED = 'POST_UPDATE_RULE_ALLEGRO_STARTED';
export const POST_UPDATE_RULE_ALLEGRO_SUCCEED = 'POST_UPDATE_RULE_ALLEGRO_SUCCEED';
export const POST_UPDATE_RULE_ALLEGRO_FAILED = 'POST_UPDATE_RULE_ALLEGRO_FAILED';

export const POST_UPDATE_RULE_MATRIX_REQUESTED = 'POST_UPDATE_RULE_MATRIX_REQUESTED';
export const POST_UPDATE_RULE_MATRIX_STARTED = 'POST_UPDATE_RULE_MATRIX_STARTED';
export const POST_UPDATE_RULE_MATRIX_SUCCEED = 'POST_UPDATE_RULE_MATRIX_SUCCEED';
export const POST_UPDATE_RULE_MATRIX_FAILED = 'POST_UPDATE_RULE_MATRIX_FAILED';