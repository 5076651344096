import {
  GET_AB_PRODUCTS_REQUESTED,
  POST_IMPORT_AB_REQUESTED
} from './actionTypes';

export const getABProducts = (params) => ({
  type: GET_AB_PRODUCTS_REQUESTED,
  payload: params
});

export const importProductAB = (params) => ({
  type: POST_IMPORT_AB_REQUESTED,
  payload: params
});