import { all, takeLatest, put, call } from "redux-saga/effects";
import { post } from 'axios';
import { Cookies } from 'react-cookie';

import {
   SEND_SET_REQUESTED,
   SEND_SET_STARTED,
   SEND_SET_SUCCEED,
   SEND_SET_FAILED,
} from "./actionTypes";


function* sendRequest({payload}) {
  document.getElementById('js-SetGenerate').style.display = 'block';

  let formData = new FormData();
  let body = JSON.stringify(payload);
  formData.append('body', body);

  let cookies = new Cookies();
  let user = cookies.get('user');
  let auth = user.token;

  const options = {
    body: payload,
    method: 'POST',
    headers: {
      'Content-type': 'json',
      'Authorization': "Bearer " + auth
    }
  };
  try {
    yield put({ type: SEND_SET_STARTED });
    const data = yield call(post(process.env.REACT_APP_API_URL+'/api/tools/set-generator/send', formData, options)
    .then(function (response) {
      document.getElementById('js-SetGenerate').style.display = 'none';
      if(response.data.success && !response.data.error) {
        document.getElementById('success').style.display = 'block';
        setTimeout(function(){
          document.getElementById('success').style.display = 'none';
        }, 4000);
      } else if(response.data.success && response.data.error) {
        document.getElementById('half_success').style.display = 'block';
        setTimeout(function(){
          document.getElementById('half_success').style.display = 'none';
        }, 10000);
      } else if(!response.data.success && response.data.error) {
        document.getElementById('error').style.display = 'block';
        setTimeout(function(){
          document.getElementById('error').style.display = 'none';
        }, 10000);
      }
    })
    .catch(function (error) {
      document.getElementById('js-SetGenerate').style.display = 'none';
      document.getElementById('error').style.display = 'block';
      setTimeout(function(){
        document.getElementById('error').style.display = 'none';
      }, 10000);
    }));

    yield put({ type: SEND_SET_SUCCEED, payload: { data } });

  } catch (error) {
    yield put({ type: SEND_SET_FAILED, payload: { error } });
  }
}

export default function* usersSaga() {
  yield all([
    takeLatest(SEND_SET_REQUESTED, sendRequest),
  ]);
}
