import {
  INITIAL,
  STARTED,
  SUCCEED,
  FAILED,
} from '../../constants/statusTypes';

import {
  GET_AB_PRODUCTS_STARTED,
  GET_AB_PRODUCTS_SUCCEED,
  GET_AB_PRODUCTS_FAILED,
  POST_IMPORT_AB_STARTED,
  POST_IMPORT_AB_SUCCEED,
  POST_IMPORT_AB_FAILED,
} from './actionTypes';

import { findIndex } from 'lodash';

const INIT_STATE = {
  data: {
    index: []
  },
  error: '',
  status: {
    isLoading: false,
    name: INITIAL,
  },
  showRightSidebar: false,
};

const abProducts = (state = INIT_STATE, action) => {

  switch (action.type) {
    case GET_AB_PRODUCTS_STARTED: {
      const newStatus = {
        name: STARTED,
        isLoading: true,
      }

      return {
        ...state,
        status: newStatus
      };
    }

    case POST_IMPORT_AB_STARTED: {
      const newStatus = {
        name: STARTED,
        isLoading: true,
      }
      const index = findIndex(state.data.index, ["index_AB", action.payload.index_AB]);
      state.data.index[index].checkBoxRefresh = false;
      return {
        ...state,
        status: newStatus
      };
    }
    
    // pobranie produktów AB
    case GET_AB_PRODUCTS_SUCCEED: {
      const newStatus = {
        name: SUCCEED,
        isLoading: false,
      };
      return {
        ...state,
        status: newStatus,
        data: {
          ...state.data,
          index: action.payload.products
        }
      };
    }

    // import AB
    case POST_IMPORT_AB_SUCCEED: {
      const newStatus = {
        name: SUCCEED,
        isLoading: false,
      };
      const index = findIndex(state.data.index, ["index_AB", action.payload.index_AB]);
      if(action.payload.action=="import") state.data.index[index].import = action.payload.change;
      if(action.payload.action=="sync") state.data.index[index].sync = action.payload.change;
      if(action.payload.action=="oversized") state.data.index[index].oversized = action.payload.change;
      state.data.index[index].checkBoxRefresh = true;
      return {
        ...state,
        status: newStatus
      }
    }

    case GET_AB_PRODUCTS_FAILED:
    case POST_IMPORT_AB_FAILED: {
      const newStatus = {
        name: FAILED,
        isLoading: false,
      };
      return {
        ...state,
        status: newStatus
      };
    }


    default:
      return state;
  }
};

export default abProducts;
