import {
  INITIAL,
  STARTED,
  SUCCEED,
  FAILED,
} from '../../constants/statusTypes';

import {
  GET_RULE_STARTED,
  GET_RULE_SUCCEED,
  GET_RULE_FAILED,
  POST_MODIFY_RULE_STARTED,
  POST_MODIFY_RULE_SUCCEED,
  POST_MODIFY_RULE_FAILED,
  POST_UPDATE_RULE_ALLEGRO_STARTED,
  POST_UPDATE_RULE_ALLEGRO_SUCCEED,
  POST_UPDATE_RULE_ALLEGRO_FAILED,
  POST_UPDATE_RULE_MATRIX_STARTED,
  POST_UPDATE_RULE_MATRIX_SUCCEED,
  POST_UPDATE_RULE_MATRIX_FAILED,
} from './actionTypes';

import { findIndex } from 'lodash';

const INIT_STATE = {
  data: {
    index: [],
  },
  error: '',
  status: {
    isLoading: false,
    name: INITIAL,
  }
};

const Rule = (state = INIT_STATE, action) => {

  switch (action.type) {
    case GET_RULE_STARTED: 
    case POST_UPDATE_RULE_MATRIX_STARTED: 
    case POST_UPDATE_RULE_ALLEGRO_STARTED: 
    case POST_MODIFY_RULE_STARTED: {
      const newStatus = {
        name: STARTED,
        isLoading: true,
      }

      return {
        ...state,
        status: newStatus
      };
    }

    // pobrana lista regul
    case GET_RULE_SUCCEED: {
      const newStatus = {
        name: SUCCEED,
        isLoading: false,
      };
      //console.log(action.payload.data);
      return {
        ...state,
        status: newStatus,
        data: {
          ...state.data,
          index: action.payload.data
        }
      };
    }
    
    // modyfikcja / dodanie nowej reguly
    case POST_MODIFY_RULE_SUCCEED: {
      const newStatus = {
        name: SUCCEED,
        isLoading: false,
      };
      //console.log(action.payload.data);
      if(action.payload.data.status) {
        let actionData = action.payload.data.action;
        if (actionData=='add' || actionData=='remove') {
          window.location.reload();
        } else if (actionData=='edit') {
          const index = findIndex(state.data.index, ["id", action.payload.data.id]);
          //console.log(index);
          if(index!==-1) {
            if(action.payload.data.name!=null) state.data.index[index].name = action.payload.data.name;
            if(action.payload.data.type!=null) state.data.index[index].type = action.payload.data.type;
            if(action.payload.data.time_start!=null) state.data.index[index].time_start = action.payload.data.time_start;
            if(action.payload.data.time_stop!=null) state.data.index[index].time_stop = action.payload.data.time_stop;
          }
        } else if (actionData=='change_active') {
          const index = findIndex(state.data.index, ["id", action.payload.data.id]);
          //console.log(index);
          if(index!==-1) {
            if(action.payload.data.active!=null) state.data.index[index].active = action.payload.data.active;
          }
        } 
      }
      return {
        ...state,
        status: newStatus,
      };
    }
    
    // aktualizacja produktów allegro
    case POST_UPDATE_RULE_ALLEGRO_SUCCEED: {
      const newStatus = {
        name: SUCCEED,
        isLoading: false,
      };
      if(action.payload.data.status_add || action.payload.data.status_delete ) {
        setTimeout(function(){ window.location.reload(); }, 2000);
      }
      return {
        ...state,
        status: newStatus,
      };
    }

    // aktualizacja produktów magento
    case POST_UPDATE_RULE_MATRIX_SUCCEED: {
      const newStatus = {
        name: SUCCEED,
        isLoading: false,
      };
      if(action.payload.data.status_add || action.payload.data.status_delete ) {
        setTimeout(function(){ window.location.reload(); }, 2000);
      }
      return {
        ...state,
        status: newStatus,
      };
    }

    case GET_RULE_FAILED: 
    case POST_UPDATE_RULE_MATRIX_FAILED: 
    case POST_UPDATE_RULE_ALLEGRO_FAILED: 
    case POST_MODIFY_RULE_FAILED: {
      const newStatus = {
        name: FAILED,
        isLoading: false,
      };

      return {
        ...state,
        status: newStatus,
        error: action.error,
        data: {
          ...state.data,
          index: []
        }
      };
    }


    default:
      return state;
  }
};

export default Rule;
