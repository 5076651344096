import React from 'react';
import { Redirect } from 'react-router-dom';
import { Route } from 'react-router-dom';

import { isUserAuthenticated, getLoggedInUser } from '../helpers/authUtils';

// lazy load all the views

// auth
const Login = React.lazy(() => import('../pages/auth/Login'));
const Logout = React.lazy(() => import('../pages/auth/Logout'));
const Register = React.lazy(() => import('../pages/auth/Register'));
const ForgetPassword = React.lazy(() => import('../pages/auth/ForgetPassword'));
const Confirm = React.lazy(() => import('../pages/auth/Confirm'));
// dashboard
const EcommerceDashboard = React.lazy(() => import('../pages/dashboards/Ecommerce'));
const mainDashboard = React.lazy(() => import('../pages/dashboards/Main'));
const AnalyticsDashboard = React.lazy(() => import('../pages/dashboards/Analytics'));
const SettingsAllegro = React.lazy(() => import('../pages/apps/SettingsAllegro'));
const MessagesAllegro = React.lazy(() => import('../pages/apps/Allegro/allegroMessages'));
const BillingAllegro = React.lazy(() => import('../pages/apps/Allegro/allegroBilling'));
const SettingsCeneo = React.lazy(() => import('../pages/apps/SettingsCeneo'));
const ProjectDashboard = React.lazy(() => import('../pages/dashboards/Project'));
// apps
const CalendarApp = React.lazy(() => import('../pages/apps/Calendar'));
const Projects = React.lazy(() => import('../pages/apps/Projects/'));
const ProjectDetail = React.lazy(() => import('../pages/apps/Projects/Detail/'));
const ProjectGannt = React.lazy(() => import('../pages/apps/Projects/Gantt/'));
const ProjectForm = React.lazy(() => import('../pages/apps/Projects/ProjectForm'));
// - ecommece pages
const EcommerceProducts = React.lazy(() => import('../pages/apps/Ecommerce/Products'));
const EcommerceProductsMatrix = React.lazy(() => import('../pages/apps/Ceneo/ceneo.js'));
const Cart = React.lazy(() => import('../pages/apps/Ecommerce/Cart'));
const Checkout = React.lazy(() => import('../pages/apps/Ecommerce/Checkout/'));
const Sellers = React.lazy(() => import('../pages/apps/Ecommerce/Sellers'));
const ProductDetails = React.lazy(() => import('../pages/apps/Ecommerce/ProductDetails'));
const Orders = React.lazy(() => import('../pages/apps/Ecommerce/Orders'));
const OrderDetails = React.lazy(() => import('../pages/apps/Ecommerce/OrderDetails'));

// MatrixMedia
const deliveryDro = React.lazy(() => import('../pages/apps/Dro/dro.js'));
const deliveryDroCategories = React.lazy(() => import('../pages/apps/Dro/droCategories.js'));
const deliveryElko = React.lazy(() => import('../pages/apps/Elko/elko'));
const deliveryElkoCategories = React.lazy(() => import('../pages/apps/Elko/elkoCategories.js'));
const deliveryInnpro = React.lazy(() => import('../pages/apps/Innpro/innpro'));
const deliveryInnproCategories = React.lazy(() => import('../pages/apps/Innpro/innproCategories.js'));
const deliveryAB = React.lazy(() => import('../pages/apps/AB/ab.js'));
const priceLists = React.lazy(() => import('../pages/apps/Tools/PriceLists/price.js'));
const setGenerator = React.lazy(() => import('../pages/apps/Tools/SetGenerator'));
const htmlGenerator = React.lazy(() => import('../pages/apps/Tools/HtmlGenerator'));
const reportGenerator = React.lazy(() => import('../pages/apps/Tools/ReportGenerator/ReportList'));
const singleReport = React.lazy(() => import('../pages/apps/Tools/ReportGenerator/SingleReport'));
const testOrder = React.lazy(() => import('../pages/apps/Tools/Tester/Order'));
const productGenerator = React.lazy(() => import('../pages/apps/Tools/ProductGenerator'));
const addNewProducts = React.lazy(() => import('../pages/apps/Tools/ProductGenerator/addNewProducts'));
const editProductFromGenerator = React.lazy(() => import('../pages/apps/Tools/ProductGenerator/edit'));
const EcommerceProductsAllegro = React.lazy(() => import('../pages/apps/Allegro/allegro'));
const newAllegroProduct = React.lazy(() => import('../pages/apps/Allegro/addAllegroProduct'));
const editAllegroProduct = React.lazy(() => import('../pages/apps/Allegro/editAllegroProduct'));
const Customers = React.lazy(() => import('../pages/apps/Users/user'));
const logs = React.lazy(() => import('../pages/apps/Logs/log'));
const ruleList = React.lazy(() => import('../pages/apps/Rule/rule'));
const logsAllegro = React.lazy(() => import('../pages/apps/Logs_allegro/log'));
const logsPriceHistory = React.lazy(() => import('../pages/apps/Logs_price_history/log'));
const logsAB = React.lazy(() => import('../pages/apps/Logs_ab/log'));
const logsCeneo = React.lazy(() => import('../pages/apps/Logs_ceneo/log'));
const logsRule = React.lazy(() => import('../pages/apps/Logs_rule/log'));
const logsTools = React.lazy(() => import('../pages/apps/Logs_tools/log'));
const logsGenProduktow = React.lazy(() => import('../pages/apps/Logs_gen_produktow/log'));
const universalLogs = React.lazy(() => import('../pages/apps/UniversalLogs/log'));
const complaints = React.lazy(() => import('../pages/apps/Complaints/Listing'));
const complaintDetails = React.lazy(() => import('../pages/apps/Complaints/Details'));
const createComplaint = React.lazy(() => import('../pages/apps/Complaints/CreateNew'));
const complaintDhlTable = React.lazy(() => import('../pages/apps/Complaints/DhlTable'));

// history price
const productListWithLinks = React.lazy(() => import('../pages/apps/HistoryPrice/ProductListWithLinks.js'));
const donutStatsHigherLowerPrice = React.lazy(() => import('../pages/apps/HistoryPrice/DonutStatsHigherLowerPrice.js'));
const productList = React.lazy(() => import('../pages/apps/HistoryPrice/ProductList.js'));
const shopList = React.lazy(() => import('../pages/apps/HistoryPrice/ShopList.js'));
const stats = React.lazy(() => import('../pages/apps/HistoryPrice/Stats.js'));

// chat
const ChatApp = React.lazy(() => import('../pages/apps/Chat/'));
// social
const SocialFeed = React.lazy(() => import('../pages/apps/SocialFeed/'));
// tasks
const TaskList = React.lazy(() => import('../pages/apps/Tasks/List/'));
const Kanban = React.lazy(() => import('../pages/apps/Tasks/Board/'));

// - email
const Inbox = React.lazy(() => import('../pages/apps/Email/Inbox'));
const EmailDetail = React.lazy(() => import('../pages/apps/Email/Detail'));

// pages
const Starter = React.lazy(() => import('../pages/Starter'));
const Profile = React.lazy(() => import('../pages/profile'));
const ErrorPageNotFound = React.lazy(() => import('../pages/error/PageNotFound'));
const ServerError = React.lazy(() => import('../pages/error/ServerError'));

// - other
const Invoice = React.lazy(() => import('../pages/other/Invoice'));
const FAQ = React.lazy(() => import('../pages/other/FAQ'));
const Pricing = React.lazy(() => import('../pages/other/Pricing'));
const Timeline = React.lazy(() => import('../pages/other/Timeline'));

// uikit
const Accordions = React.lazy(() => import('../pages/uikit/Accordions'));
const Alerts = React.lazy(() => import('../pages/uikit/Alerts'));
const Badges = React.lazy(() => import('../pages/uikit/Badges'));
const Buttons = React.lazy(() => import('../pages/uikit/Buttons'));
const Cards = React.lazy(() => import('../pages/uikit/Cards'));
const Carousel = React.lazy(() => import('../pages/uikit/Carousel'));
const Dropdowns = React.lazy(() => import('../pages/uikit/Dropdowns'));
const ListGroups = React.lazy(() => import('../pages/uikit/ListGroups'));
const Modals = React.lazy(() => import('../pages/uikit/Modals'));
const Tabs = React.lazy(() => import('../pages/uikit/Tabs'));
const Toasts = React.lazy(() => import('../pages/uikit/Toasts'));
const Grid = React.lazy(() => import('../pages/uikit/Grid'));
const Popovers = React.lazy(() => import('../pages/uikit/Popovers'));
const Progress = React.lazy(() => import('../pages/uikit/Progress'));
const Ribbons = React.lazy(() => import('../pages/uikit/Ribbons'));
const Tooltips = React.lazy(() => import('../pages/uikit/Tooltips'));
const Typography = React.lazy(() => import('../pages/uikit/Typography'));
const Spinners = React.lazy(() => import('../pages/uikit/Spinners'));
const Widgets = React.lazy(() => import('../pages/uikit/Widgets'));
const DragDrop = React.lazy(() => import('../pages/uikit/DragDrop'));
const RangeSliders = React.lazy(() => import('../pages/uikit/RangeSliders'));
const Ratings = React.lazy(() => import('../pages/uikit/Ratings'));

const MDIIcons = React.lazy(() => import('../pages/uikit/MDIIcons'));
const Dripicons = React.lazy(() => import('../pages/uikit/Dripicons'));
const Unicons = React.lazy(() => import('../pages/uikit/Unicons'));
// forms
const BasicForms = React.lazy(() => import('../pages/forms/Basic'));
const FormValidation = React.lazy(() => import('../pages/forms/Validation'));
const FormAdvanced = React.lazy(() => import('../pages/forms/Advanced'));
const FormWizard = React.lazy(() => import('../pages/forms/Wizard'));
const FileUpload = React.lazy(() => import('../pages/forms/FileUpload'));
const Editors = React.lazy(() => import('../pages/forms/Editors'));
// charts
const ApexChart = React.lazy(() => import('../pages/charts/Apex'));
const BriteChart = React.lazy(() => import('../pages/charts/Brite'));
const ChartJs = React.lazy(() => import('../pages/charts/ChartJs'));
// tables
const BasicTables = React.lazy(() => import('../pages/tables/Basic'));
const AdvancedTables = React.lazy(() => import('../pages/tables/Advanced'));
// maps
const GoogleMaps = React.lazy(() => import('../pages/GoogleMaps'));

// handle auth and authorization

const PrivateRoute = ({ component: Component, permission, ...rest }) => (
  <Route
    {...rest}
    render={(props) => {
      if (!isUserAuthenticated()) {
        // not logged in so redirect to login page with the return url
        return <Redirect to={{ pathname: '/account/login', state: { from: props.location } }} />;
      }

      const loggedInUser = getLoggedInUser();
      // check if route is restricted by permission
      if (permission && loggedInUser.user.permissions && loggedInUser.user.permissions.indexOf(permission) === -1) {
        // permission not authorised so redirect to home page
        return <Redirect to={{ pathname: '/' }} />;
      }

      // authorised so return component
      return <Component {...props} />;
    }}
  />
);

// dashboards
const dashboardRoutes = {
  path: '/dashboard',
  name: 'Panel główny',
  icon: 'uil-home-alt',
  header: 'Navigation',
  permission: 'dashboard',
  children: [
    {
      path: '/dashboard/main',
      name: 'Panel',
      component: mainDashboard,
      route: PrivateRoute,
      permission: 'dashboard',
    },
    {
      path: '/dashboard/analytics',
      name: 'Analytics',
      component: AnalyticsDashboard,
      route: PrivateRoute,
      permission: 'dashboard',
    },
    // {
    // 	path: '/dashboard/ecommerce',
    // 	name: 'Ecommerce',
    // 	badge: {
    // 			variant: 'success',
    // 			text: '3',
    // 	},
    // 	component: EcommerceDashboard,
    // 	route: PrivateRoute,
    // },
    // {
    // 	path: '/dashboard/project',
    // 	name: 'Project',
    // 	component: ProjectDashboard,
    // 	route: PrivateRoute,
    // },
  ],
};

const comparison = {
  name: 'Integracje',
  icon: 'uil-clipboard-alt',
  permission: 'comparison',
  children: [
    {
      path: '/integracje/ceneo',
      name: 'Ceneo',
      component: EcommerceProductsMatrix,
      route: PrivateRoute,
      permission: 'comparison',
    },
    {
      path: '/integracje/allegro',
      name: 'Allegro',
      component: EcommerceProductsAllegro,
      route: PrivateRoute,
      permission: 'comparison',
    },
  ],
};

const delivery = {
  name: 'Dostawcy',
  icon: 'uil-briefcase',
  permission: 'delivery',
  children: [
    {
      path: '/dostawcy/dro',
      name: 'DRO',
      component: deliveryDro,
      route: PrivateRoute,
      permission: 'delivery',
    },
    {
      path: '/dostawcy/ab',
      name: 'AB',
      component: deliveryAB,
      route: PrivateRoute,
      permission: 'delivery',
    },
    {
      path: '/dostawcy/elko',
      name: 'Elko',
      component: deliveryElko,
      route: PrivateRoute,
      permission: 'delivery',
    },
    {
      path: '/dostawcy/innpro',
      name: 'Innpro',
      component: deliveryInnpro,
      route: PrivateRoute,
      permission: 'delivery',
    },
  ],
};

const droCategories = {
  name: 'DRO - Kategorie',
  path: '/dostawcy/dro-kategorie',
  component: deliveryDroCategories,
  route: PrivateRoute,
  permission: 'delivery',
};

const elkoCategories = {
  name: 'Elko - Kategorie',
  path: '/dostawcy/elko-kategorie',
  component: deliveryElkoCategories,
  route: PrivateRoute,
  permission: 'delivery',
};

const innproCategories = {
  name: 'Innpro - Kategorie',
  path: '/dostawcy/innpro-kategorie',
  component: deliveryInnproCategories,
  route: PrivateRoute,
  permission: 'delivery',
};

const priceHistory = {
  name: 'Historia cen',
  icon: 'uil-chart-line',
  permission: 'price-history',
  children: [
    {
      path: '/historia-cen/lista-produktow',
      name: 'Lista produktów',
      component: productList,
      route: PrivateRoute,
      permission: 'price-history',
    },
    {
      path: '/historia-cen/statystyki-produktow/',
      name: 'Lista produktów z linakmi',
      component: productListWithLinks,
      route: PrivateRoute,
      permission: 'price-history',
    },
    {
      path: '/historia-cen/lista-sklepow',
      name: 'Lista sklepów',
      component: shopList,
      route: PrivateRoute,
      permission: 'price-history',
    },
    {
      path: '/historia-cen/statystyki-ogolne-cen',
      name: 'Statystyki ogólne cen',
      component: donutStatsHigherLowerPrice,
      route: PrivateRoute,
      permission: 'price-history',
    },
  ],
};

const priceHistoryStats = {
  name: 'Statystyki produktu',
  path: '/historia-cen/statystyki-produktu/:id',
  component: stats,
  route: PrivateRoute,
  permission: 'price-history',
};

const tests = {
  name: 'Testy automatyczne',
  icon: 'mdi mdi-settings-transfer-outline',
  permission: 'tests',
  children: [
    {
      path: '/tester/zamowien',
      name: 'Zamówień',
      component: testOrder,
      route: PrivateRoute,
      permission: 'tests',
    }
  ]
};

const tools = {
  name: 'Narzędzia',
  icon: 'mdi mdi-tools',
  permission: 'tools',
  children: [
    {
      path: '/narzedzia/reguly',
      name: 'Reguły',
      route: PrivateRoute,
      component: ruleList,
      permission: 'tools',
    },
    {
      path: '/narzedzia/cenniki',
      name: 'Cenniki',
      component: priceLists,
      route: PrivateRoute,
      permission: 'tools',
    },
    {
      path: '/narzedzia/zestawy',
      name: 'Zestawy',
      component: setGenerator,
      route: PrivateRoute,
      permission: 'tools',
    },
    {
      path: '/narzedzia/generator-produktow',
      name: 'G. Produktów',
      component: productGenerator,
      route: PrivateRoute,
      permission: 'tools',
    },
    {
      path: '/narzedzia/generator-html',
      name: 'G. HTML',
      component: htmlGenerator,
      route: PrivateRoute,
      permission: 'tools',
    },
    {
      path: '/narzedzia/generator-raportow',
      name: 'G. Raportów',
      component: reportGenerator,
      route: PrivateRoute,
      permission: 'tools',
    }
  ],
};

const SingleReport = {
  path: '/generator-raportow/raport/:id',
  name: 'Raport',
  component: singleReport,
  route: PrivateRoute,
  permission: 'report-generator',
};

const addProductsNew = {
  path: '/narzedzia/generator-produktow-nowe',
  name: 'Generuj Nowe Produkty',
  route: PrivateRoute,
  component: addNewProducts,
  permission: 'generate-products',
};

const editGeneratorProduct = {
  path: '/narzedzia/generator-produktow-edycja/:id',
  name: 'Edycja Produktu',
  route: PrivateRoute,
  component: editProductFromGenerator,
  permission: 'generate-products',
};

const users = {
  path: '/apps/users',
  name: 'Pracownicy',
  route: PrivateRoute,
  icon: 'uil-users-alt',
  component: Customers,
  permission: 'users',
};

const allLogs = {
  path: '/apps/logs',
  name: 'Logi',
  route: PrivateRoute,
  icon: 'uil-clipboard-alt',
  component: logs,
  permission: 'logs',
};

const laravelHorizon = {
  path: process.env.REACT_APP_API_URL.replace('https://aplikacja.matrixmedia.pl/api/public', 'https://api.aplikacja.matrixmedia.pl') + '/horizon',
  name: 'Queue',
  route: PrivateRoute,
  icon: 'uil-play-circle',
  permission: 'queue',
};

const allegroLog = {
  path: '/apps/logsallegro',
  name: 'Logi Allegro',
  route: PrivateRoute,
  icon: 'uil-clipboard-alt',
  component: logsAllegro,
  permission: 'logs',
};

const priceHistoryLog = {
  path: '/apps/price-history-logs',
  name: 'Logi Historii Cen',
  route: PrivateRoute,
  icon: 'uil-clipboard-alt',
  component: logsPriceHistory,
  permission: 'logs',
};

const ABLog = {
  path: '/apps/logsAB',
  name: 'Logi AB',
  route: PrivateRoute,
  icon: 'uil-clipboard-alt',
  component: logsAB,
  permission: 'logs',
};

const newProductAllegro = {
  path: '/apps/newAllegroProduct',
  name: 'Nowy produkt Allegro',
  route: PrivateRoute,
  component: newAllegroProduct,
};

const editProductAllegro = {
  path: '/integracje/allegro-edit',
  name: 'Edycja produktu Allegro',
  route: PrivateRoute,
  component: editAllegroProduct,
};

const ceneoLog = {
  path: '/apps/logsceneo',
  name: 'Logi Ceneo',
  route: PrivateRoute,
  icon: 'uil-clipboard-alt',
  component: logsCeneo,
};

const ruleLog = {
  path: '/apps/logsrule',
  name: 'Logi Reguł',
  route: PrivateRoute,
  icon: 'uil-clipboard-alt',
  component: logsRule,
};

const toolsLog = {
  path: '/apps/logstools',
  name: 'Logi Generatora Zestawów',
  route: PrivateRoute,
  icon: 'uil-clipboard-alt',
  component: logsTools,
};

const genProduktowLog = {
  path: '/apps/logs-gen-produktow',
  name: 'Logi Generatora Produktów',
  route: PrivateRoute,
  icon: 'uil-clipboard-alt',
  component: logsGenProduktow,
};

const universalLog = {
  path: '/apps/uni-log/:type',
  name: 'Logi',
  route: PrivateRoute,
  icon: 'uil-clipboard-alt',
  component: universalLogs,
};

const complaintPage = {
  path: '/reklamacje',
  name: 'Reklamacje',
  route: PrivateRoute,
  icon: 'uil-ticket',
  component: complaints,
  permission: 'complaint/listing',
};

const complaintDetailsPage = {
  path: '/reklamacja/szczegoly/:id',
  name: 'Szczegóły reklamacji',
  route: PrivateRoute,
  component: complaintDetails,
  permission: 'complaint/single',
};

const createComplaintPage = {
  path: '/reklamacja/nowa',
  name: 'Nowa reklamacja',
  route: PrivateRoute,
  component: createComplaint,
  permission: 'complaint/new',
};

const complaintDhlTablePage = {
  path: '/reklamacja/lista-dhl',
  name: 'Lista paczek DHL',
  route: PrivateRoute,
  component: complaintDhlTable,
  permission: 'complaint/listing',
};

const allegroSettings = {
  path: '/integracje/allegro-ustawienia',
  name: 'Ustawienia Allegro',
  component: SettingsAllegro,
  route: PrivateRoute,
};

const allegroMessages = {
  path: '/integracje/allegro-komunikaty',
  name: 'Komunikaty z Allegro',
  component: MessagesAllegro,
  route: PrivateRoute,
};

const allegroBilling = {
  path: '/integracje/allegro-rozliczenia',
  name: 'Rozliczenia z Allegro',
  component: BillingAllegro,
  route: PrivateRoute,
};

const ceneoSettings = {
  path: '/integracje/ceneo-ustawienia',
  name: 'Ustawienia Ceneo',
  component: SettingsCeneo,
  route: PrivateRoute,
};

const ecommerceAppRoutes = {
  path: '/apps/ecommerce',
  name: 'eCommerce',
  route: PrivateRoute,
  roles: ['Admin'],
  icon: 'uil-store',
  children: [
    {
      path: '/apps/ecommerce/products',
      name: 'Products',
      component: EcommerceProducts,
      route: PrivateRoute,
    },
    {
      path: '/apps/ecommerce/details',
      name: 'Product Details',
      component: ProductDetails,
      route: PrivateRoute,
    },
    {
      path: '/apps/ecommerce/orders',
      name: 'Orders',
      component: Orders,
      route: PrivateRoute,
    },
    {
      path: '/apps/ecommerce/order/details',
      name: 'Order Details',
      component: OrderDetails,
      route: PrivateRoute,
    },
    {
      path: '/apps/ecommerce/customers',
      name: 'Customers',
      component: Customers,
      route: PrivateRoute,
    },
    {
      path: '/apps/ecommerce/shopping-cart',
      name: 'Shopping Cart',
      component: Cart,
      route: PrivateRoute,
    },
    {
      path: '/apps/ecommerce/checkout',
      name: 'Checkout',
      component: Checkout,
      route: PrivateRoute,
    },
    {
      path: '/apps/ecommerce/sellers',
      name: 'Sellers',
      component: Sellers,
      route: PrivateRoute,
    },
  ],
};

const emailAppRoutes = {
  path: '/apps/email',
  name: 'Email',
  route: PrivateRoute,
  roles: ['Admin'],
  icon: 'uil-envelope',
  children: [
    {
      path: '/apps/email/inbox',
      name: 'Inbox',
      component: Inbox,
      route: PrivateRoute,
    },
    {
      path: '/apps/email/details',
      name: 'Email Details',
      component: EmailDetail,
      route: PrivateRoute,
    },
  ],
};

const projectAppRoutes = {
  path: '/apps/projects',
  name: 'Projects',
  route: PrivateRoute,
  roles: ['Admin'],
  icon: 'uil-briefcase',

  children: [
    {
      path: '/apps/projects/list',
      name: 'List',
      component: Projects,
      route: PrivateRoute,
    },
    {
      path: '/apps/projects/:id/details',
      name: 'Detail',
      component: ProjectDetail,
      route: PrivateRoute,
    },
    {
      path: '/apps/projects/gantt',
      name: 'Gantt',
      component: ProjectGannt,
      route: PrivateRoute,
    },
    {
      path: '/apps/projects/new',
      name: 'Create Project',
      component: ProjectForm,
      route: PrivateRoute,
    },
  ],
};

// pages
const pageRoutes = {
  path: '/pages',
  name: 'Pages',
  icon: 'uil-copy-alt',
  header: 'Custom',
  children: [
    {
      path: '/pages/starter',
      name: 'Starter',
      component: Starter,
      route: PrivateRoute,
    },
    {
      path: '/pages/profile',
      name: 'Profile',
      component: Profile,
      route: PrivateRoute,
    },
    {
      path: '/pages/invoice',
      name: 'Invoice',
      component: Invoice,
      route: PrivateRoute,
    },
    {
      path: '/pages/faq',
      name: 'FAQ',
      component: FAQ,
      route: PrivateRoute,
    },
    {
      path: '/pages/pricing',
      name: 'Pricing',
      component: Pricing,
      route: PrivateRoute,
    },
    {
      path: '/pages/error-404',
      name: 'Error - 404',
      component: ErrorPageNotFound,
      route: PrivateRoute,
    },
    {
      path: '/pages/error-500',
      name: 'Error - 500',
      component: ServerError,
      route: PrivateRoute,
    },
    {
      path: '/pages/timeline',
      name: 'Timeline',
      component: Timeline,
      route: PrivateRoute,
    },
  ],
};

// auth
const authRoutes = {
  path: '/account',
  name: 'Auth',
  children: [
    {
      path: '/account/login',
      name: 'Login',
      component: Login,
      route: Route,
    },
    {
      path: '/account/logout',
      name: 'Logout',
      component: Logout,
      route: Route,
    },
    {
      path: '/account/register',
      name: 'Register',
      component: Register,
      route: Route,
    },
    {
      path: '/account/confirm',
      name: 'Confirm',
      component: Confirm,
      route: Route,
    },
    {
      path: '/account/forget-password',
      name: 'Forget Password',
      component: ForgetPassword,
      route: Route,
    },
  ],
};

// ui
const uiRoutes = {
  path: '/ui',
  name: 'Components',
  icon: 'uil-package',
  header: 'UI Elements',
  children: [
    {
      path: '/ui/base',
      name: 'Base UI',
      children: [
        {
          path: '/ui/accordions',
          name: 'Accordions',
          component: Accordions,
          route: PrivateRoute,
        },
        {
          path: '/ui/alerts',
          name: 'Alerts',
          component: Alerts,
          route: PrivateRoute,
        },
        {
          path: '/ui/badges',
          name: 'Badges',
          component: Badges,
          route: PrivateRoute,
        },
        {
          path: '/ui/buttons',
          name: 'Buttons',
          component: Buttons,
          route: PrivateRoute,
        },
        {
          path: '/ui/cards',
          name: 'Cards',
          component: Cards,
          route: PrivateRoute,
        },
        {
          path: '/ui/carousel',
          name: 'Carousel',
          component: Carousel,
          route: PrivateRoute,
        },
        {
          path: '/ui/dropdowns',
          name: 'Dropdowns',
          component: Dropdowns,
          route: PrivateRoute,
        },
        {
          path: '/ui/grid',
          name: 'Grid',
          component: Grid,
          route: PrivateRoute,
        },
        {
          path: '/ui/listgroups',
          name: 'List Groups',
          component: ListGroups,
          route: PrivateRoute,
        },
        {
          path: '/ui/modals',
          name: 'Modals',
          component: Modals,
          route: PrivateRoute,
        },
        {
          path: '/ui/popovers',
          name: 'Popovers',
          component: Popovers,
          route: PrivateRoute,
        },
        {
          path: '/ui/progress',
          name: 'Progress',
          component: Progress,
          route: PrivateRoute,
        },
        {
          path: '/ui/ribbons',
          name: 'Ribbons',
          component: Ribbons,
          route: PrivateRoute,
        },
        {
          path: '/ui/spinners',
          name: 'Spinners',
          component: Spinners,
          route: PrivateRoute,
        },
        {
          path: '/ui/tabs',
          name: 'Tabs',
          component: Tabs,
          route: PrivateRoute,
        },
        {
          path: '/ui/toasts',
          name: 'Toasts',
          component: Toasts,
          route: PrivateRoute,
        },
        {
          path: '/ui/tooltips',
          name: 'Tooltips',
          component: Tooltips,
          route: PrivateRoute,
        },
        {
          path: '/ui/typography',
          name: 'Typography',
          component: Typography,
          route: PrivateRoute,
        },
        {
          path: '/ui/widgets',
          name: 'Widgets',
          component: Widgets,
          route: PrivateRoute,
        },
      ],
    },
    {
      path: '/ui/extended',
      name: 'Extended UI',
      children: [
        {
          path: '/ui/dragdrop',
          name: 'Drag and Drop',
          component: DragDrop,
          route: PrivateRoute,
        },
        {
          path: '/ui/rangesliders',
          name: 'Range Sliders',
          component: RangeSliders,
          route: PrivateRoute,
        },
        {
          path: '/ui/ratings',
          name: 'Ratings',
          component: Ratings,
          route: PrivateRoute,
        },
      ],
    },
    {
      path: '/ui/icons',
      name: 'Icons',
      children: [
        {
          path: '/ui/icons/dripicons',
          name: 'Dripicons',
          component: Dripicons,
          route: PrivateRoute,
        },
        {
          path: '/ui/icons/mdi',
          name: 'Material Design',
          component: MDIIcons,
          route: PrivateRoute,
        },
        {
          path: '/ui/icons/unicons',
          name: 'Unicons',
          component: Unicons,
          route: Unicons,
        },
      ],
    },
    {
      path: '/ui/forms',
      name: 'Forms',
      children: [
        {
          path: '/ui/forms/basic',
          name: 'Basic Elements',
          component: BasicForms,
          route: PrivateRoute,
        },
        {
          path: '/ui/forms/advanced',
          name: 'Form Advanced',
          component: FormAdvanced,
          route: PrivateRoute,
        },
        {
          path: '/ui/forms/validation',
          name: 'Form Validation',
          component: FormValidation,
          route: PrivateRoute,
        },
        {
          path: '/ui/forms/wizard',
          name: 'Form Wizard',
          component: FormWizard,
          route: PrivateRoute,
        },
        {
          path: '/ui/forms/upload',
          name: 'File Upload',
          component: FileUpload,
          route: PrivateRoute,
        },
        {
          path: '/ui/forms/editors',
          name: 'Editors',
          component: Editors,
          route: PrivateRoute,
        },
      ],
    },
    {
      path: '/ui/charts',
      name: 'Charts',
      children: [
        {
          path: '/ui/charts/apex',
          name: 'Apex',
          component: ApexChart,
          route: PrivateRoute,
        },
        {
          path: '/ui/charts/brite',
          name: 'Brite',
          component: BriteChart,
          route: PrivateRoute,
        },
        {
          path: '/ui/charts/chartjs',
          name: 'Chartjs',
          component: ChartJs,
          route: PrivateRoute,
        },
      ],
    },
    {
      path: '/ui/tables',
      name: 'Tables',
      children: [
        {
          path: '/ui/tables/basic',
          name: 'Basic',
          component: BasicTables,
          route: PrivateRoute,
        },
        {
          path: '/ui/tables/advanced',
          name: 'Advanced',
          component: AdvancedTables,
          route: PrivateRoute,
        },
      ],
    },
    {
      path: '/ui/googlemaps',
      name: 'Google Maps',
      component: GoogleMaps,
      route: PrivateRoute,
    },
  ],
};

// flatten the list of all nested routes
const flattenRoutes = (routes) => {
  let flatRoutes = [];

  routes = routes || [];
  routes.forEach((item) => {
    flatRoutes.push(item);

    if (typeof item.children !== 'undefined') {
      flatRoutes = [...flatRoutes, ...flattenRoutes(item.children)];
    }
  });
  return flatRoutes;
};

const appRoutes = () => {
  const loginRoute = {
    path: '/',
    exact: true,
    component: () => <Redirect to="/account/login" />,
    route: PrivateRoute,
  };

  if (!isUserAuthenticated()) return [loginRoute];
  const loggedInUser = getLoggedInUser();

  let availableRoutes = [
    dashboardRoutes,
    comparison,
    complaintPage,
    delivery,
    priceHistory,
    tests,
    tools,
    users,
    allLogs,
    laravelHorizon
  ];

  if(loggedInUser.user.permissions == undefined || !Array.isArray(loggedInUser.user.permissions)) return [loginRoute];

  availableRoutes = availableRoutes.filter(({ permission }) => !permission || loggedInUser.user.permissions.indexOf(permission) !== -1);

  if(availableRoutes.length > 0) {
    const rootUrl = availableRoutes[0].children ? availableRoutes[0].children[0].path : availableRoutes[0].path;
    availableRoutes.push({
      path: '/',
      exact: true,
      component: () => <Redirect to={rootUrl} />,
      route: PrivateRoute,
    });
  }

  return availableRoutes;
}

// All routes
const allRoutes = [
  priceHistoryStats,
  ...appRoutes(),
  authRoutes,
  uiRoutes,
  allegroLog,
  droCategories,
  elkoCategories,
  innproCategories,
  priceHistoryLog,
  ABLog,
  newProductAllegro,
  editProductAllegro,
  SingleReport,
  addProductsNew,
  editGeneratorProduct,
  ceneoLog,
  ruleLog,
  toolsLog,
  genProduktowLog,
  universalLog,
  allegroSettings,
  allegroMessages,
  allegroBilling,
  ceneoSettings,
  ecommerceAppRoutes,
  complaintDetailsPage,
  createComplaintPage,
  complaintDhlTablePage
];

const authProtectedRoutes = [...appRoutes()];
const allFlattenRoutes = flattenRoutes(allRoutes);

export { allRoutes, authProtectedRoutes, allFlattenRoutes };
