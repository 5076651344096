import { all, takeLatest, put, call } from 'redux-saga/effects';
import { fetchJSON } from '../../helpers/api';
import { Cookies } from 'react-cookie';

import {
  GET_MAGENTO_PRODUCTS_REQUESTED,
  GET_MAGENTO_PRODUCTS_STARTED,
  GET_MAGENTO_PRODUCTS_SECCESS,
  GET_MAGENTO_PRODUCTS_FAILED,
  GET_LOWER_HIGHER_REQUESTED,
  GET_LOWER_HIGHER_STARTED,
  GET_LOWER_HIGHER_SECCESS,
  GET_LOWER_HIGHER_FAILED,
  GET_COMPLETE_PRODUCTS_REQUESTED,
  GET_COMPLETE_PRODUCTS_STARTED,
  GET_COMPLETE_PRODUCTS_SECCESS,
  GET_COMPLETE_PRODUCTS_FAILED,
  GET_SHOPS_LIST_REQUESTED,
  GET_SHOPS_LIST_STARTED,
  GET_SHOPS_LIST_SECCESS,
  GET_SHOPS_LIST_FAILED,
  POST_SHOPS_LIST_REQUESTED,
  POST_SHOPS_LIST_STARTED,
  POST_SHOPS_LIST_SECCESS,
  POST_SHOPS_LIST_FAILED,
  DELETE_SHOP_REQUESTED,
  DELETE_SHOP_STARTED,
  DELETE_SHOP_SECCESS,
  DELETE_SHOP_FAILED,
  GET_PRODUCT_SHOP_LIST_REQUESTED,
  GET_PRODUCT_SHOP_LIST_STARTED,
  GET_PRODUCT_SHOP_LIST_SECCESS,
  GET_PRODUCT_SHOP_LIST_FAILED,
  POST_PRODUCT_URL_REQUESTED,
  POST_PRODUCT_URL_STARTED,
  POST_PRODUCT_URL_SECCESS,
  POST_PRODUCT_URL_FAILED,
  GET_PRODUCT_STATS_REQUESTED,
  GET_PRODUCT_STATS_STARTED,
  GET_PRODUCT_STATS_SECCESS,
  GET_PRODUCT_STATS_FAILED,
} from './actionTypes';

import { SUCCESS, ERROR } from '../../constants/toastTypes';
import { GET_PRODUCTS_ALLEGRO_ERROR } from '../../constants/notifications';
import { addToast } from '../toast/actions';
let cookies = new Cookies();
let user = cookies.get('user');
let auth = user ? user.token : null;

function* getMagentoProducts() {
  try {
    yield put({ type: GET_MAGENTO_PRODUCTS_STARTED });
    const payload = yield call(fetchJSON, process.env.REACT_APP_API_URL + '/api/price-history/get-all-products', {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + auth,
      },
    });
    yield put({ type: GET_MAGENTO_PRODUCTS_SECCESS, payload: payload });
  } catch (error) {
    yield put(
      addToast({
        type: ERROR,
        message: GET_PRODUCTS_ALLEGRO_ERROR,
      })
    );
    yield put({ type: GET_MAGENTO_PRODUCTS_FAILED, payload: { error } });
  }
}

function* getCompleteProducts() {
  try {
    yield put({ type: GET_COMPLETE_PRODUCTS_STARTED });
    const payload = yield call(fetchJSON, process.env.REACT_APP_API_URL + '/api/price-history/get-complete-products', {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + auth,
      },
    });
    yield put({ type: GET_COMPLETE_PRODUCTS_SECCESS, payload: payload });
  } catch (error) {
    yield put(
      addToast({
        type: ERROR,
        message: GET_PRODUCTS_ALLEGRO_ERROR,
      })
    );
    yield put({ type: GET_COMPLETE_PRODUCTS_FAILED, payload: { error } });
  }
}

function* getShopsList() {
  try {
    yield put({ type: GET_SHOPS_LIST_STARTED });
    const payload = yield call(fetchJSON, process.env.REACT_APP_API_URL + '/api/price-history/shop-list', {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + auth,
      },
    });
    yield put({ type: GET_SHOPS_LIST_SECCESS, payload: payload });
    yield put(
      addToast({
        type: SUCCESS,
        message: 'Poprawnie pobrano listę sklepów.',
      })
    );
  } catch (error) {
    yield put(
      addToast({
        type: ERROR,
        message: 'Nie udało się pobrać listy sklepów.',
      })
    );
    yield put({ type: GET_SHOPS_LIST_FAILED, payload: { error } });
  }
}

function* postShopsList({ payload }) {
  const options = {
    body: payload,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
  };
  try {
    yield put({ type: POST_SHOPS_LIST_STARTED });
    const payload = yield call(fetchJSON, process.env.REACT_APP_API_URL + '/api/price-history/save-shops', options);
    yield put({ type: POST_SHOPS_LIST_SECCESS, payload: payload });
    yield put(
      addToast({
        type: SUCCESS,
        message: 'Lista sklepów została poprawnie zapisana.',
      })
    );
  } catch (error) {
    yield put(
      addToast({
        type: ERROR,
        message: 'Nie udało się zapisać listy sklepów',
      })
    );
    yield put({ type: POST_SHOPS_LIST_FAILED, payload: { error } });
  }
}

function* deleteShop({ payload }) {
  try {
    yield put({ type: DELETE_SHOP_STARTED });
    const payloadDelete = yield call(
      fetchJSON,
      process.env.REACT_APP_API_URL + `/api/price-history/delete-shop/` + payload,
      {
        method: 'DELETE',
        headers: {
          Authorization: 'Bearer ' + auth,
        },
      }
    );
    yield put({ type: DELETE_SHOP_SECCESS, payload: payloadDelete });
    yield put(
      addToast({
        type: SUCCESS,
        message: 'Sklep został poprawnie usunięty',
      })
    );
  } catch (error) {
    yield put(
      addToast({
        type: ERROR,
        message: 'Nie udało się usunąć sklepu',
      })
    );
    yield put({ type: DELETE_SHOP_FAILED, payload: { error } });
  }
}

function* getProductShopList({ payload }) {
  try {
    yield put({ type: GET_PRODUCT_SHOP_LIST_STARTED });
    const payloadProductShops = yield call(
      fetchJSON,
      process.env.REACT_APP_API_URL + '/api/price-history/product-urls/' + payload,
      {
        method: 'GET',
        headers: {
          Authorization: 'Bearer ' + auth,
        },
      }
    );
    yield put({ type: GET_PRODUCT_SHOP_LIST_SECCESS, payload: payloadProductShops });
  } catch (error) {
    yield put(
      addToast({
        type: ERROR,
        message: GET_PRODUCTS_ALLEGRO_ERROR,
      })
    );
    yield put({ type: GET_PRODUCT_SHOP_LIST_FAILED, payload: { error } });
  }
}

function* getStatsPricesLowerHigher({ payload }) {
  try {
    yield put({ type: GET_LOWER_HIGHER_STARTED });
    const payloadProductShops = yield call(
      fetchJSON,
      process.env.REACT_APP_API_URL + '/api/price-history/get-higher-lower-stats' + payload,
      {
        method: 'GET',
        headers: {
          Authorization: 'Bearer ' + auth,
        },
      }
    );
    yield put({ type: GET_LOWER_HIGHER_SECCESS, payload: payloadProductShops });
  } catch (error) {
    yield put(
      addToast({
        type: ERROR,
        message: GET_PRODUCTS_ALLEGRO_ERROR,
      })
    );
    yield put({ type: GET_LOWER_HIGHER_FAILED, payload: { error } });
  }
}

function* postProductUrl({ payload }) {
  const options = {
    body: payload,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
  };
  try {
    yield put({ type: POST_PRODUCT_URL_STARTED });
    const payload = yield call(
      fetchJSON,
      process.env.REACT_APP_API_URL + '/api/price-history/update-product-urls',
      options
    );
    yield put({ type: POST_PRODUCT_URL_SECCESS, payload: payload });
    yield put(
      addToast({
        type: SUCCESS,
        message: 'Poprawnie zaaktualizowano linki do sklepów.',
      })
    );
  } catch (error) {
    yield put(
      addToast({
        type: ERROR,
        message: 'Nie udało się zaaktualizować linków.',
      })
    );
    yield put({ type: POST_PRODUCT_URL_FAILED, payload: { error } });
  }
}

function* getProductStats({ payload }) {
  const options = {
    body: payload,
    method: 'POST',
    headers: {
      Authorization: 'Bearer ' + auth,
      'Content-Type': 'application/json',
    },
  };
  try {
    yield put({ type: GET_PRODUCT_STATS_STARTED });
    const dataFromAPI = yield call(
      fetchJSON,
      process.env.REACT_APP_API_URL + '/api/price-history/get-product-stats',
      options
    );
    yield put({ type: GET_PRODUCT_STATS_SECCESS, payload: dataFromAPI });
  } catch (error) {
    yield put(
      addToast({
        type: ERROR,
        message: GET_PRODUCTS_ALLEGRO_ERROR,
      })
    );
    yield put({ type: GET_PRODUCT_STATS_FAILED, payload: { error } });
  }
}

export default function* usersSaga() {
  yield all([
    takeLatest(GET_MAGENTO_PRODUCTS_REQUESTED, getMagentoProducts),
    takeLatest(GET_COMPLETE_PRODUCTS_REQUESTED, getCompleteProducts),
    takeLatest(GET_SHOPS_LIST_REQUESTED, getShopsList),
    takeLatest(POST_SHOPS_LIST_REQUESTED, postShopsList),
    takeLatest(DELETE_SHOP_REQUESTED, deleteShop),
    takeLatest(GET_PRODUCT_SHOP_LIST_REQUESTED, getProductShopList),
    takeLatest(POST_PRODUCT_URL_REQUESTED, postProductUrl),
    takeLatest(GET_PRODUCT_STATS_REQUESTED, getProductStats),
    takeLatest(GET_LOWER_HIGHER_REQUESTED, getStatsPricesLowerHigher),
  ]);
}
