import {
  GET_RULE_REQUESTED,
  POST_MODIFY_RULE_REQUESTED,
  POST_UPDATE_RULE_ALLEGRO_REQUESTED,
  POST_UPDATE_RULE_MATRIX_REQUESTED,
} from './actionTypes';

export const getRuleList = (params) => ({
  type: GET_RULE_REQUESTED,
  payload: params
});

export const modifyRule = (params) => ({
  type: POST_MODIFY_RULE_REQUESTED,
  payload: params
});

export const updateRuleAllegro = (params) => ({
  type: POST_UPDATE_RULE_ALLEGRO_REQUESTED,
  payload: params
});

export const updateRuleMatrix = (params) => ({
  type: POST_UPDATE_RULE_MATRIX_REQUESTED,
  payload: params
});
