import {
  GET_PRODUCTS_TO_IMPROVE_REQUESTED,
  GET_MAG_SUPP_RULES_REQUESTED,
  POST_CHECKLIST_REQUESTED,
  PUT_NEW_PRODUCTS_REQUESTED,
  POST_REINDEX_REQUESTED,
  DELETE_PRODUCT_REQUESTED,
  EDIT_PRODUCT_REQUESTED,
  SAVE_G_PRODUCT_REQUESTED,
  POST_TAKE_ACTION_REQUESTED,
  ADD_PRODUCTS_FROM_FILE_REQUESTED,
} from './actionTypes';

export const getProductsToImprove = (params) => ({
  type: GET_PRODUCTS_TO_IMPROVE_REQUESTED,
  payload: params
});

export const addNewProducts = (params) => ({
  type: PUT_NEW_PRODUCTS_REQUESTED,
  payload: params
});

export const addProductsFromFile = (params) => ({
  type: ADD_PRODUCTS_FROM_FILE_REQUESTED,
  params: params
});

export const editProduct = (productId) => ({
  type: EDIT_PRODUCT_REQUESTED,
  productId: productId
});

export const deleteProduct = (params) => ({
  type: DELETE_PRODUCT_REQUESTED,
  payload: params
});

export const saveProduct = (params) => ({
  type: SAVE_G_PRODUCT_REQUESTED,
  params: params
});

export const getMagSuppRules = (params) => ({
  type: GET_MAG_SUPP_RULES_REQUESTED,
  payload: params
});

export const updateCheckList = (params) => ({
  type: POST_CHECKLIST_REQUESTED,
  payload: params
});

export const takeAction = (params) => ({
  type: POST_TAKE_ACTION_REQUESTED,
  params: params
});

export const reindex = (params) => ({
  type: POST_REINDEX_REQUESTED,
  payload: params
});