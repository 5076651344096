import {
  GET_ALLEGRO_PRODUCTS_REQUESTED,
  GET_ALLEGRO_MESSAGES_REQUESTED,
  GET_ALLEGRO_BILLING_REQUESTED,
  GET_ALLEGRO_MESSAGES_CENTER_REQUESTED,
  POST_ALLEGRO_READ_MESSAGES_REQUESTED,
  POST_ALLEGRO_READ_MESSAGES_CENTER_REQUESTED,
  POST_ALLEGRO_PRODUCTS_REQUESTED,
  MODIFY_ALLEGRO_PRODUCTS_REQUESTED,
  POST_CALC_COST_REQUESTED,
  POST_CHECKBOX_UPDATE_REQUESTED,
  POST_EDIT_PRODUCT_REQUESTED,
  POST_REPORT_BADGE_REQUESTED,
  GET_SEARCH_AUCTION_REQUESTED,
  GET_SEARCH_ITEM_REQUESTED,
  SAVE_PRODUCT_REQUESTED,
  FILTER_PRODUCT_REQUESTED,
  CLEAR_PRODUCT_REQUESTED,
  GET_CATEGORY_PRODUCT_REQUESTED,
  GET_ALL_CATEGORY_REQUESTED,
  GET_CATEGORY_FIELD_REQUESTED
} from './actionTypes';

export const getAllegroProducts = (params) => ({
  type: GET_ALLEGRO_PRODUCTS_REQUESTED,
  payload: params
});

export const getMessages = (params) => ({
  type: GET_ALLEGRO_MESSAGES_REQUESTED,
  payload: params
});

export const getBilling = (params) => ({
  type: GET_ALLEGRO_BILLING_REQUESTED,
  payload: params
});

export const changeReadMessage = (params) => ({
  type: POST_ALLEGRO_READ_MESSAGES_REQUESTED,
  payload: params
});

export const getMessagesCenter = (params) => ({
  type: GET_ALLEGRO_MESSAGES_CENTER_REQUESTED,
  payload: params
});

export const changeReadMessageCenter = (params) => ({
  type: POST_ALLEGRO_READ_MESSAGES_CENTER_REQUESTED,
  payload: params
});

export const checkboxUpdate = (params) => ({
  type: POST_CHECKBOX_UPDATE_REQUESTED,
  payload: params
});

export const editProduct = (params) => ({
  type: POST_EDIT_PRODUCT_REQUESTED,
  payload: params
});

export const reportBadge = (params) => ({
  type: POST_REPORT_BADGE_REQUESTED,
  payload: params
});

export const searchItem = (params) => ({
  type: GET_SEARCH_ITEM_REQUESTED,
  payload: params
});

export const searchAuction = (params) => ({
  type: GET_SEARCH_AUCTION_REQUESTED,
  payload: params
});

export const postProductAllegro = (params) => ({
  type: POST_ALLEGRO_PRODUCTS_REQUESTED,
  payload: params
});

export const modifyProductAllegro = (params) => ({
  type: MODIFY_ALLEGRO_PRODUCTS_REQUESTED,
  payload: params
});

export const calcOffertCost = (params) => ({
  type: POST_CALC_COST_REQUESTED,
  payload: params
});

export const selectProduct = (params) => ({
  type: SAVE_PRODUCT_REQUESTED,
  payload: params
});

export const getProductCategory = (params) => ({
  type: GET_CATEGORY_PRODUCT_REQUESTED,
  payload: params
});

export const getAllCategory = (params) => ({
  type: GET_ALL_CATEGORY_REQUESTED,
  payload: params
});

export const getCategoryField = (params) => ({
  type: GET_CATEGORY_FIELD_REQUESTED,
  payload: params
});

export const filteredProduct = (params) => ({
  type: FILTER_PRODUCT_REQUESTED,
  payload: params
});

export const clearProducts = (params) => ({
  type: CLEAR_PRODUCT_REQUESTED,
  payload: params
});
