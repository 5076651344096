import {
  GET_NOTIFICATION_REQUESTED,
  POST_NOTIFICATION_REQUESTED,
} from './actionTypes';

export const getNotification = (params) => ({
  type: GET_NOTIFICATION_REQUESTED,
  payload: params
});

export const readNotification = (params) => ({
  type: POST_NOTIFICATION_REQUESTED,
  payload: params
});
