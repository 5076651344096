import { all, takeLatest, put, call } from "redux-saga/effects";
import { fetchJSON } from '../../helpers/api';
import { post } from 'axios';
import { Cookies } from 'react-cookie';

import {
  SEND_REQUEST_REQUESTED,
  SEND_REQUEST_STARTED,
  SEND_REQUEST_SUCCEED,
  SEND_REQUEST_FAILED,
} from "./actionTypes";


function* sendRequest({payload}) {
  document.getElementById('js-generatePriceList').style.display = 'block';
  let formData = new FormData();
  let configData = JSON.stringify(payload.configData);
  formData.append('configData', configData);
  if(payload.xlsxFile) {
    formData.append('file', payload.xlsxFile);
  }
  let cookies = new Cookies();
  let user = cookies.get('user');
  let auth = user.token;

  const options = {
    body: payload,
    method: 'POST',
    headers: {
      'Content-type': 'multipart/form-data',
      'Authorization': "Bearer " + auth
    }
  };
  try {
    yield put({ type: SEND_REQUEST_STARTED });
    const data = yield call(post(process.env.REACT_APP_API_URL+'/api/tools/price-lists/send', formData, options)
    .then(function (response) {
      //console.log(response.data);
      document.getElementById('js-generatePriceList').style.display = 'none';
      if(response.data == 'no_file_send') {
        document.getElementById('no_file_send').style.display = 'block';
        setTimeout(function(){
          document.getElementById('no_file_send').style.display = 'none';
        }, 2000);
      } else if(response.data == 'error_connect_magento') {
        document.getElementById('error_connect_magento').style.display = 'block';
        setTimeout(function(){
          document.getElementById('error_connect_magento').style.display = 'none';
        }, 2000);
      } else if(response.data['url_changes']!=undefined && response.data['url_matrix']!=undefined){
        document.getElementById('success').style.display = 'block';
        window.location.href = response.data['url_changes'];
        setTimeout(function(){
          window.location.href = response.data['url_matrix'];
        }, 1000);
        setTimeout(function(){
          document.getElementById('success').style.display = 'none';
        }, 2000);
      } else {
        document.getElementById('other_error').style.display = 'block';
        setTimeout(function(){
          document.getElementById('other_error').style.display = 'none';
        }, 2000);
      }
    })
    .catch(function (error) {
      //console.log(error);
      document.getElementById('js-generatePriceList').style.display = 'none';
      document.getElementById('other_error').style.display = 'block';
      setTimeout(function(){
        document.getElementById('other_error').style.display = 'none';
      }, 2000);
    }));

    yield put({ type: SEND_REQUEST_SUCCEED, payload: { data } });

  } catch (error) {
    yield put({ type: SEND_REQUEST_FAILED, payload: { error } });
  }
}

export default function* usersSaga() {
  yield all([
    takeLatest(SEND_REQUEST_REQUESTED, sendRequest),
  ]);
}
