import {
  GET_MAGENTO_PRODUCTS_REQUESTED,
  GET_LOWER_HIGHER_REQUESTED,
  GET_COMPLETE_PRODUCTS_REQUESTED,
  GET_SHOPS_LIST_REQUESTED,
  POST_SHOPS_LIST_REQUESTED,
  DELETE_SHOP_REQUESTED,
  GET_PRODUCT_SHOP_LIST_REQUESTED,
  POST_PRODUCT_URL_REQUESTED,
  GET_PRODUCT_STATS_REQUESTED,
} from './actionTypes';

export const getMagentoProducts = () => ({
  type: GET_MAGENTO_PRODUCTS_REQUESTED,
  payload: [],
});

export const getStatsPricesLowerHigher = () => ({
  type: GET_LOWER_HIGHER_REQUESTED,
  payload: [],
});

export const getCompleteProducts = () => ({
  type: GET_COMPLETE_PRODUCTS_REQUESTED,
  payload: [],
});

export const getShopsList = () => ({
  type: GET_SHOPS_LIST_REQUESTED,
  payload: [],
});

export const postShopsList = (params) => ({
  type: POST_SHOPS_LIST_REQUESTED,
  payload: params,
});

export const deleteShop = (params) => ({
  type: DELETE_SHOP_REQUESTED,
  payload: params,
});

export const getProductShopList = (params) => ({
  type: GET_PRODUCT_SHOP_LIST_REQUESTED,
  payload: params,
});

export const postProductUrl = (params) => ({
  type: POST_PRODUCT_URL_REQUESTED,
  payload: params,
});

export const getProductStats = (params) => ({
  type: GET_PRODUCT_STATS_REQUESTED,
  payload: params,
});
