import { all, takeLatest, put, call } from "redux-saga/effects";
import { fetchJSON } from '../../helpers/api';

import {
  GET_LOG_REQUESTED,
  GET_LOG_STARTED,
  GET_LOG_SUCCEED,
  GET_LOG_FAILED,
  GET_LOG_ALLEGRO_REQUESTED,
  GET_LOG_ALLEGRO_STARTED,
  GET_LOG_ALLEGRO_SUCCEED,
  GET_LOG_ALLEGRO_FAILED,
  GET_LOG_CENEO_REQUESTED,
  GET_LOG_CENEO_STARTED,
  GET_LOG_CENEO_SUCCEED,
  GET_LOG_CENEO_FAILED,
  GET_LOG_RULE_REQUESTED,
  GET_LOG_RULE_STARTED,
  GET_LOG_RULE_SUCCEED,
  GET_LOG_RULE_FAILED,
  GET_LOG_TOOLS_REQUESTED,
  GET_LOG_TOOLS_STARTED,
  GET_LOG_TOOLS_SUCCEED,
  GET_LOG_TOOLS_FAILED,
  GET_LOG_DRO_REQUESTED,
  GET_LOG_DRO_STARTED,
  GET_LOG_DRO_SUCCEED,
  GET_LOG_DRO_FAILED,
  GET_LOG_AB_REQUESTED,
  GET_LOG_AB_STARTED,
  GET_LOG_AB_SUCCEED,
  GET_LOG_AB_FAILED,
  GET_LOG_GEN_P_REQUESTED,
  GET_LOG_GEN_P_STARTED,
  GET_LOG_GEN_P_SUCCEED,
  GET_LOG_GEN_P_FAILED,  
  GET_LOG_PRICE_HISTORY_REQUESTED,
  GET_LOG_PRICE_HISTORY_STARTED,
  GET_LOG_PRICE_HISTORY_SUCCEED,
  GET_LOG_PRICE_HISTORY_FAILED,
  GET_UNIV_REQUESTED,
  GET_UNIV_STARTED,
  GET_UNIV_SUCCEED,
  GET_UNIV_FAILED,
} from "./actionTypes";

function* getLogs() {
  try {
    yield put({ type: GET_LOG_STARTED });

    const data = yield call(fetchJSON, process.env.REACT_APP_API_URL+'/api/log/all');
    // const data = products.map( (item,index) => {
    //   return {...item, id: index}
    // });

    yield put({ type: GET_LOG_SUCCEED, payload: { data } });
 
  } catch (error) {
    yield put({ type: GET_LOG_FAILED, payload: { error } });
  }
}

function* getLogsAllegro() {
  try {
    yield put({ type: GET_LOG_ALLEGRO_STARTED });

    const data = yield call(fetchJSON, process.env.REACT_APP_API_URL+'/api/log/all?module=allegro');

    yield put({ type: GET_LOG_ALLEGRO_SUCCEED, payload: { data } });
 
  } catch (error) {
    yield put({ type: GET_LOG_ALLEGRO_FAILED, payload: { error } });
  }
}

function* getLogsCeneo() {
  try {
    yield put({ type: GET_LOG_CENEO_STARTED });
    
    const data = yield call(fetchJSON, process.env.REACT_APP_API_URL+'/api/log/all?module=ceneo');

    yield put({ type: GET_LOG_CENEO_SUCCEED, payload: { data } });
 
  } catch (error) {
    yield put({ type: GET_LOG_CENEO_FAILED, payload: { error } });
  }
}

function* getLogsRule() {
  try {
    yield put({ type: GET_LOG_RULE_STARTED });
    
    const data = yield call(fetchJSON, process.env.REACT_APP_API_URL+'/api/log/all?module=reguly');

    yield put({ type: GET_LOG_RULE_SUCCEED, payload: { data } });
 
  } catch (error) {
    yield put({ type: GET_LOG_RULE_FAILED, payload: { error } });
  }
}

function* getLogsTools() {
  try {
    yield put({ type: GET_LOG_TOOLS_STARTED });
    
    const data = yield call(fetchJSON, process.env.REACT_APP_API_URL+'/api/log/all?module=tools');

    yield put({ type: GET_LOG_TOOLS_SUCCEED, payload: { data } });
 
  } catch (error) {
    yield put({ type: GET_LOG_TOOLS_FAILED, payload: { error } });
  }
}

function* getLogsDro() {
  try {
    yield put({ type: GET_LOG_DRO_STARTED });
    
    const data = yield call(fetchJSON, process.env.REACT_APP_API_URL+'/api/log/all?module=dro');

    yield put({ type: GET_LOG_DRO_SUCCEED, payload: { data } });
 
  } catch (error) {
    yield put({ type: GET_LOG_DRO_FAILED, payload: { error } });
  }
}

function* getLogsAB() {
  try {
    yield put({ type: GET_LOG_AB_STARTED });
    
    const data = yield call(fetchJSON, process.env.REACT_APP_API_URL+'/api/log/all?module=ab');

    yield put({ type: GET_LOG_AB_SUCCEED, payload: { data } });
 
  } catch (error) {
    yield put({ type: GET_LOG_AB_FAILED, payload: { error } });
  }
}

function* getLogsGenProduktow() {
  try {
    yield put({ type: GET_LOG_GEN_P_STARTED });
    
    const data = yield call(fetchJSON, process.env.REACT_APP_API_URL+'/api/log/all?module=g-produktow');

    yield put({ type: GET_LOG_GEN_P_SUCCEED, payload: { data } });
 
  } catch (error) {
    yield put({ type: GET_LOG_GEN_P_FAILED, payload: { error } });
  }
}

function* getLogsPriceHistory() {
  try {
    yield put({ type: GET_LOG_PRICE_HISTORY_STARTED });
    
    const data = yield call(fetchJSON, process.env.REACT_APP_API_URL+'/api/log/all?module=price-history');

    yield put({ type: GET_LOG_PRICE_HISTORY_SUCCEED, payload: { data } });
 
  } catch (error) {
    yield put({ type: GET_LOG_PRICE_HISTORY_FAILED, payload: { error } });
  }
}

function* getUniversalLogs({payload}) {
  try {
    yield put({ type: GET_UNIV_STARTED });
    
    const data = yield call(fetchJSON, process.env.REACT_APP_API_URL+'/api/log/all?module=' + payload.type );

    yield put({ type: GET_UNIV_SUCCEED, payload: { data } });
 
  } catch (error) {
    yield put({ type: GET_UNIV_FAILED, payload: { error } });
  }
}


export default function* usersSaga() {
  yield all([
    takeLatest(GET_LOG_REQUESTED, getLogs),
    takeLatest(GET_LOG_ALLEGRO_REQUESTED, getLogsAllegro),
    takeLatest(GET_LOG_CENEO_REQUESTED, getLogsCeneo),
    takeLatest(GET_LOG_DRO_REQUESTED, getLogsDro),
    takeLatest(GET_LOG_AB_REQUESTED, getLogsAB),
    takeLatest(GET_LOG_RULE_REQUESTED, getLogsRule),
    takeLatest(GET_LOG_TOOLS_REQUESTED, getLogsTools),
    takeLatest(GET_LOG_GEN_P_REQUESTED, getLogsGenProduktow),
    takeLatest(GET_LOG_PRICE_HISTORY_REQUESTED, getLogsPriceHistory),
    takeLatest(GET_UNIV_REQUESTED, getUniversalLogs),
  ]);
}
