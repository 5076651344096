import {
  INITIAL,
  STARTED,
  SUCCEED,
  FAILED,
} from '../../constants/statusTypes';

import {
  GET_LOG_STARTED,
  GET_LOG_SUCCEED,
  GET_LOG_FAILED,
  GET_LOG_ALLEGRO_STARTED,
  GET_LOG_ALLEGRO_SUCCEED,
  GET_LOG_ALLEGRO_FAILED,
  GET_LOG_CENEO_STARTED,
  GET_LOG_CENEO_SUCCEED,
  GET_LOG_CENEO_FAILED,
  GET_LOG_RULE_STARTED,
  GET_LOG_RULE_SUCCEED,
  GET_LOG_RULE_FAILED,
  GET_LOG_TOOLS_STARTED,
  GET_LOG_TOOLS_SUCCEED,
  GET_LOG_TOOLS_FAILED,
  GET_LOG_DRO_STARTED,
  GET_LOG_DRO_SUCCEED,
  GET_LOG_DRO_FAILED,
  GET_LOG_AB_STARTED,
  GET_LOG_AB_SUCCEED,
  GET_LOG_AB_FAILED,
  GET_LOG_GEN_P_STARTED,
  GET_LOG_GEN_P_SUCCEED,
  GET_LOG_GEN_P_FAILED,
  GET_LOG_PRICE_HISTORY_STARTED,
  GET_LOG_PRICE_HISTORY_SUCCEED,
  GET_LOG_PRICE_HISTORY_FAILED,
  GET_UNIV_STARTED,
  GET_UNIV_SUCCEED,
  GET_UNIV_FAILED,
} from './actionTypes';

import { findIndex } from 'lodash';

const INIT_STATE = {
  data: {
    index: [],
    indexAllegro: [],
    indexCeneo: [],
    indexRule: [],
    indexTools: [],
    indexDro: [],
    indexAB: [],
    indexGenP: [],
    universal: []
  },
  error: '',
  status: {
    isLoading: false,
    name: INITIAL,
  },
  showRightSidebar: false,
};

const Logs = (state = INIT_STATE, action) => {

  switch (action.type) {
    case GET_LOG_STARTED:
    case GET_LOG_RULE_STARTED:
    case GET_LOG_TOOLS_STARTED:
    case GET_LOG_ALLEGRO_STARTED:
    case GET_LOG_CENEO_STARTED: 
    case GET_LOG_AB_STARTED: 
    case GET_LOG_GEN_P_STARTED: 
    case GET_LOG_PRICE_HISTORY_STARTED: 
    case GET_UNIV_STARTED: 
    case GET_LOG_DRO_STARTED: {
      const newStatus = {
        name: STARTED,
        isLoading: true,
      }

      return {
        ...state,
        status: newStatus
      };
    }

    case GET_LOG_SUCCEED: {
      const newStatus = {
        name: SUCCEED,
        isLoading: false,
      };
      return {
        ...state,
        status: newStatus,
        data: {
          ...state.data,
          index: action.payload.data
        }
      };
    }
    // allegro
    case GET_LOG_ALLEGRO_SUCCEED: {
      const newStatus = {
        name: SUCCEED,
        isLoading: false,
      };
      return {
        ...state,
        status: newStatus,
        data: {
          ...state.data,
          indexAllegro: action.payload.data
        }
      };
    }
    // ceneo
    case GET_LOG_CENEO_SUCCEED: {
      const newStatus = {
        name: SUCCEED,
        isLoading: false,
      };
      return {
        ...state,
        status: newStatus,
        data: {
          ...state.data,
          indexCeneo: action.payload.data
        }
      };
    }
    // reguly
    case GET_LOG_RULE_SUCCEED: {
      const newStatus = {
        name: SUCCEED,
        isLoading: false,
      };
      return {
        ...state,
        status: newStatus,
        data: {
          ...state.data,
          indexRule: action.payload.data
        }
      };
    }
    // generator zestawów
    case GET_LOG_TOOLS_SUCCEED: {
      const newStatus = {
        name: SUCCEED,
        isLoading: false,
      };
      return {
        ...state,
        status: newStatus,
        data: {
          ...state.data,
          indexTools: action.payload.data
        }
      };
    }
    // DRO
    case GET_LOG_DRO_SUCCEED: {
      const newStatus = {
        name: SUCCEED,
        isLoading: false,
      };
      return {
        ...state,
        status: newStatus,
        data: {
          ...state.data,
          indexDro: action.payload.data
        }
      };
    }

    // AB
    case GET_LOG_AB_SUCCEED: {
      const newStatus = {
        name: SUCCEED,
        isLoading: false,
      };
      return {
        ...state,
        status: newStatus,
        data: {
          ...state.data,
          indexAB: action.payload.data
        }
      };
    }

    // Generator Produktow
    case GET_LOG_GEN_P_SUCCEED: {
      const newStatus = {
        name: SUCCEED,
        isLoading: false,
      };
      return {
        ...state,
        status: newStatus,
        data: {
          ...state.data,
          indexGenP: action.payload.data
        }
      };
    }
    
    case GET_LOG_PRICE_HISTORY_SUCCEED: {
      const newStatus = {
        name: SUCCEED,
        isLoading: false,
      };
      return {
        ...state,
        status: newStatus,
        data: {
          ...state.data,
          indexGenP: action.payload.data
        }
      };
    }
    
    case GET_UNIV_SUCCEED: {
      const newStatus = {
        name: SUCCEED,
        isLoading: false,
      };
      return {
        ...state,
        status: newStatus,
        data: {
          ...state.data,
          universal: action.payload.data
        }
      };
    }

    case GET_LOG_FAILED:
    case GET_LOG_RULE_FAILED:
    case GET_LOG_TOOLS_FAILED:
    case GET_LOG_ALLEGRO_FAILED:
    case GET_LOG_DRO_FAILED:
    case GET_LOG_AB_FAILED:
    case GET_LOG_GEN_P_FAILED:
    case GET_LOG_PRICE_HISTORY_FAILED:
    case GET_UNIV_FAILED:
    case GET_LOG_CENEO_FAILED: {
      const newStatus = {
        name: FAILED,
        isLoading: false,
      };

      return {
        ...state,
        status: newStatus,
        error: action.error,
        data: {
          ...state.data,
          index: []
        }
      };
    }


    default:
      return state;
  }
};

export default Logs;
