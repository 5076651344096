import {
  INITIAL,
  STARTED,
  SUCCEED,
  FAILED,
} from '../../constants/statusTypes';

import {
  GET_PRODUCTS_STARTED,
  GET_PRODUCTS_SUCCEED,
  GET_PRODUCTS_FAILED,
  GET_TAXES_SHIPPING_STARTED,
  GET_TAXES_SHIPPING_SUCCEED,
  GET_TAXES_SHIPPING_FAILED,
  POST_BLOCK_STARTED,
  POST_BLOCK_SUCCEED,
  POST_BLOCK_FAILED,
  POST_CHANGE_PRICE_STARTED,
  POST_CHANGE_PRICE_SUCCEED,
  POST_CHANGE_PRICE_FAILED,
  POST_CHANGE_PRICE_CENEO_STARTED,
  POST_CHANGE_PRICE_CENEO_SUCCEED,
  POST_CHANGE_PRICE_CENEO_FAILED,
  POST_CHANGE_TAXES_SHIPPING_PRICE_STARTED,
  POST_CHANGE_TAXES_SHIPPING_PRICE_SUCCEED,
  POST_CHANGE_TAXES_SHIPPING_PRICE_FAILED,
} from './actionTypes';

import { findIndex } from 'lodash';

const INIT_STATE = {
  data: {
    index: []
  },
  error: '',
  status: {
    isLoading: false,
    name: INITIAL,
  },
  showRightSidebar: false,
};

const Products = (state = INIT_STATE, action) => {

  switch (action.type) {
    case GET_PRODUCTS_STARTED: {
      const newStatus = {
        name: STARTED,
        isLoading: true,
      }

      return {
        ...state,
        status: newStatus
      };
    }

    case GET_PRODUCTS_SUCCEED: {
      const newStatus = {
        name: SUCCEED,
        isLoading: false,
      };
      return {
        ...state,
        status: newStatus,
        data: {
          ...state.data,
          index: action.payload.data
        }
      };
    }

    case GET_PRODUCTS_FAILED: {
      const newStatus = {
        name: FAILED,
        isLoading: false,
      };

      return {
        ...state,
        status: newStatus,
        error: action.error,
        data: {
          ...state.data,
          index: []
        }
      };
    }

    // block

    case GET_TAXES_SHIPPING_STARTED: 
    case POST_CHANGE_PRICE_STARTED:
    case POST_CHANGE_PRICE_CENEO_STARTED: 
    case POST_CHANGE_TAXES_SHIPPING_PRICE_STARTED: {
      const newStatus = {
        name: STARTED,
        isLoading: true,
      }

      return {
        ...state,
        status: newStatus
      };
    }

    case GET_TAXES_SHIPPING_SUCCEED: {
      const newStatus = {
        name: SUCCEED,
        isLoading: false,
      };
      return {
        ...state,
        status: newStatus,
        data: {
          ...state.data,
          index: action.payload.products
        }
      }
    }

    case POST_BLOCK_STARTED: {
      const newStatus = {
        name: STARTED,
        isLoading: true,
      }
      const index = findIndex(state.data.index, ["id_magento", action.payload.product_id]);
      state.data.index[index].checkBoxRefresh = false;
      return {
        ...state,
        status: newStatus
      };
    }

    case POST_BLOCK_SUCCEED: {
      const newStatus = {
        name: SUCCEED,
        isLoading: false,
      };
      const index = findIndex(state.data.index, ["id_magento", action.payload[0].product_id]);
      if(action.payload[0].dir === 'up') {
        state.data.index[index].block_up = action.payload[0].change;
      } else if(action.payload[0].dir === 'down') {
        state.data.index[index].block_down = action.payload[0].change;
      } else if(action.payload[0].dir === 'buynow') {
        state.data.index[index].buy_now = action.payload[1].is_set;
      }
      state.data.index[index].checkBoxRefresh = true;
      return {
        ...state,
        status: newStatus
      }
    }

    // zmiana ceny produktu
    case POST_CHANGE_PRICE_SUCCEED: {
      const newStatus = {
        name: SUCCEED,
        isLoading: false,
      };
      if(action.payload.data.status) {
        const index = findIndex(state.data.index, ["id_magento", action.payload.payload.product_id]);
        state.data.index[index].price_current = action.payload.payload.price;
      }
      return {
        ...state,
        status: newStatus
      };
    }

    // zmiana ceny produktów do ceneo
    case POST_CHANGE_PRICE_CENEO_SUCCEED: {
      const newStatus = {
        name: SUCCEED,
        isLoading: false,
      };
      return {
        ...state,
        status: newStatus
      };
    }

    // zmiana ceny dostaw
    case POST_CHANGE_TAXES_SHIPPING_PRICE_SUCCEED: {
      const newStatus = {
        name: SUCCEED,
        isLoading: false,
      };
      const index = findIndex(state.data.index, ["id", action.payload.taxe_id]);
      state.data.index[index].price = action.payload.new_price;
      return {
        ...state,
        status: newStatus
      };
    }

    case GET_TAXES_SHIPPING_FAILED: 
    case POST_BLOCK_FAILED:
    case POST_CHANGE_PRICE_FAILED:
    case POST_CHANGE_PRICE_CENEO_FAILED: 
    case POST_CHANGE_TAXES_SHIPPING_PRICE_FAILED: {
      const newStatus = {
        name: FAILED,
        isLoading: false,
      };
      return {
        ...state,
        status: newStatus,
      };
    }


    default:
      return state;
  }
};

export default Products;
