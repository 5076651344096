import {
  GET_PRODUCTS_REQUESTED,
  GET_TAXES_SHIPPING_REQUESTED,
  POST_BLOCK_REQUESTED,
  POST_CHANGE_PRICE_REQUESTED,
  POST_CHANGE_PRICE_CENEO_REQUESTED,
  POST_CHANGE_TAXES_SHIPPING_PRICE_REQUESTED
} from './actionTypes';

export const getProducts = (params) => ({
  type: GET_PRODUCTS_REQUESTED,
  payload: params
});

export const getTaxesShipping = () => ({
  type: GET_TAXES_SHIPPING_REQUESTED,
});

export const blockUpdate = (params) => ({
  type: POST_BLOCK_REQUESTED,
  payload: params
});

export const changePrice = (params) => ({
  type: POST_CHANGE_PRICE_REQUESTED,
  payload: params
});

export const changePriceToCeneo = () => ({
  type: POST_CHANGE_PRICE_CENEO_REQUESTED,
});

export const changeTaxesShippingPrice = (params) => ({
  type: POST_CHANGE_TAXES_SHIPPING_PRICE_REQUESTED,
  payload: params
});