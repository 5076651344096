import {
  GET_ELKO_CATEGORIES_REQUESTED,
  GET_ELKO_PRODUCTS_REQUESTED, IMPORT_ELKO_PRODUCTS_REQUESTED, UPDATE_ELKO_CATEGORY_REQUESTED,
} from './actionTypes';

export const getElkoProducts = () => ({
  type: GET_ELKO_PRODUCTS_REQUESTED
});

export const importElkoProducts = (ids) => ({
  type: IMPORT_ELKO_PRODUCTS_REQUESTED,
  ids
});

export const getElkoCategories = () => ({
  type: GET_ELKO_CATEGORIES_REQUESTED
});

export const updateElkoCategory = (params) => ({
  type: UPDATE_ELKO_CATEGORY_REQUESTED,
  params
});