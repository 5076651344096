import { all, takeLatest, put, call } from "redux-saga/effects";
import { delay } from "../../helpers/SagaHelpers";

import {
  ADD_TOAST,
  REMOVE_TOAST,
} from "./actionTypes";

function* createToast(action) {
  const params = action.payload;
  yield delay(4000);
  
  yield put({
    type: REMOVE_TOAST,
    payload: {
      id: params.id,
    },
  });
}


export default function* ToastSaga() {
  yield all([
    takeLatest(ADD_TOAST, createToast),
  ]);
};
