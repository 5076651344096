import {
  GET_DRO_CATEGORIES_REQUESTED,
  GET_DRO_PRODUCTS_REQUESTED, 
  IMPORT_DRO_PRODUCTS_REQUESTED,
  UPDATE_DRO_CATEGORY_REQUESTED,
} from './actionTypes';

export const getDroProducts = () => ({
  type: GET_DRO_PRODUCTS_REQUESTED
});

export const importDroProducts = (ids) => ({
  type: IMPORT_DRO_PRODUCTS_REQUESTED,
  ids
});

export const getDroCategories = () => ({
  type: GET_DRO_CATEGORIES_REQUESTED
});

export const updateDroCategory = (params) => ({
  type: UPDATE_DRO_CATEGORY_REQUESTED,
  params
});