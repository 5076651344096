import { all, takeLatest, put, call } from 'redux-saga/effects';
import { fetchJSON } from '../../helpers/api';
import { Cookies } from 'react-cookie';
import { post } from 'axios';

import {
  MAKE_TEST_REQUESTED,
  MAKE_TEST_STARTED,
  MAKE_TEST_SECCESS,
  MAKE_TEST_FAILED,
  CONFIRM_LOG_REQUESTED,
  CONFIRM_LOG_STARTED,
  CONFIRM_LOG_SECCESS,
  CONFIRM_LOG_FAILED,
  GET_LOGS_REQUESTED,
  GET_LOGS_STARTED,
  GET_LOGS_SECCESS,
  GET_LOGS_FAILED
} from './actionTypes';

import { SUCCESS, ERROR } from '../../constants/toastTypes';
import { addToast } from '../toast/actions';

let cookies = new Cookies();
let user = cookies.get('user');
let auth = user ? user.token : null;

function* makeTest({payload}) {
  const options = {
    body: payload,
    method: 'POST',
    headers: {
      'Content-type': 'application/json',
      'Authorization': 'Bearer ' + auth,
    }
  };
  try {
    yield put({ type: MAKE_TEST_STARTED, payload: payload  });
    const data = yield call(fetchJSON, process.env.REACT_APP_API_URL+'/api/tester/make-test', options);
    yield put({ type: MAKE_TEST_SECCESS, payload: payload });
  } catch (error) {
    // yield put(addToast({
    //   type: ERROR,
    //   message: POST_CHECKLIST_ERROR,
    // }));
    yield put({ type: MAKE_TEST_FAILED, payload: { error } });
  }
}

function* confirmLog({payload}) {
  const options = {
    body: payload,
    method: 'POST',
    headers: {
      'Content-type': 'application/json',
      'Authorization': 'Bearer ' + auth,
    }
  };
  try {
    yield put({ type: CONFIRM_LOG_STARTED, payload: payload  });
    const data = yield call(fetchJSON, process.env.REACT_APP_API_URL+'/api/tester/confirm-log', options);
    yield put({ type: CONFIRM_LOG_SECCESS, payload: payload });
  } catch (error) {
    // yield put(addToast({
    //   type: ERROR,
    //   message: POST_CHECKLIST_ERROR,
    // }));
    yield put({ type: CONFIRM_LOG_FAILED, payload: { error } });
  }
}

function* getLogs({payload}) {
  const options = {
    method: 'GET',
    headers: {
      'Content-type': 'application/json',
      'Authorization': 'Bearer ' + auth,
    }
  };
  try {
    yield put({ type: GET_LOGS_STARTED, payload: payload  });
    const data = yield call(fetchJSON, process.env.REACT_APP_API_URL+'/api/tester/get-logs/'+payload, options);
    yield put({ type: GET_LOGS_SECCESS, payload: data });
  } catch (error) {
    // yield put(addToast({
    //   type: ERROR,
    //   message: POST_CHECKLIST_ERROR,
    // }));
    yield put({ type: GET_LOGS_FAILED, payload: { error } });
  }
}

export default function* usersSaga() {
  yield all([
    takeLatest(MAKE_TEST_REQUESTED, makeTest),
    takeLatest(CONFIRM_LOG_REQUESTED, confirmLog),
    takeLatest(GET_LOGS_REQUESTED, getLogs)
  ]);
}
