import {
  INITIAL,
  STARTED,
  SUCCEED,
  FAILED,
} from '../../constants/statusTypes';

import {
  GET_USERS_STARTED,
  GET_USERS_SUCCEED,
  GET_USERS_FAILED,
} from './actionTypes';


const INIT_STATE = {
  data: {
    index: [],
  },
  error: '',
  status: {
    isLoading: false,
    name: INITIAL,
  },
  showRightSidebar: false,
};

const Users = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_USERS_STARTED: {
      const newStatus = {
        name: STARTED,
        isLoading: true,
      }

      return {
        ...state,
        status: newStatus
      };
    }

    case GET_USERS_SUCCEED: {
      const newStatus = {
        name: SUCCEED,
        isLoading: false,
      };
      return {
        ...state,
        status: newStatus,
        data: {
          ...state.data,
          index: action.payload.data
        }
      };
    }


    case GET_USERS_FAILED: {
      const newStatus = {
        name: FAILED,
        isLoading: false,
      };

      return {
        ...state,
        status: newStatus,
        error: action.error,
        data: {
          ...state.data,
          index: []
        }
      };
    }

    default:
      return state;
  }
};

export default Users;
