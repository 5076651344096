import { all, takeLatest, put, call } from "redux-saga/effects";
import { fetchJSON } from '../../helpers/api';

import {
  GET_RULE_REQUESTED,
  GET_RULE_STARTED,
  GET_RULE_SUCCEED,
  GET_RULE_FAILED,
  POST_MODIFY_RULE_REQUESTED,
  POST_MODIFY_RULE_STARTED,
  POST_MODIFY_RULE_SUCCEED,
  POST_MODIFY_RULE_FAILED,
  POST_UPDATE_RULE_ALLEGRO_REQUESTED,
  POST_UPDATE_RULE_ALLEGRO_STARTED,
  POST_UPDATE_RULE_ALLEGRO_SUCCEED,
  POST_UPDATE_RULE_ALLEGRO_FAILED,
  POST_UPDATE_RULE_MATRIX_REQUESTED,
  POST_UPDATE_RULE_MATRIX_STARTED,
  POST_UPDATE_RULE_MATRIX_SUCCEED,
  POST_UPDATE_RULE_MATRIX_FAILED,
} from "./actionTypes";

import { SUCCESS, ERROR } from "../../constants/toastTypes";
import { GET_RULE_SUCCESS, GET_RULE_ERROR, POST_MODIFY_RULE_SUCCESS, POST_MODIFY_RULE_ERROR, POST_UPDATE_RULE_ALLEGRO_SUCCESS, POST_UPDATE_RULE_ALLEGRO_ERROR, POST_UPDATE_RULE_MATRIX_SUCCESS, POST_UPDATE_RULE_MATRIX_ERROR} from "../../constants/notifications";
import { addToast } from "../toast/actions";

function* getRuleList() {
  try {
    yield put({ type: GET_RULE_STARTED });
    const data = yield call(fetchJSON, process.env.REACT_APP_API_URL+'/api/rule/all');
    // const data = products.map( (item,index) => {
    //   return {...item, id: index}
    // });

    yield put(addToast({
      type: SUCCESS,
      message: GET_RULE_SUCCESS,
    }));

    yield put({ type: GET_RULE_SUCCEED, payload: { data } });
 
  } catch (error) {
    yield put(addToast({
      type: ERROR,
      message: GET_RULE_ERROR,
    }));
    yield put({ type: GET_RULE_FAILED, payload: { error } });
  }
}

function* modifyRule({payload}) {
  const options = {
    body: payload,
    method: 'POST'
  };
  try {
    //console.log(payload);
    yield put({ type: POST_MODIFY_RULE_STARTED });
    const data = yield call(fetchJSON, process.env.REACT_APP_API_URL+'/api/rule/modify', options);
    yield put(addToast({
      type: SUCCESS,
      message: POST_MODIFY_RULE_SUCCESS,
    }));
    yield put({ type: POST_MODIFY_RULE_SUCCEED, payload: {data} });
    
  } catch (error) {
    yield put(addToast({
      type: ERROR,
      message: POST_MODIFY_RULE_ERROR,
    }));
    yield put({ type: POST_MODIFY_RULE_FAILED });
  }
}

function* updateRuleAllegro({payload}) {
  const options = {
    body: payload,
    method: 'POST'
  };
  try {
    //console.log(payload);
    yield put({ type: POST_UPDATE_RULE_ALLEGRO_STARTED });
    const data = yield call(fetchJSON, process.env.REACT_APP_API_URL+'/api/rule/update-allegro', options);
    yield put({ type: POST_UPDATE_RULE_ALLEGRO_SUCCEED, payload: {data} });
    yield put(addToast({
      type: SUCCESS,
      message: POST_UPDATE_RULE_ALLEGRO_SUCCESS,
    }));
    
  } catch (error) {
    yield put(addToast({
      type: ERROR,
      message: POST_UPDATE_RULE_ALLEGRO_ERROR,
    }));
    yield put({ type: POST_UPDATE_RULE_ALLEGRO_FAILED });
  }
}

function* updateRuleMatrix({payload}) {
  const options = {
    body: payload,
    method: 'POST'
  };
  try {
    //console.log(payload);
    yield put({ type: POST_UPDATE_RULE_MATRIX_STARTED });
    const data = yield call(fetchJSON, process.env.REACT_APP_API_URL+'/api/rule/update-matrix', options);
    yield put({ type: POST_UPDATE_RULE_MATRIX_SUCCEED, payload: {data} });
    yield put(addToast({
      type: SUCCESS,
      message: POST_UPDATE_RULE_MATRIX_SUCCESS,
    }));
  } catch (error) {
    yield put(addToast({
      type: ERROR,
      message: POST_UPDATE_RULE_MATRIX_ERROR,
    }));
    yield put({ type: POST_UPDATE_RULE_MATRIX_FAILED });
  }
}



export default function* usersSaga() {
  yield all([
    takeLatest(GET_RULE_REQUESTED, getRuleList),
    takeLatest(POST_MODIFY_RULE_REQUESTED, modifyRule),
    takeLatest(POST_UPDATE_RULE_ALLEGRO_REQUESTED, updateRuleAllegro),
    takeLatest(POST_UPDATE_RULE_MATRIX_REQUESTED, updateRuleMatrix),
  ]);
}
