export const GET_MAGENTO_PRODUCTS_REQUESTED = 'GET_MAGENTO_PRODUCTS_REQUESTED';
export const GET_MAGENTO_PRODUCTS_STARTED = 'GET_MAGENTO_PRODUCTS_STARTED';
export const GET_MAGENTO_PRODUCTS_SECCESS = 'GET_MAGENTO_PRODUCTS_SECCESS';
export const GET_MAGENTO_PRODUCTS_FAILED = 'GET_MAGENTO_PRODUCTS_FAILED';

export const GET_LOWER_HIGHER_REQUESTED = 'GET_LOWER_HIGHER_REQUESTED';
export const GET_LOWER_HIGHER_STARTED = 'GET_LOWER_HIGHER_STARTED';
export const GET_LOWER_HIGHER_SECCESS = 'GET_LOWER_HIGHER_SECCESS';
export const GET_LOWER_HIGHER_FAILED = 'GET_LOWER_HIGHER_FAILED';

export const GET_COMPLETE_PRODUCTS_REQUESTED = 'GET_COMPLETE_PRODUCTS_REQUESTED';
export const GET_COMPLETE_PRODUCTS_STARTED = 'GET_COMPLETE_PRODUCTS_STARTED';
export const GET_COMPLETE_PRODUCTS_SECCESS = 'GET_COMPLETE_PRODUCTS_SECCESS';
export const GET_COMPLETE_PRODUCTS_FAILED = 'GET_COMPLETE_PRODUCTS_FAILED';

export const GET_PRODUCT_SHOP_LIST_REQUESTED = 'GET_PRODUCT_SHOP_LIST_REQUESTED';
export const GET_PRODUCT_SHOP_LIST_STARTED = 'GET_PRODUCT_SHOP_LIST_STARTED';
export const GET_PRODUCT_SHOP_LIST_SECCESS = 'GET_PRODUCT_SHOP_LIST_SECCESS';
export const GET_PRODUCT_SHOP_LIST_FAILED = 'GET_PRODUCT_SHOP_LIST_FAILED';

export const GET_SHOPS_LIST_REQUESTED = 'GET_SHOPS_LIST_REQUESTED';
export const GET_SHOPS_LIST_STARTED = 'GET_SHOPS_LIST_STARTED';
export const GET_SHOPS_LIST_SECCESS = 'GET_SHOPS_LIST_SECCESS';
export const GET_SHOPS_LIST_FAILED = 'GET_SHOPS_LIST_FAILED';

export const POST_SHOPS_LIST_REQUESTED = 'POST_SHOPS_LIST_REQUESTED';
export const POST_SHOPS_LIST_STARTED = 'POST_SHOPS_LIST_STARTED';
export const POST_SHOPS_LIST_SECCESS = 'POST_SHOPS_LIST_SECCESS';
export const POST_SHOPS_LIST_FAILED = 'POST_SHOPS_LIST_FAILED';

export const POST_PRODUCT_URL_REQUESTED = 'POST_PRODUCT_URL_REQUESTED';
export const POST_PRODUCT_URL_STARTED = 'POST_PRODUCT_URL_STARTED';
export const POST_PRODUCT_URL_SECCESS = 'POST_PRODUCT_URL_SECCESS';
export const POST_PRODUCT_URL_FAILED = 'POST_PRODUCT_URL_FAILED';

export const DELETE_SHOP_REQUESTED = 'DELETE_SHOP_REQUESTED';
export const DELETE_SHOP_STARTED = 'DELETE_SHOP_STARTED';
export const DELETE_SHOP_SECCESS = 'DELETE_SHOP_SECCESS';
export const DELETE_SHOP_FAILED = 'DELETE_SHOP_FAILED';

export const GET_PRODUCT_STATS_REQUESTED = 'GET_PRODUCT_STATS_REQUESTED';
export const GET_PRODUCT_STATS_STARTED = 'GET_PRODUCT_STATS_STARTED';
export const GET_PRODUCT_STATS_SECCESS = 'GET_PRODUCT_STATS_SECCESS';
export const GET_PRODUCT_STATS_FAILED = 'GET_PRODUCT_STATS_FAILED';
