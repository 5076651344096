import {
  INITIAL,
  STARTED,
  SUCCEED,
  FAILED,
} from '../../constants/statusTypes';
import { findIndex } from 'lodash';
import {
  GET_NOTIFICATION_STARTED,
  GET_NOTIFICATION_SUCCEED,
  GET_NOTIFICATION_FAILED,
  POST_NOTIFICATION_STARTED,
  POST_NOTIFICATION_SUCCEED,
  POST_NOTIFICATION_FAILED,
} from './actionTypes';

// import { findIndex } from 'lodash';

const INIT_STATE = {
  data: {
    index: []
  },
  error: '',
  status: {
    isLoading: false,
    name: INITIAL,
  },
};

const notification = (state = INIT_STATE, action) => {

  switch (action.type) {
    case GET_NOTIFICATION_STARTED: 
    case POST_NOTIFICATION_STARTED: 
    {
      const newStatus = {
        name: STARTED,
        isLoading: true,
      }

      return {
        ...state,
        status: newStatus
      };
    }
    // pobranie produktów allegro
    case GET_NOTIFICATION_SUCCEED: {
      const newStatus = {
        name: SUCCEED,
        isLoading: false,
      };
      return {
        ...state,
        status: newStatus,
        data: {
          ...state.data,
          index: action.payload.products
        }
      };
    }

    // powiadomienie zamkniete
    case POST_NOTIFICATION_SUCCEED: {
      const newStatus = {
        name: SUCCEED,
        isLoading: false,
      };
      //console.log(action.payload.data);
      const index = findIndex(state.data.index, ["id", action.payload.data.id]);
      if(index!==-1) state.data.index.splice(index, 1);
      return {
        ...state,
        status: newStatus,
      };
    }

    case GET_NOTIFICATION_FAILED: 
    case POST_NOTIFICATION_FAILED: 
    {
      const newStatus = {
        name: FAILED,
        isLoading: false,
      };

      return {
        ...state,
        status: newStatus,
        error: action.error,
        data: {
          ...state.data,
          index: []
        }
      };
    }

    default:
      return state;
  }
};

export default notification;
