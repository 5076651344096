import { INITIAL, STARTED, SUCCEED, FAILED } from '../../constants/statusTypes';

import {
  MAKE_TEST_STARTED,
  MAKE_TEST_SECCESS,
  MAKE_TEST_FAILED,
  CONFIRM_LOG_STARTED,
  CONFIRM_LOG_SECCESS,
  CONFIRM_LOG_FAILED,
  GET_LOGS_STARTED,
  GET_LOGS_SECCESS,
  GET_LOGS_FAILED,
} from './actionTypes';

const INIT_STATE = {
  logs: [],
  status: {
    isLoading: false,
    name: INITIAL,
  },
};

const Tester = (state = INIT_STATE, action) => {
  switch (action.type) {
    case CONFIRM_LOG_STARTED:
    case GET_LOGS_STARTED:
    case MAKE_TEST_STARTED: {
      const newStatus = {
        name: STARTED,
        isLoading: true,
      };
      return {
        ...state,
        status: newStatus,
      };
    }

    case CONFIRM_LOG_SECCESS:
    case MAKE_TEST_SECCESS: {
      const newStatus = {
        name: SUCCEED,
        isLoading: false,
      };
      window.location.href = window.location.href;
      return {
        ...state,
        status: newStatus
      };
    }
    
    case GET_LOGS_SECCESS: {
      const newStatus = {
        name: SUCCEED,
        isLoading: false,
      };
      return {
        ...state,
        status: newStatus,
        logs: action.payload,
      };
    }

    case GET_LOGS_FAILED:
    case CONFIRM_LOG_FAILED:
    case MAKE_TEST_FAILED: {
      const newStatus = {
        name: FAILED,
        isLoading: false,
      };

      return {
        ...state,
        status: newStatus,
        error: action.error,
      };
    }

    default:
      return state;
  }
};

export default Tester;
