import { createToast } from "../../services/toast";

import {
  ADD_TOAST,
  REMOVE_TOAST,
} from './actionTypes';

export const addToast = (params) => ({
  type: ADD_TOAST,
  payload: createToast(params),
});

export const removeToast = (params) => ({
  type: REMOVE_TOAST,
  payload: params,
});
