import {
  GET_USERS_REQUESTED,
  GET_SAVEUSER_REQUESTED
} from './actionTypes';

export const getUsers = (params) => ({
  type: GET_USERS_REQUESTED,
  payload: params
});

export const saveUsers = (params) => ({
  type: GET_SAVEUSER_REQUESTED,
  payload: params
});
