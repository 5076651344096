import {
  ADD_TOAST,
  REMOVE_TOAST
} from './actionTypes';

const InitialState = {
  data: [],
};

const ToastReducer = (state = InitialState, action) => {
  const { payload } = action;
  const { data } = state;

  switch (action.type) {
    case ADD_TOAST: {
      return {
        ...state,
        data: data.some((toast) => toast.message === payload.message)
          ? data
          : [payload, ...data],
      };
    }
    case REMOVE_TOAST: {
      return {
        ...state,
        data: [],
      };
    }
    default:
      return state;
  }
};

export default ToastReducer;
