import { INITIAL, STARTED, SUCCEED, FAILED } from '../../constants/statusTypes';

import {
  GET_MAGENTO_PRODUCTS_STARTED,
  GET_MAGENTO_PRODUCTS_SECCESS,
  GET_MAGENTO_PRODUCTS_FAILED,
  GET_LOWER_HIGHER_STARTED,
  GET_LOWER_HIGHER_SECCESS,
  GET_LOWER_HIGHER_FAILED,
  GET_COMPLETE_PRODUCTS_STARTED,
  GET_COMPLETE_PRODUCTS_SECCESS,
  GET_COMPLETE_PRODUCTS_FAILED,
  GET_SHOPS_LIST_STARTED,
  GET_SHOPS_LIST_SECCESS,
  GET_SHOPS_LIST_FAILED,
  POST_SHOPS_LIST_STARTED,
  POST_SHOPS_LIST_FAILED,
  POST_PRODUCT_URL_STARTED,
  POST_PRODUCT_URL_FAILED,
  DELETE_SHOP_STARTED,
  DELETE_SHOP_FAILED,
  GET_PRODUCT_SHOP_LIST_STARTED,
  GET_PRODUCT_SHOP_LIST_SECCESS,
  GET_PRODUCT_SHOP_LIST_FAILED,
  GET_PRODUCT_STATS_STARTED,
  GET_PRODUCT_STATS_SECCESS,
  GET_PRODUCT_STATS_FAILED,
} from './actionTypes';

const INIT_STATE = {
  magentoProducts: [],
  shopsList: null,
  productShopList: [],
  productStats: [],
  lowerHigherStats: null,
  error: '',
  isLoading: true,
  status: {
    isLoading: false,
    name: INITIAL,
  },
};

const magentoProducts = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_MAGENTO_PRODUCTS_STARTED:
    case GET_COMPLETE_PRODUCTS_STARTED:
    case POST_SHOPS_LIST_STARTED:
    case DELETE_SHOP_STARTED:
    case GET_PRODUCT_SHOP_LIST_STARTED:
    case POST_PRODUCT_URL_STARTED:
    case GET_PRODUCT_STATS_STARTED:
    case GET_LOWER_HIGHER_STARTED:
    case GET_SHOPS_LIST_STARTED: {
      const newStatus = {
        name: STARTED,
        isLoading: true,
      };
      return {
        ...state,
        status: newStatus,
      };
    }
    case GET_MAGENTO_PRODUCTS_SECCESS: {
      const newStatus = {
        name: SUCCEED,
        isLoading: false,
      };
      return {
        ...state,
        status: newStatus,
        magentoProducts: action.payload,
      };
    }
    case GET_COMPLETE_PRODUCTS_SECCESS: {
      const newStatus = {
        name: SUCCEED,
        isLoading: false,
      };
      return {
        ...state,
        status: newStatus,
        magentoProducts: action.payload,
      };
    }
    case GET_SHOPS_LIST_SECCESS: {
      const newStatus = {
        name: SUCCEED,
        isLoading: false,
      };
      return {
        ...state,
        status: newStatus,
        shopsList: action.payload,
      };
    }
    case GET_PRODUCT_SHOP_LIST_SECCESS: {
      const newStatus = {
        name: SUCCEED,
        isLoading: false,
      };
      return {
        ...state,
        status: newStatus,
        productShopList: action.payload,
      };
    }
    case GET_PRODUCT_STATS_SECCESS: {
      const newStatus = {
        name: SUCCEED,
        isLoading: false,
      };
      return {
        ...state,
        status: newStatus,
        productStats: action.payload,
      };
    }
    case GET_LOWER_HIGHER_SECCESS: {
      const newStatus = {
        name: SUCCEED,
        isLoading: false,
      };
      return {
        ...state,
        status: newStatus,
        lowerHigherStats: action.payload,
      };
    }
    case GET_MAGENTO_PRODUCTS_FAILED:
    case GET_COMPLETE_PRODUCTS_FAILED:
    case POST_SHOPS_LIST_FAILED:
    case DELETE_SHOP_FAILED:
    case GET_PRODUCT_SHOP_LIST_FAILED:
    case POST_PRODUCT_URL_FAILED:
    case GET_LOWER_HIGHER_FAILED:
    case GET_PRODUCT_STATS_FAILED:
    case GET_SHOPS_LIST_FAILED: {
      const newStatus = {
        name: FAILED,
        isLoading: false,
      };

      return {
        ...state,
        status: newStatus,
        error: action.error,
      };
    }
    default:
      return state;
  }
};

export default magentoProducts;
