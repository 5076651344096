// @flow

import { combineReducers } from 'redux';
import Layout from './layout/reducers';
import Auth from './auth/reducers';
import AppMenu from './appMenu/reducers';
import Users from './users/reducers';
import Products from './ceneo/reducers';
import Complaints from './complaints/reducers';
import Allegro from './allegro/reducers';
import Dro from './dro/reducers';
import Ab from './ab/reducers';
import Elko from './elko/reducers';
import Innpro from './innpro/reducers';
import Rule from './rule/reducers';
import PriceLists from './PriceLists/reducers';
import SetGenerator from './SetGenerator/reducers';
import ProductGenerator from './ProductGenerator/reducers';
import Logs from './logi/reducers';
import Toast from './toast/reducers';
import Notification from './notification/reducers';
import HistoryPrice from './historyPrice/reducers';
import ReportGenerator from './reportGenerator/reducers';
import Tester from './tester/reducers';

export default combineReducers({
    Auth,
    AppMenu,
    Layout,
    Users,
    Products,
    Complaints,
    Allegro,
    Rule,
    PriceLists,
    SetGenerator,
    ProductGenerator,
    Logs,
    Toast,
    Dro,
    Ab,
    Elko,
    Innpro,
    Notification,
    HistoryPrice,
    ReportGenerator,
    Tester
});
