import { all, takeLatest, put, call } from 'redux-saga/effects';
import { fetchJSON } from '../../helpers/api';
import { Cookies } from 'react-cookie';
import { post } from 'axios';

import {
  GET_REPORTS_REQUESTED,
  GET_REPORTS_STARTED,
  GET_REPORTS_SECCESS,
  GET_REPORTS_FAILED,
  MAKE_REPORT_REQUESTED,
  MAKE_REPORT_STARTED,
  MAKE_REPORT_SECCESS,
  MAKE_REPORT_FAILED,
  GET_SINGLE_REPORT_REQUESTED,
  GET_SINGLE_REPORT_STARTED,
  GET_SINGLE_REPORT_SECCESS,
  GET_SINGLE_REPORT_FAILED
} from './actionTypes';

import { SUCCESS, ERROR } from '../../constants/toastTypes';
import { addToast } from '../toast/actions';

let cookies = new Cookies();
let user = cookies.get('user');
let auth = user ? user.token : null;

function* getReports() {
  try {
    yield put({ type: GET_REPORTS_STARTED });
    const response = yield call(fetchJSON, process.env.REACT_APP_API_URL + '/api/tools/report-generator/reports-list', {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + auth,
      },
    });
    yield put(
      addToast({
        type: SUCCESS,
        message: 'Pomyślnie pobrano listę raportów.',
      })
    );
    yield put({ type: GET_REPORTS_SECCESS, payload: response });
  } catch (error) {
    yield put(
      addToast({
        type: ERROR,
        message: 'Wystąpił błąd podczas pobierania listy raportów.',
      })
    );
    yield put({ type: GET_REPORTS_FAILED, payload: { error } });
  }
}

function* postMakeReport({payload}) {
  let formData = new FormData();
  formData.append('type', payload.type);

  // Post files binary
  Object.keys(payload.files).map((keyName, i) => {
    formData.append('files['+keyName+']', payload.files[keyName]);
  });

  const options = {
    body: payload,
    method: 'POST',
    headers: {
      Authorization: 'Bearer ' + auth,
    }
  };

  try {
    yield put({ type: MAKE_REPORT_STARTED });
    const response = yield call(post(process.env.REACT_APP_API_URL+'/api/tools/report-generator/make-new', formData, options)
    .then(function (response) {
      if(response.data.status != 'success') return;
      document.getElementById('powiadomienie').style.display = 'block';
      window.location.href = window.location.origin + "/generator-raportow/raport/" + response.data.report_id;
    })
    .catch(function (error) {
      document.getElementById('powiadomienie-error').style.display = 'block';
      setTimeout(function(){
        document.getElementById('powiadomienie-error').style.display = 'none';
      }, 5000);
    }));

    yield put({ type: MAKE_REPORT_SECCESS, payload: response });
  } catch (error) {
    yield put({ type: MAKE_REPORT_FAILED, payload: { error } });
  }
}

function* getSingleReport({payload}) {
  try {
    yield put({ type: GET_SINGLE_REPORT_STARTED });
    const response = yield call(fetchJSON, process.env.REACT_APP_API_URL + '/api/tools/report-generator/single-report/' + payload.id, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + auth,
      },
    });
    yield put(
      addToast({
        type: SUCCESS,
        message: 'Pomyślnie pobrano zawartość raportu..',
      })
    );
    yield put({ type: GET_SINGLE_REPORT_SECCESS, payload: response });
  } catch (error) {
    yield put(
      addToast({
        type: ERROR,
        message: 'Wystąpił błąd podczas pobierania zawartości raportu.',
      })
    );
    yield put({ type: GET_SINGLE_REPORT_FAILED, payload: { error } });
  }
}

export default function* usersSaga() {
  yield all([
    takeLatest(GET_REPORTS_REQUESTED, getReports),
    takeLatest(MAKE_REPORT_REQUESTED, postMakeReport),
    takeLatest(GET_SINGLE_REPORT_REQUESTED, getSingleReport)
  ]);
}
