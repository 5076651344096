import {
  GET_LOG_REQUESTED,
  GET_LOG_CENEO_REQUESTED,
  GET_LOG_RULE_REQUESTED,
  GET_LOG_TOOLS_REQUESTED,
  GET_LOG_ALLEGRO_REQUESTED,
  GET_LOG_DRO_REQUESTED,
  GET_LOG_GEN_P_REQUESTED,
  GET_LOG_AB_REQUESTED,
  GET_LOG_PRICE_HISTORY_REQUESTED,
  GET_UNIV_REQUESTED
} from './actionTypes';

export const getLogs = (params) => ({
  type: GET_LOG_REQUESTED,
  payload: params
});

export const getLogsAllegro = (params) => ({
  type: GET_LOG_ALLEGRO_REQUESTED,
  payload: params
});

export const getLogsCeneo = (params) => ({
  type: GET_LOG_CENEO_REQUESTED,
  payload: params
});

export const getLogsRule = (params) => ({
  type: GET_LOG_RULE_REQUESTED,
  payload: params
});

export const getLogsTools = (params) => ({
  type: GET_LOG_TOOLS_REQUESTED,
  payload: params
});

export const getLogsDro = (params) => ({
  type: GET_LOG_DRO_REQUESTED,
  payload: params
});

export const getLogsAB = (params) => ({
  type: GET_LOG_AB_REQUESTED,
  payload: params
});

export const getLogsGenProduktow = (params) => ({
  type: GET_LOG_GEN_P_REQUESTED,
  payload: params
});

export const getLogsPriceHistory = (params) => ({
  type: GET_LOG_PRICE_HISTORY_REQUESTED,
  payload: params
});

export const getUniversalLogs = (params) => ({
  type: GET_UNIV_REQUESTED,
  payload: params
});