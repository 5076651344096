export const GET_LOG_REQUESTED = 'GET_LOG_REQUESTED';
export const GET_LOG_STARTED = 'GET_LOG_STARTED';
export const GET_LOG_SUCCEED = 'GET_LOG_SUCCEED';
export const GET_LOG_FAILED = 'GET_LOG_FAILED';

export const GET_LOG_ALLEGRO_REQUESTED = 'GET_LOG_ALLEGRO_REQUESTED';
export const GET_LOG_ALLEGRO_STARTED = 'GET_LOG_ALLEGRO_STARTED';
export const GET_LOG_ALLEGRO_SUCCEED = 'GET_LOG_ALLEGRO_SUCCEED';
export const GET_LOG_ALLEGRO_FAILED = 'GET_LOG_ALLEGRO_FAILED';

export const GET_LOG_CENEO_REQUESTED = 'GET_LOG_CENEO_REQUESTED';
export const GET_LOG_CENEO_STARTED = 'GET_LOG_CENEO_STARTED';
export const GET_LOG_CENEO_SUCCEED = 'GET_LOG_CENEO_SUCCEED';
export const GET_LOG_CENEO_FAILED = 'GET_LOG_CENEO_FAILED';

export const GET_LOG_RULE_REQUESTED = 'GET_LOG_RULE_REQUESTED';
export const GET_LOG_RULE_STARTED = 'GET_LOG_RULE_STARTED';
export const GET_LOG_RULE_SUCCEED = 'GET_LOG_RULE_SUCCEED';
export const GET_LOG_RULE_FAILED = 'GET_LOG_RULE_FAILED';

export const GET_LOG_TOOLS_REQUESTED = 'GET_LOG_TOOLS_REQUESTED';
export const GET_LOG_TOOLS_STARTED = 'GET_LOG_TOOLS_STARTED';
export const GET_LOG_TOOLS_SUCCEED = 'GET_LOG_TOOLS_SUCCEED';
export const GET_LOG_TOOLS_FAILED = 'GET_LOG_TOOLS_FAILED';

export const GET_LOG_DRO_REQUESTED = 'GET_LOG_DRO_REQUESTED';
export const GET_LOG_DRO_STARTED = 'GET_LOG_DRO_STARTED';
export const GET_LOG_DRO_SUCCEED = 'GET_LOG_DRO_SUCCEED';
export const GET_LOG_DRO_FAILED = 'GET_LOG_DRO_FAILED';

export const GET_LOG_AB_REQUESTED = 'GET_LOG_AB_REQUESTED';
export const GET_LOG_AB_STARTED = 'GET_LOG_AB_STARTED';
export const GET_LOG_AB_SUCCEED = 'GET_LOG_AB_SUCCEED';
export const GET_LOG_AB_FAILED = 'GET_LOG_AB_FAILED';

export const GET_LOG_GEN_P_REQUESTED = 'GET_LOG_GEN_P_REQUESTED';
export const GET_LOG_GEN_P_STARTED = 'GET_LOG_GEN_P_STARTED';
export const GET_LOG_GEN_P_SUCCEED = 'GET_LOG_GEN_P_SUCCEED';
export const GET_LOG_GEN_P_FAILED = 'GET_LOG_GEN_P_FAILED';

export const GET_LOG_PRICE_HISTORY_REQUESTED = 'GET_LOG_PRICE_HISTORY_REQUESTED';
export const GET_LOG_PRICE_HISTORY_STARTED = 'GET_LOG_PRICE_HISTORY_STARTED';
export const GET_LOG_PRICE_HISTORY_SUCCEED = 'GET_LOG_PRICE_HISTORY_SUCCEED';
export const GET_LOG_PRICE_HISTORY_FAILED = 'GET_LOG_PRICE_HISTORY_FAILED';

export const GET_UNIV_REQUESTED = 'GET_UNIV_REQUESTED';
export const GET_UNIV_STARTED = 'GET_UNIV_STARTED';
export const GET_UNIV_SUCCEED = 'GET_UNIV_SUCCEED';
export const GET_UNIV_FAILED = 'GET_UNIV_FAILED';