import { all, takeLatest, put, call } from "redux-saga/effects";
import { fetchJSON } from '../../helpers/api';

import {
  GET_ELKO_PRODUCTS_STARTED,
  GET_ELKO_PRODUCTS_SUCCEED,
  GET_ELKO_PRODUCTS_FAILED,
  GET_ELKO_PRODUCTS_REQUESTED,
  IMPORT_ELKO_PRODUCTS_STARTED,
  IMPORT_ELKO_PRODUCTS_SUCCEED,
  IMPORT_ELKO_PRODUCTS_FAILED,
  IMPORT_ELKO_PRODUCTS_REQUESTED,
  GET_ELKO_CATEGORIES_STARTED,
  GET_ELKO_CATEGORIES_SUCCEED,
  GET_ELKO_CATEGORIES_FAILED,
  GET_ELKO_CATEGORIES_REQUESTED,
  UPDATE_ELKO_CATEGORY_STARTED,
  UPDATE_ELKO_CATEGORY_SUCCEED,
  UPDATE_ELKO_CATEGORY_FAILED,
  UPDATE_ELKO_CATEGORY_REQUESTED
} from "./actionTypes";
import { addToast } from "../toast/actions";
import { ERROR, SUCCESS } from "../../constants/toastTypes";

function* getElkoProducts() {
  try {
    yield put({ type: GET_ELKO_PRODUCTS_STARTED });
    const response = yield callApi('/products');

    yield serveReponse(
      response,
      'Pomyślnie pobrano listę produktów.',
      GET_ELKO_PRODUCTS_SUCCEED,
      GET_ELKO_PRODUCTS_FAILED
    );
  } catch (error) {
    console.warn(error);
    yield serveError('Niepoprawna odpowiedź serwera.', GET_ELKO_PRODUCTS_FAILED);
  }
}

function* importElkoProducts({ ids }) {
  try {
    yield put({ type: IMPORT_ELKO_PRODUCTS_STARTED });
    const response = yield callApi('/products-import', 'POST', { products_ids: ids });

    yield serveReponse(
      response,
      'Pomyślnie dodano produkty do generatora.',
      IMPORT_ELKO_PRODUCTS_SUCCEED,
      IMPORT_ELKO_PRODUCTS_FAILED
    );
  } catch (error) {
    console.warn(error);
    yield serveError('Niepoprawna odpowiedź serwera.', IMPORT_ELKO_PRODUCTS_FAILED);
  }
}

function* getElkoCategories() {
  try {
    yield put({ type: GET_ELKO_CATEGORIES_STARTED });
    const response = yield callApi('/categories');

    yield serveReponse(
      response,
      'Pomyślnie pobrano listę kategorii.',
      GET_ELKO_CATEGORIES_SUCCEED,
      GET_ELKO_CATEGORIES_FAILED
    );
  } catch (error) {
    console.warn(error);
    yield serveError('Niepoprawna odpowiedź serwera.', GET_ELKO_CATEGORIES_FAILED);
  }
}

function* updateElkoCategory({ params }) {
  try {
    yield put({ type: UPDATE_ELKO_CATEGORY_STARTED });
    const response = yield callApi('/update-category-map', 'POST', params);

    yield serveReponse(
      response,
      'Pomyślnie zaktualizowano kategorie.',
      UPDATE_ELKO_CATEGORY_SUCCEED,
      UPDATE_ELKO_CATEGORY_FAILED
    );
  } catch (error) {
    console.warn(error);
    yield serveError('Niepoprawna odpowiedź serwera.', UPDATE_ELKO_CATEGORY_FAILED);
  }
}

export default function* elkoSaga() {
  yield all([
    takeLatest(GET_ELKO_PRODUCTS_REQUESTED, getElkoProducts),
    takeLatest(IMPORT_ELKO_PRODUCTS_REQUESTED, importElkoProducts),
    takeLatest(GET_ELKO_CATEGORIES_REQUESTED, getElkoCategories),
    takeLatest(UPDATE_ELKO_CATEGORY_REQUESTED, updateElkoCategory),
  ]);
}

const callApi = (endpoint, method = 'GET', body = []) => {
  return call(fetchJSON, process.env.REACT_APP_API_URL + '/api/elko' + endpoint, {
    method: method,
    headers: { 'Content-Type': 'application/json' },
    body: method != 'GET' ? body : null
  }, true, true);
}

function* serveReponse(response, successMessage, seccessType, failedType, customData = null) {
  if (response.success) {
    yield put(
      addToast({
        type: SUCCESS,
        message: successMessage,
      })
    );
    yield put({ type: seccessType, data: response.data, customData: customData });
  } else {
    yield serveError(response.error, failedType);
  }
}

function* serveError(errorMessages, failedType) {
  yield put(
    addToast({
      type: ERROR,
      message: errorMessages,
    })
  );
  yield put({ type: failedType, data: null });
}
