import { INITIAL, STARTED, SUCCEED, FAILED } from '../../constants/statusTypes';

import {
  GET_REPORTS_STARTED,
  GET_REPORTS_SECCESS,
  GET_REPORTS_FAILED,
  MAKE_REPORT_STARTED,
  MAKE_REPORT_SECCESS,
  MAKE_REPORT_FAILED,
  GET_SINGLE_REPORT_STARTED,
  GET_SINGLE_REPORT_SECCESS,
  GET_SINGLE_REPORT_FAILED,
} from './actionTypes';

const INIT_STATE = {
  reports: [],
  singleReport: null,
  status: {
    isLoading: false,
    name: INITIAL,
  },
};

const reportGenerator = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_SINGLE_REPORT_STARTED:
    case MAKE_REPORT_STARTED:
    case GET_REPORTS_STARTED: {
      const newStatus = {
        name: STARTED,
        isLoading: true,
      };
      return {
        ...state,
        status: newStatus,
      };
    }

    case GET_REPORTS_SECCESS: {
      const newStatus = {
        name: SUCCEED,
        isLoading: false,
      };
      return {
        ...state,
        status: newStatus,
        reports: action.payload,
      };
    }
    
    case GET_SINGLE_REPORT_SECCESS: {
      const newStatus = {
        name: SUCCEED,
        isLoading: false,
      };
      return {
        ...state,
        status: newStatus,
        singleReport: action.payload,
      };
    }

    case GET_SINGLE_REPORT_FAILED:
    case MAKE_REPORT_FAILED:
    case GET_REPORTS_FAILED: {
      const newStatus = {
        name: FAILED,
        isLoading: false,
      };

      return {
        ...state,
        status: newStatus,
        error: action.error,
      };
    }

    default:
      return state;
  }
};

export default reportGenerator;
