import {
  GET_REPORTS_REQUESTED,
  MAKE_REPORT_REQUESTED,
  GET_SINGLE_REPORT_REQUESTED
} from './actionTypes';

export const getReports = (params) => ({
  type: GET_REPORTS_REQUESTED,
  payload: params,
});

export const postMakeReport = (params) => ({
  type: MAKE_REPORT_REQUESTED,
  payload: params,
});

export const getSingleReport = (params) => ({
  type: GET_SINGLE_REPORT_REQUESTED,
  payload: params,
});