import {
  INITIAL,
  STARTED,
  SUCCEED,
  FAILED,
} from '../../constants/statusTypes';

import {
  SEND_REQUEST_STARTED,
  SEND_REQUEST_SUCCEED,
  SEND_REQUEST_FAILED,
} from './actionTypes';

import { findIndex } from 'lodash';

const INIT_STATE = {
  data: {
    index: [],
  },
  error: '',
  status: {
    isLoading: false,
    name: INITIAL,
  }
};

const PriceLists = (state = INIT_STATE, action) => {

  switch (action.type) {
    case SEND_REQUEST_STARTED: {
      const newStatus = {
        name: STARTED,
        isLoading: true,
      }

      return {
        ...state,
        status: newStatus
      };
    }

    case SEND_REQUEST_SUCCEED: {
      const newStatus = {
        name: SUCCEED,
        isLoading: false,
      };
      console.log(action.payload.data);
      return {
        ...state,
        status: newStatus,
        data: {
          ...state.data,
          index: action.payload.data
        }
      };
    }

    case SEND_REQUEST_FAILED: {
      const newStatus = {
        name: FAILED,
        isLoading: false,
      };
      console.log("reducer failed");
      return {
        ...state,
        status: newStatus,
        error: action.error,
        data: {
          ...state.data,
          index: []
        }
      };
    }


    default:
      return state;
  }
};

export default PriceLists;
