export const GET_PRODUCTS_CENEO_SUCCESS = "Pomyślnie pobrałeś produkty z Ceneo";
export const GET_CENEO_TAXES_SHIPPING_SUCCESS = "Pomyślnie pobrałeś ceny wysyłek dla danych kategori";
export const GET_PRODUCTS_ALLEGRO_SUCCESS = "Pomyślnie pobrałeś produkty z Allegro";
export const GET_PRODUCTS_DRO_SUCCESS = "Pomyślnie pobrałeś produkty z DRO";
export const GET_IMPORT_DRO_SUCCESS = "Zaimportowano produkt DRO";
export const GET_PRODUCTS_AB_SUCCESS = "Pomyślnie pobrałeś produkty z AB";
export const GET_IMPORT_AB_SUCCESS = "Zaimportowano produkt AB";

export const POST_ALLEGRO_PRODUCT_SUCCEED = "Pomyślnie dodano produkt Allegro";
export const POST_ALLEGRO_PRODUCT_ERROR = "Nie udało się wystawić produktu Allegro";

export const POST_EDIT_PRODUCT_SUCCESS = "Pomyślnie zmieniono parametry ogłoszenia";
export const POST_EDIT_PRODUCT_ERROR = "Nie udało się zmienić parametrów ogłoszenia";

export const GET_PRODUCTS_CENEO_ERROR = "Nie udało się pobrać produktów";
export const GET_CENEO_TAXES_SHIPPING_ERROR = "Nie udało się pobrać cen wysyłek";
export const GET_PRODUCTS_ALLEGRO_ERROR = "Nie udało się pobrać produktów";
export const GET_PRODUCTS_DRO_ERROR = "Nie udało się pobrać produktów";
export const GET_IMPORT_DRO_ERROR = "Import DRO nie powiódł się";
export const GET_PRODUCTS_AB_ERROR = "Nie udało się pobrać produktów";
export const GET_IMPORT_AB_ERROR = "Import AB nie powiódł się";

export const GET_NOTIFICATION_ERROR = 'Nie pobrano powiadomień';
export const POST_NOTIFICATION_ERROR = 'Nie zaktualizowano powiadomienia';

export const GET_RULE_SUCCESS = 'Pomyślnie pobrano listę reguł';
export const GET_RULE_ERROR = 'Nie udało się pobrać listy reguł';

export const POST_MODIFY_RULE_SUCCESS = 'Pomyślnie zmodyfikowano regułe';
export const POST_MODIFY_RULE_ERROR = 'Nie udało się zmodyfikować reguły, zajrzyj do logów';

export const POST_UPDATE_RULE_ALLEGRO_SUCCESS = 'Pomyślnie zaktualizowano parametry produktów allegro';
export const POST_UPDATE_RULE_ALLEGRO_ERROR = 'Nie udało się zaktualizować produktów allegro, zajrzyj do logów';

export const POST_UPDATE_RULE_MATRIX_SUCCESS = 'Pomyślnie zaktualizowano parametry produktów matrix media';
export const POST_UPDATE_RULE_MATRIX_ERROR = 'Nie udało się zaktualizować produktów matrix media, zajrzyj do logów';

// Generator Produktów
export const GET_PRODUCTS_TO_IMPROVE_SUCCESS = 'Pomyślnie pobrano listę produktów';
export const GET_PRODUCTS_TO_IMPROVE_ERROR = 'Nie udało się pobrać listy produktów';
export const GET_MAG_SUPP_RULES_SUCCESS = 'Pomyślnie pobrano listę dostawców oraz reguł';
export const GET_MAG_SUPP_RULES_ERROR = 'Nie udało się pobrać listy dostawców oraz reguł';
export const POST_CHECKLIST_ERROR = 'Nie udało się zapisać zmian';
export const PUT_NEW_PRODUCTS_SUCCESS = 'Pomyślnie dodano nowe produkty do bazy. Za chwilę nastąpi przekierowanie.';
export const PUT_NEW_PRODUCTS_ERROR = 'Nie udało się dodać produktów do bazy.';
export const POST_START_DOWNLOAD_SUCCESS = 'Udało się pobrać dane produktu.';
export const POST_START_DOWNLOAD_ERROR = 'Wystąpił błąd poczas pobierania produktu.';
export const POST_START_UPLOAD_SUCCESS = 'Udało się zaimportować produkt do Magento. Sprawdź logi aby upewnić się czy wszystko się powiodło.';
export const POST_START_UPLOAD_ERROR = 'Wystąpił błąd poczas importowania produktu do Magento.';
export const DELETE_PRODUCT_SUCCESS = 'Udało się usunąć pliki oraz dane z bazy.';
export const DELETE_PRODUCT_ERROR = 'Wystąpił błąd poczas usuwania produktu.';
export const POST_REINDEX_SUCCESS = 'Udało się przeindeksować dane w Magento.';
export const POST_REINDEX_ERROR = 'Wystąpił błąd poczas indeksowania danych w Magento.';