import {
  GET_INNPRO_CATEGORIES_REQUESTED,
  GET_INNPRO_PRODUCTS_REQUESTED, 
  IMPORT_INNPRO_PRODUCTS_REQUESTED,
  UPDATE_INNPRO_CATEGORY_REQUESTED,
} from './actionTypes';

export const getInnproProducts = () => ({
  type: GET_INNPRO_PRODUCTS_REQUESTED
});

export const importInnproProducts = (ids) => ({
  type: IMPORT_INNPRO_PRODUCTS_REQUESTED,
  ids
});

export const getInnproCategories = () => ({
  type: GET_INNPRO_CATEGORIES_REQUESTED
});

export const updateInnproCategory = (params) => ({
  type: UPDATE_INNPRO_CATEGORY_REQUESTED,
  params
});