import { all, takeLatest, put, call } from "redux-saga/effects";
import { fetchJSON } from '../../helpers/api';

import {
  GET_NOTIFICATION_REQUESTED,
  GET_NOTIFICATION_STARTED,
  GET_NOTIFICATION_SUCCEED,
  GET_NOTIFICATION_FAILED,
  POST_NOTIFICATION_REQUESTED,
  POST_NOTIFICATION_STARTED,
  POST_NOTIFICATION_SUCCEED,
  POST_NOTIFICATION_FAILED,
} from "./actionTypes";

import { SUCCESS, ERROR } from "../../constants/toastTypes";
import { GET_NOTIFICATION_ERROR, POST_NOTIFICATION_ERROR } from "../../constants/notifications";
import { addToast } from "../toast/actions";

function* getNotification() {
  try {
    yield put({ type: GET_NOTIFICATION_STARTED });

    const products = yield call(fetchJSON, process.env.REACT_APP_API_URL+'/api/notification/all?global=true');

    yield put({ type: GET_NOTIFICATION_SUCCEED, payload: { products } });
 
  } catch (error) {
    yield put(addToast({
      type: ERROR,
      message: GET_NOTIFICATION_ERROR,
    }));
    yield put({ type: GET_NOTIFICATION_FAILED, payload: { error } });
  }
}

function* readNotification({payload}) {
  const options = {
    body: payload,
    method: 'PUT'
  };
  try {
    yield put({ type: POST_NOTIFICATION_STARTED });

    const data = yield call(fetchJSON, process.env.REACT_APP_API_URL+'/api/notification/closed', options);
    //console.log(data);
    yield put({ type: POST_NOTIFICATION_SUCCEED, payload: { data } });
 
  } catch (error) {
    yield put(addToast({
      type: ERROR,
      message: POST_NOTIFICATION_ERROR,
    }));
    yield put({ type: POST_NOTIFICATION_FAILED, payload: { error } });
  }
}



export default function* usersSaga() {
  yield all([
    takeLatest(GET_NOTIFICATION_REQUESTED, getNotification),
    takeLatest(POST_NOTIFICATION_REQUESTED, readNotification),
  ]);
}
