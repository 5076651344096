import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { getLoggedInUser } from '../../helpers/authUtils';
import {
  GET_COMPLAINT_COLLECTIONS_STARTED,
  GET_COMPLAINT_COLLECTIONS_SECCESS,
  GET_COMPLAINT_COLLECTIONS_FAILED,
  GET_COMPLAINTS_STARTED,
  GET_COMPLAINTS_SECCESS,
  GET_COMPLAINTS_FAILED,
  GET_COMPLAINT_DETAILS_STARTED,
  GET_COMPLAINT_DETAILS_SECCESS,
  GET_COMPLAINT_DETAILS_FAILED,
  GET_COMPLAINT_FORM_DATA_STARTED,
  GET_COMPLAINT_FORM_DATA_SECCESS,
  GET_COMPLAINT_FORM_DATA_FAILED,
  UPDATE_COMPLAINT_INFO_STARTED,
  UPDATE_COMPLAINT_INFO_SECCESS,
  UPDATE_COMPLAINT_INFO_FAILED,
  MAKE_COMPLAINT_ACTION_STARTED,
  MAKE_COMPLAINT_ACTION_SECCESS,
  MAKE_COMPLAINT_ACTION_FAILED,
  SEARCH_COMPLAINT_CLIENTS_STARTED,
  SEARCH_COMPLAINT_CLIENTS_SECCESS,
  SEARCH_COMPLAINT_CLIENTS_FAILED,
  CREATE_NEW_COMPLAINT_STARTED,
  CREATE_NEW_COMPLAINT_SECCESS,
  CREATE_NEW_COMPLAINT_FAILED,
  GET_BASELINKER_ORDERS_BY_EMAIL_STARTED,
  GET_BASELINKER_ORDERS_BY_EMAIL_SECCESS,
  GET_BASELINKER_ORDERS_BY_EMAIL_FAILED,
  GET_COMPLAINT_DHL_ORDERS_STARTED,
  GET_COMPLAINT_DHL_ORDERS_SECCESS,
  GET_COMPLAINT_DHL_ORDERS_FAILED,
} from './actionTypes';

const INIT_STATE = {
  collections: [],
  complaints: null,
  dhlOrders: null,
  formData: {
    statuses: [],
    fields_scheme: [],
  },
  baselinkerOrders: null,
  matchedClients: null,
  complaintDetails: null,
  isLoading: false
};

const complaints = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_COMPLAINT_DHL_ORDERS_STARTED:
    case GET_COMPLAINT_DETAILS_STARTED:
    case GET_COMPLAINT_COLLECTIONS_STARTED: {
      return {
        ...state,
        isLoading: true,
        complaints: null,
        dhlOrders: null,
        complaintDetails: null
      };
    }

    case SEARCH_COMPLAINT_CLIENTS_STARTED:{
      return {
        ...state,
        isLoading: true,
        matchedClients: null
      };
    }

    case GET_BASELINKER_ORDERS_BY_EMAIL_STARTED:
    case CREATE_NEW_COMPLAINT_STARTED:
    case GET_COMPLAINT_FORM_DATA_STARTED:
    case MAKE_COMPLAINT_ACTION_STARTED:
    case UPDATE_COMPLAINT_INFO_STARTED:
    case GET_COMPLAINTS_STARTED: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case GET_COMPLAINT_COLLECTIONS_SECCESS: {
      return {
        ...state,
        isLoading: false,
        collections: action.data
      };
    }

    case GET_COMPLAINTS_SECCESS: {
      return {
        ...state,
        isLoading: false,
        complaints: {
          ...state.complaints,
          [action.customData.collectionHandle]: action.data
        },
      };
    }

    case GET_COMPLAINT_DHL_ORDERS_SECCESS: {
      return {
        ...state,
        isLoading: false,
        dhlOrders: action.data,
      };
    }

    case GET_COMPLAINT_DETAILS_SECCESS: {
      return {
        ...state,
        isLoading: false,
        complaintDetails: action.data
      };
    }

    case UPDATE_COMPLAINT_INFO_SECCESS: {
      return {
        ...state,
        isLoading: false,
        complaintDetails: {
          ...state.complaintDetails,
          collected_info: action.data
        }
      };
    }

    case GET_COMPLAINT_FORM_DATA_SECCESS: {
      return {
        ...state,
        isLoading: false,
        formData: action.data
      };
    }

    case MAKE_COMPLAINT_ACTION_SECCESS: {
      const loggedInUser = getLoggedInUser();
      const lastActionType = action.data[0].type;
      
      if(
        lastActionType == 'status-change' &&
        loggedInUser.user.permissions.indexOf('complaint/statuses/all') === -1
      ) {
        window.location.href = window.location.origin + '/reklamacje';
      } else {
        window.location.href = window.location.pathname;
      }

      return {
        ...state,
        isLoading: false,
        complaintDetails: {
          ...state.complaintDetails,
          actions: action.data
        }
      };
    }

    case SEARCH_COMPLAINT_CLIENTS_SECCESS: {
      return {
        ...state,
        isLoading: false,
        matchedClients: action.data
      };
    }

    case CREATE_NEW_COMPLAINT_SECCESS: {
      window.location.href = '/reklamacje';
      return {
        ...state,
        isLoading: false
      };
    }

    case GET_BASELINKER_ORDERS_BY_EMAIL_SECCESS: {
      return {
        ...state,
        isLoading: false,
        baselinkerOrders: action.data
      };
    }

    case GET_COMPLAINT_DHL_ORDERS_FAILED:
    case GET_BASELINKER_ORDERS_BY_EMAIL_FAILED:
    case CREATE_NEW_COMPLAINT_FAILED:
    case SEARCH_COMPLAINT_CLIENTS_FAILED:
    case GET_COMPLAINT_FORM_DATA_FAILED:
    case MAKE_COMPLAINT_ACTION_FAILED:
    case UPDATE_COMPLAINT_INFO_FAILED:
    case GET_COMPLAINT_DETAILS_FAILED:
    case GET_COMPLAINT_COLLECTIONS_FAILED:
    case GET_COMPLAINTS_FAILED: {
      return {
        ...state,
        isLoading: false
      };
    }

    default:
      return state;
  }
};

export default complaints;
