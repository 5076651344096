import { findIndex } from 'lodash';
import {
  INITIAL,
  STARTED,
  SUCCEED,
  FAILED,
} from '../../constants/statusTypes';
import { GET_ELKO_CATEGORIES_FAILED, GET_ELKO_CATEGORIES_STARTED, GET_ELKO_CATEGORIES_SUCCEED, GET_ELKO_PRODUCTS_FAILED, GET_ELKO_PRODUCTS_STARTED, GET_ELKO_PRODUCTS_SUCCEED, IMPORT_ELKO_PRODUCTS_FAILED, IMPORT_ELKO_PRODUCTS_STARTED, IMPORT_ELKO_PRODUCTS_SUCCEED, UPDATE_ELKO_CATEGORY_FAILED, UPDATE_ELKO_CATEGORY_STARTED, UPDATE_ELKO_CATEGORY_SUCCEED } from './actionTypes';

const INIT_STATE = {
  products: [],
  categories: [],
  status: {
    isLoading: false,
    name: INITIAL,
  }
};

const ElkoProducts = (state = INIT_STATE, action) => {
  switch (action.type) {
    case UPDATE_ELKO_CATEGORY_STARTED:
    case GET_ELKO_CATEGORIES_STARTED:
    case IMPORT_ELKO_PRODUCTS_STARTED:
    case GET_ELKO_PRODUCTS_STARTED: {
      const newStatus = {
        name: STARTED,
        isLoading: true,
      }

      return {
        ...state,
        status: newStatus
      };
    }

    // pobranie produktów Elko
    case GET_ELKO_PRODUCTS_SUCCEED: {
      const newStatus = {
        name: SUCCEED,
        isLoading: false,
      };
      return {
        ...state,
        status: newStatus,
        products: action.data
      };
    }

    // zaimportowano produkty Elko
    case IMPORT_ELKO_PRODUCTS_SUCCEED: {
      const newStatus = {
        name: SUCCEED,
        isLoading: false,
      };

      const newProducts = [...state.products];
      if(Array.isArray(action.data)) {
        action.data.map(elkoProduct => {
          const index = findIndex(newProducts, ["id", elkoProduct.id]);
          if(index !== -1) {newProducts[index] = elkoProduct; console.log(newProducts[index]);}
        });
      }

      return {
        ...state,
        status: newStatus,
        products: newProducts
      };
    }

    // pobranie kategorii Elko
    case GET_ELKO_CATEGORIES_SUCCEED: {
      const newStatus = {
        name: SUCCEED,
        isLoading: false,
      };
      return {
        ...state,
        status: newStatus,
        categories: action.data
      };
    }

    // zaktualizacja kategorii Elko
    case UPDATE_ELKO_CATEGORY_SUCCEED: {
      const newStatus = {
        name: SUCCEED,
        isLoading: false,
      };
      if(action.data.id != undefined) {
        const index = findIndex(state.categories.elko_categories, ["id", action.data.id]);
        if(index !== -1) {
          state.categories.elko_categories[index] = action.data;
        }
      }
      return {
        ...state,
        status: newStatus
      };
    }

    case UPDATE_ELKO_CATEGORY_FAILED: 
    case GET_ELKO_CATEGORIES_FAILED: 
    case IMPORT_ELKO_PRODUCTS_FAILED: 
    case GET_ELKO_PRODUCTS_FAILED: {
      const newStatus = {
        name: FAILED,
        isLoading: false,
      };

      return {
        ...state,
        status: newStatus
      };
    }

    default:
      return state;
  }
};

export default ElkoProducts;
