import {
  GET_COMPLAINT_COLLECTIONS_REQUESTED,
  GET_COMPLAINTS_REQUESTED,
  GET_COMPLAINT_DETAILS_REQUESTED,
  GET_COMPLAINT_FORM_DATA_REQUESTED,
  UPDATE_COMPLAINT_INFO_REQUESTED,
  MAKE_COMPLAINT_ACTION_REQUESTED,
  SEARCH_COMPLAINT_CLIENTS_REQUESTED,
  CREATE_NEW_COMPLAINT_REQUESTED,
  GET_BASELINKER_ORDERS_BY_EMAIL_REQUESTED,
  GET_COMPLAINT_DHL_ORDERS_REQUESTED
} from './actionTypes';

export const getCollections = () => ({
  type: GET_COMPLAINT_COLLECTIONS_REQUESTED
});

export const getComplaints = collectionHandle => ({
  type: GET_COMPLAINTS_REQUESTED,
  collectionHandle: collectionHandle
});

export const getComplaintDhlOrders = () => ({
  type: GET_COMPLAINT_DHL_ORDERS_REQUESTED
});

export const getComplaintDetails = complaintId => ({
  type: GET_COMPLAINT_DETAILS_REQUESTED,
  complaintId: complaintId
});

export const getBaselinkerOrderByEmail = params => ({
  type: GET_BASELINKER_ORDERS_BY_EMAIL_REQUESTED,
  params
});

export const createNewComplaint = params => ({
  type: CREATE_NEW_COMPLAINT_REQUESTED,
  params: params
});

export const getFormData = () => ({
  type: GET_COMPLAINT_FORM_DATA_REQUESTED
});

export const updateComplaintInfo = (complaintId, params) => ({
  type: UPDATE_COMPLAINT_INFO_REQUESTED,
  complaintId: complaintId,
  params: params
});

export const makeComplaintAction = (complaintId, actionType, params) => ({
  type: MAKE_COMPLAINT_ACTION_REQUESTED,
  complaintId: complaintId,
  actionType: actionType,
  params: params
});

export const searchComplaintClients = (fieldName, fieldValue) => ({
  type: SEARCH_COMPLAINT_CLIENTS_REQUESTED,
  fieldName: fieldName,
  fieldValue: fieldValue
});