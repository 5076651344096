// @flow
import { all } from 'redux-saga/effects';
import authSaga from './auth/saga';
import layoutSaga from './layout/saga';
import appMenuSaga from './appMenu/saga';
import usersSaga from './users/saga';
import productsSaga from './ceneo/saga';
import complaintsSaga from './complaints/saga';
import allegroSaga from './allegro/saga';
import droSaga from './dro/saga';
import abSaga from './ab/saga';
import ruleSaga from './rule/saga';
import PriceListsSaga from './PriceLists/saga';
import SetGeneratorSaga from './SetGenerator/saga';
import ProductGeneratorSaga from './ProductGenerator/saga';
import logsSaga from './logi/saga';
import toastSaga from './toast/saga';
import notificationSaga from './notification/saga';
import historyPriceSaga from './historyPrice/saga';
import reportGeneratorSaga from './reportGenerator/saga';
import testerSaga from './tester/saga';
import elkoSaga from './elko/saga';
import innproSaga from './innpro/saga';

export default function* rootSaga(getState) {
    yield all([
        authSaga(),
        layoutSaga(),
        appMenuSaga(),
        usersSaga(),
        productsSaga(),
        complaintsSaga(),
        allegroSaga(),
        historyPriceSaga(),
        droSaga(),
        abSaga(),
        elkoSaga(),
        innproSaga(),
        logsSaga(),
        ruleSaga(),
        PriceListsSaga(),
        SetGeneratorSaga(),
        ProductGeneratorSaga(),
        toastSaga(),
        notificationSaga(),
        reportGeneratorSaga(),
        testerSaga()
    ]);
}
