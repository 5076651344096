import { all, takeLatest, put, call } from "redux-saga/effects";
import { fetchJSON } from '../../helpers/api';

import {
   GET_PRODUCTS_TO_IMPROVE_REQUESTED,
   GET_PRODUCTS_TO_IMPROVE_STARTED,
   GET_PRODUCTS_TO_IMPROVE_SUCCEED,
   GET_PRODUCTS_TO_IMPROVE_FAILED,
   GET_MAG_SUPP_RULES_REQUESTED,
   GET_MAG_SUPP_RULES_STARTED,
   GET_MAG_SUPP_RULES_SUCCEED,
   GET_MAG_SUPP_RULES_FAILED,
   POST_CHECKLIST_REQUESTED,
   POST_CHECKLIST_STARTED,
   POST_CHECKLIST_SUCCEED,
   POST_CHECKLIST_FAILED,
   PUT_NEW_PRODUCTS_REQUESTED,
   PUT_NEW_PRODUCTS_STARTED,
   PUT_NEW_PRODUCTS_SUCCEED,
   PUT_NEW_PRODUCTS_FAILED,
   DELETE_PRODUCT_REQUESTED,
   DELETE_PRODUCT_STARTED,
   DELETE_PRODUCT_SUCCEED,
   DELETE_PRODUCT_FAILED,
   POST_REINDEX_REQUESTED,
   POST_REINDEX_STARTED,
   POST_REINDEX_SUCCEED,
   POST_REINDEX_FAILED,
   EDIT_PRODUCT_STARTED,
   EDIT_PRODUCT_SUCCEED,
   EDIT_PRODUCT_FAILED,
   EDIT_PRODUCT_REQUESTED,
   SAVE_G_PRODUCT_STARTED,
   SAVE_G_PRODUCT_SUCCEED,
   SAVE_G_PRODUCT_FAILED,
   SAVE_G_PRODUCT_REQUESTED,
   POST_TAKE_ACTION_STARTED,
   POST_TAKE_ACTION_SUCCEED,
   POST_TAKE_ACTION_FAILED,
   POST_TAKE_ACTION_REQUESTED,
   ADD_PRODUCTS_FROM_FILE_STARTED,
   ADD_PRODUCTS_FROM_FILE_SUCCEED,
   ADD_PRODUCTS_FROM_FILE_FAILED,
   ADD_PRODUCTS_FROM_FILE_REQUESTED,
} from "./actionTypes";

import { SUCCESS, ERROR } from "../../constants/toastTypes";
import { GET_PRODUCTS_TO_IMPROVE_SUCCESS, GET_PRODUCTS_TO_IMPROVE_ERROR, GET_MAG_SUPP_RULES_SUCCESS, GET_MAG_SUPP_RULES_ERROR, POST_CHECKLIST_ERROR, PUT_NEW_PRODUCTS_SUCCESS, PUT_NEW_PRODUCTS_ERROR, DELETE_PRODUCT_SUCCESS, DELETE_PRODUCT_ERROR, POST_REINDEX_SUCCESS, POST_REINDEX_ERROR} from "../../constants/notifications";
import { addToast } from "../toast/actions";

// pobranie listy produktów generatora
function* getProductsToImprove() {
  try {
    yield put({ type: GET_PRODUCTS_TO_IMPROVE_STARTED });
    const data = yield call(fetchJSON, process.env.REACT_APP_API_URL+'/api/product-generator/products');

    yield put(addToast({
      type: SUCCESS,
      message: GET_PRODUCTS_TO_IMPROVE_SUCCESS,
    }));

    yield put({ type: GET_PRODUCTS_TO_IMPROVE_SUCCEED, payload: { data } });
 
  } catch (error) {
    yield put(addToast({
      type: ERROR,
      message: GET_PRODUCTS_TO_IMPROVE_ERROR,
    }));
    yield put({ type: GET_PRODUCTS_TO_IMPROVE_FAILED, payload: { error } });
  }
}

// dodawanie nowych produktów do bazy
function* addNewProducts({payload}) {
  try {
    yield put({ type: PUT_NEW_PRODUCTS_STARTED, payload: payload  });
    const response = yield callApi('/products/new', 'POST', payload);
    yield put(addToast({
      type: SUCCESS,
      message: PUT_NEW_PRODUCTS_SUCCESS,
    }));
    yield put({ type: PUT_NEW_PRODUCTS_SUCCEED, payload: payload });
  } catch (error) {
    yield put(addToast({
      type: ERROR,
      message: PUT_NEW_PRODUCTS_ERROR,
    }));
    yield put({ type: PUT_NEW_PRODUCTS_FAILED, payload: { error } });
  }
}

// dodawanie nowych produktów do bazy
function* addProductsFromFile({ params }) {
  try {
    yield put({ type: ADD_PRODUCTS_FROM_FILE_STARTED });
    const response = yield callApi('/products/new-from-file', 'POST', params);

    yield serveReponse(
      response,
      'Pomyślnie wgrano nowe produkty.',
      ADD_PRODUCTS_FROM_FILE_SUCCEED,
      ADD_PRODUCTS_FROM_FILE_FAILED
    );
  } catch (error) {
    console.warn(error);
    yield serveError('Niepoprawna odpowiedź serwera.', ADD_PRODUCTS_FROM_FILE_FAILED);
  }
}

// pobierz dane produktu w celu edycji
function* editProduct({ productId }) {
  try {
    yield put({ type: EDIT_PRODUCT_STARTED });
    const response = yield callApi('/product/' + productId);

    yield serveReponse(
      response,
      'Pomyślnie pobrano dane produktu.',
      EDIT_PRODUCT_SUCCEED,
      EDIT_PRODUCT_FAILED
    );
  } catch (error) {
    console.warn(error);
    yield serveError('Niepoprawna odpowiedź serwera.', EDIT_PRODUCT_FAILED);
  }
}

// usuń dane oraz pliki danego produktu
function* deleteProduct({payload}) {
  const options = {
    body: payload,
    method: 'DELETE'
  };
  try {
    yield put({ type: DELETE_PRODUCT_STARTED, payload: payload  });
    const data = yield call(fetchJSON, process.env.REACT_APP_API_URL+'/api/product-generator/product', options);
    yield put(addToast({
      type: SUCCESS,
      message: DELETE_PRODUCT_SUCCESS,
    }));
    yield put({ type: DELETE_PRODUCT_SUCCEED, payload: payload });
  } catch (error) {
    yield put(addToast({
      type: ERROR,
      message: DELETE_PRODUCT_ERROR,
    }));
    yield put({ type: DELETE_PRODUCT_FAILED, payload: { error } });
  }
}

// zapisywanie nowych danych dla produktu
function* saveProduct({ params }) {
  try {
    yield put({ type: SAVE_G_PRODUCT_STARTED });
    const response = yield callApi('/save-product', 'POST', params);

    yield serveReponse(
      response,
      'Pomyślnie zapisano produkt.',
      SAVE_G_PRODUCT_SUCCEED,
      SAVE_G_PRODUCT_FAILED
    );
  } catch (error) {
    console.warn(error);
    yield serveError('Niepoprawna odpowiedź serwera.', SAVE_G_PRODUCT_FAILED);
  }
}

// pobranie listy reguł oraz dostawców magento
function* getMagSuppRules() {
  try {
    yield put({ type: GET_MAG_SUPP_RULES_STARTED });
    const data = yield call(fetchJSON, process.env.REACT_APP_API_URL+'/api/product-generator/mag-supp-rules');

    yield put(addToast({
      type: SUCCESS,
      message: GET_MAG_SUPP_RULES_SUCCESS,
    }));

    yield put({ type: GET_MAG_SUPP_RULES_SUCCEED, payload: { data } });
 
  } catch (error) {
    yield put(addToast({
      type: ERROR,
      message: GET_MAG_SUPP_RULES_ERROR,
    }));
    yield put({ type: GET_MAG_SUPP_RULES_FAILED, payload: { error } });
  }
}

// aktualizacja checklisty oraz statusu
function* updateCheckList({payload}) {
  const options = {
    body: payload,
    method: 'POST'
  };
  try {
    yield put({ type: POST_CHECKLIST_STARTED, payload: payload  });
    const data = yield call(fetchJSON, process.env.REACT_APP_API_URL+'/api/product-generator/update-check-list', options);
    yield put({ type: POST_CHECKLIST_SUCCEED, payload: payload });
  } catch (error) {
    yield put(addToast({
      type: ERROR,
      message: POST_CHECKLIST_ERROR,
    }));
    yield put({ type: POST_CHECKLIST_FAILED, payload: { error } });
  }
}

// dodanie akcji do kolejki
function* takeAction({ params }) {
  try {
    yield put({ type: POST_TAKE_ACTION_STARTED });
    const response = yield callApi('/take-action', 'POST', params);

    yield serveReponse(
      response,
      'Zadanie zostało dodane do kolejki.',
      POST_TAKE_ACTION_SUCCEED,
      POST_TAKE_ACTION_FAILED
    );
  } catch (error) {
    console.warn(error);
    yield serveError('Niepoprawna odpowiedź serwera.', POST_TAKE_ACTION_FAILED);
  }
}

// przeindeksuj dane aby pojawiły się świeżo dodane produkty
function* reindex({payload}) {
  const options = {
    body: payload,
    method: 'POST'
  };
  try {
    yield put({ type: POST_REINDEX_STARTED, payload: payload  });
    const data = yield call(fetchJSON, process.env.REACT_APP_API_URL+'/api/product-generator/reindex', options);
    yield put(addToast({
      type: SUCCESS,
      message: POST_REINDEX_SUCCESS,
    }));
    yield put({ type: POST_REINDEX_SUCCEED, payload: payload });
  } catch (error) {
    yield put(addToast({
      type: ERROR,
      message: POST_REINDEX_ERROR,
    }));
    yield put({ type: POST_REINDEX_FAILED, payload: { error } });
  }
}

export default function* usersSaga() {
  yield all([
    takeLatest(GET_PRODUCTS_TO_IMPROVE_REQUESTED, getProductsToImprove),
    takeLatest(PUT_NEW_PRODUCTS_REQUESTED, addNewProducts),
    takeLatest(ADD_PRODUCTS_FROM_FILE_REQUESTED, addProductsFromFile),
    takeLatest(EDIT_PRODUCT_REQUESTED, editProduct),
    takeLatest(DELETE_PRODUCT_REQUESTED, deleteProduct),
    takeLatest(SAVE_G_PRODUCT_REQUESTED, saveProduct),
    takeLatest(GET_MAG_SUPP_RULES_REQUESTED, getMagSuppRules),
    takeLatest(POST_CHECKLIST_REQUESTED, updateCheckList),
    takeLatest(POST_TAKE_ACTION_REQUESTED, takeAction),
    takeLatest(POST_REINDEX_REQUESTED, reindex),
  ]);
}

const callApi = (endpoint, method = 'GET', body = []) => {
  const formData = new FormData();
  const useFormData = Object.keys(body).includes('has_files');

  if(useFormData) {
    // Append body data to form data
    appendDataToForm(formData, body);
  }

  return call(fetchJSON, process.env.REACT_APP_API_URL + '/api/product-generator' + endpoint, {
    method: method,
    headers: useFormData ? {} : { 'Content-Type': 'application/json' },
    body: method != 'GET' ? (useFormData ? formData : body) : null
  }, true, !useFormData);
}

const appendDataToForm = (formData, appendData, namePrefix = null) => {
  if(appendData instanceof File) {
    const finalName = namePrefix ? namePrefix : 'file';
    formData.append(finalName, appendData);
    return;
  }

  if(namePrefix != null && (appendData == null || typeof appendData === 'string' || typeof appendData === 'number')) {
    formData.append(namePrefix, appendData);
    return;
  }

  Object.keys(appendData).forEach(paramName => {
    const finalName = namePrefix ? `${namePrefix}[${paramName}]` : paramName;

    if(Array.isArray(appendData[paramName]) && appendData[paramName].length) {
      appendData[paramName].forEach((arrayElement, index) => {
        appendDataToForm(formData, arrayElement, finalName + `[${index}]`);
      });
      return;
    }

    if(typeof appendData[paramName] === 'object' && 
      !Array.isArray(appendData[paramName]) && 
      appendData[paramName] !== null
    ) {
      appendDataToForm(formData, appendData[paramName], finalName);
      return;
    }

    formData.append(finalName, appendData[paramName]);
  });
}

function* serveReponse(response, successMessage, seccessType, failedType, customData = null) {
  if (response.success) {
    yield put(
      addToast({
        type: SUCCESS,
        message: successMessage,
      })
    );
    yield put({ type: seccessType, data: response.data, customData: customData });
  } else {
    yield serveError(response.error, failedType);
  }
}

function* serveError(errorMessages, failedType) {
  yield put(
    addToast({
      type: ERROR,
      message: errorMessages,
    })
  );
  yield put({ type: failedType, data: null });
}