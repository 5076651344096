import { all, takeLatest, put, call } from "redux-saga/effects";
import { fetchJSON } from '../../helpers/api';
import { getUsers as getUsersAction } from './actions';

import {
  GET_USERS_REQUESTED,
  GET_USERS_STARTED,
  GET_USERS_SUCCEED,
  GET_USERS_FAILED,

  GET_SAVEUSER_REQUESTED,
  GET_SAVEUSER_STARTED,
  GET_SAVEUSER_SUCCEED,
  GET_SAVEUSER_FAILED,
} from "./actionTypes";

function* getUsers() {
  try {
    yield put({ type: GET_USERS_STARTED });

    const data = yield call(fetchJSON, process.env.REACT_APP_API_URL+'/api/users');

    yield put({ type: GET_USERS_SUCCEED, payload: { data } });
 
  } catch (error) {
    yield put({ type: GET_USERS_FAILED, payload: { error } });
  }
}

function* saveUser(payload) {
  console.log(payload);
  const options = {
    body: payload.payload,
    method: 'POST'
  };
  try {
    yield put({ type: GET_SAVEUSER_STARTED });

    const data = yield call(fetchJSON, process.env.REACT_APP_API_URL+'/api/auth/register', options);

    yield put({ type: GET_SAVEUSER_SUCCEED, payload: { data } });

    yield put(getUsersAction());
    
  } catch (error) {
    yield put({ type: GET_SAVEUSER_FAILED, payload: { error } });
  }
}

export default function* usersSaga() {
  yield all([
    takeLatest(GET_USERS_REQUESTED, getUsers),
    takeLatest(GET_SAVEUSER_REQUESTED, saveUser),
  ]);
}
